import { Grid, makeStyles, useMediaQuery } from '@material-ui/core'
import { Typography, Button } from 'modules/ui'
import InspirationItemCard from './InspirationIItemCard'

const useStyles = makeStyles(theme => ({
  header: {
    marginBottom: theme.spacing(2),
  },
  addNew: {
    flexGrow: 1,
  },
}))

const InspirationItemList = ({
  handleAddNewItem,
  handleChangeOrder,
  items,
  handleUpdateActiveStatus,
  handleClickItem,
  loading,
}) => {
  const classes = useStyles()
  const needAddButtonOnLeft = useMediaQuery(theme => theme.breakpoints.up('md'))

  return (
    <>
      <Grid container spacing={1} className={classes.header} direction="row">
        <Grid item className={needAddButtonOnLeft ? classes.addNew : ''}>
          <Button variant="contained" color="primary" onClick={handleAddNewItem}>
            <Typography>Add new item</Typography>
          </Button>
        </Grid>
        <Grid item>
          <Button onClick={() => handleChangeOrder('newest')} variant="contained" color="primary">
            <Typography>Newest</Typography>
          </Button>
        </Grid>
        <Grid item>
          <Button onClick={() => handleChangeOrder('oldest')} variant="contained" color="primary">
            <Typography>Oldest</Typography>
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={() => handleChangeOrder('most-liked')}
            variant="contained"
            color="primary"
          >
            <Typography>Most liked</Typography>
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={() => handleChangeOrder('most-viewed')}
            variant="contained"
            color="primary"
          >
            <Typography>Most viewed</Typography>
          </Button>
        </Grid>
      </Grid>
      <Grid align="center" container spacing={2}>
        {items.map((item, i) => (
          <InspirationItemCard
            key={i}
            {...item}
            item={item}
            onUpdateActiveStatus={handleUpdateActiveStatus}
            onClick={handleClickItem}
            loading={loading}
          />
        ))}
      </Grid>
    </>
  )
}

export default InspirationItemList
