import API from '@aws-amplify/api'
import { call } from 'redux-saga/effects'
import * as qb from 'gql-query-builder'
import config from '../../config'

export function* requestApi(query, variables) {
  try {
    const { data } = yield call(() =>
      API.graphql(
        {
          query,
          variables,
        },
        { version: config.apiVersion }
      )
    )

    return data
  } catch (e) {
    const messages = e.errors.map(({ message }) => message)
    const error = Error('API error: ' + messages.join(', '))

    if (error.message === 'Network Error') {
      error.name = 'NetworkError'
    }

    if (messages.includes('Unsupported version')) {
      error.name = 'ApiVersionError'
    }

    throw error
  }
}

export function* query(options, _protected = false) {
  const { query, variables } = qb.query(options)

  return yield requestApi(query, variables)
}

export function* mutation(options, _protected = false) {
  const { query, variables } = qb.mutation(options)

  return yield requestApi(query, variables)
}
