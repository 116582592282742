import React from 'react'
import { useLocation } from 'react-router'
import { Stepper, Step, makeStyles, Grid } from '@material-ui/core'
import { Page, Typography, StepLabel } from 'modules/ui'

const useStyles = makeStyles(({ spacing }) => ({
  infoText: {
    margin: spacing(3),
  },
}))

const SignUpStepper = ({ children, infoText }) => {
  const location = useLocation()
  const steps = [
    {
      label: 'Company',
      path: '/sign-up/company',
      infoText:
        'Kérjük, adja meg a vállalkozásának nevét, kategóriáját, illetve válassza ki a listából a tevékenységhez illő címkéket!',
    },
    {
      label: 'Details',
      path: '/sign-up/details',
      infoText: 'Kérjük, adja meg a profilon megjeleníteni kívánt alapadatokat!',
    },
    {
      label: 'Contact',
      path: '/sign-up/contact',
      infoText:
        'Kérjük, adja meg a hivatalos kapcsolattartó adatait! Ezeket az információkat csak a Wedding Manager munkatársai látják, a profilon nem jelennek meg!',
    },
    {
      label: 'Registration',
      path: '/sign-up/registration',
      infoText:
        'Kérjük, adja meg regisztrációhoz szükséges jelszót! Ügyeljen arra, hogy legalább 8 karakterből álljon, és tartalmazzon legalább egy nagybetűt, illetve számot.',
    },
    {
      label: 'Verification',
      path: '/sign-up/verification',
      infoText: 'Kérjük, adja meg az email-ben kapott kódot, hogy megerősítsük a regisztrációját!',
    },
    {
      label: 'Sign In',
      path: '/sign-up/sign-in',
      infoText:
        'Gratulálunk! Elkészült a Wedding Manager profilja! Jelentkezzen be, és már neki is láthat a felfedezésnek!',
    },
  ]

  const activeStep = steps.indexOf(steps.find(step => step.path === location.pathname) || steps[0])
  const classes = useStyles()
  return (
    <Page title="Sign up" maxWidth="md">
      <Stepper alternativeLabel activeStep={activeStep}>
        {steps.map((step, index) => (
          <Step key={index}>
            <StepLabel completed={false}>{step.label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Grid container justifyContent="center" alignContent="center">
        <Grid item>
          <Typography className={classes.infoText} variant="body1">
            {steps[activeStep].infoText}
          </Typography>
        </Grid>
      </Grid>
      {children}
    </Page>
  )
}

export default SignUpStepper
