import * as yup from 'yup'

const validationSchemaObject = {
  name: yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
  description: yup.string().min(12, 'Too Short!').max(2400, 'Too Long!').required('Required'),
  email: yup.string().email('Invalid email address').required('Required'),
  phone: yup.string().min(6, 'Invalid phone!').required('Required'),
  tags: yup.array().min(1).required('Required'),
  category: yup.string().min(2, 'Pick a category!').required('Required'),
  website: yup.string().min(2, 'Too short!').max(50, 'Too long!').required('Required'),
  contactName: yup.string().min(2, 'Too short!').max(50, 'Too long!').required('Required'),
  contactEmail: yup.string().email('Invalid email address').required('Required'),
  contactPhone: yup.string().min(2, 'Invalid phone!').required('Required'),
  profileImage: yup.mixed().test('profile picture test', 'Profile picture is required', value => {
    if (Array.isArray(value)) {
      return value.length > 0
    } else {
      return !!value
    }
  }),
  fixLocation: yup.object().when('areaLocation', {
    is: areaLocation => !areaLocation,
    then: yup.object().shape({
      city: yup.string().min(2).required('City is required'),
      address: yup.string().min(2).required('Address is required'),
    }),
    otherwise: yup.object().nullable(true),
  }),
  areaLocation: yup.object().when('fixLocation', {
    is: fixLocation => !fixLocation,
    then: yup.object().shape({
      city: yup.string().min(2).required('City is required'),
      address: yup.string().min(2).required('Address is required'),
      radius: yup.number(),
      availableNationwide: yup.boolean(),
    }),
    otherwise: yup.object().nullable(true),
  }),
}

export default validationSchemaObject
