import { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { BlogForm, useBlog } from 'modules/blog'
import { Page, Loader, Typography } from 'modules/ui'
import { uploadToS3 } from 'modules/storage'
import config from '../../../config'

const Edit = () => {
  const { blog, updateBlog } = useBlog()
  const [submitting, setSubmitting] = useState(false)

  const handleSubmit = async data => {
    setSubmitting(true)
    await updateBlog({
      ...data,
      coverImage:
        data.coverImage !== blog.coverImage ? await uploadToS3(data.coverImage) : data.coverImage,
    })
    setSubmitting(false)
  }

  if (!blog) {
    return 'Loading...'
  }

  return (
    <Page title={`Edit "${blog.name}"`}>
      <Grid container>
        <Grid item xs={12} sm={8} md={6} lg={4}>
          <Typography variant="h5">Blog details</Typography>
          <BlogForm
            initialValues={blog}
            imagesPath={config.app.IMAGES_URL}
            onSubmit={handleSubmit}
          />
          <Loader loading={submitting} />
        </Grid>
      </Grid>
    </Page>
  )
}

export default Edit
