import React from 'react'
import { Form, Formik } from 'formik'
import * as yup from 'yup'
import { Grid, makeStyles } from '@material-ui/core'
import { TextField, SubmitButton, Checkbox } from 'modules/formik'
import FormError from './FormError'
import { Typography, Link } from 'modules/ui'

const useStyles = makeStyles(({ spacing }) => ({
  footer: {
    marginTop: spacing(4),
    justifyContent: 'flex-end',
    '& button': {
      marginLeft: spacing(1),
    },
  },
}))

// TODO: Add password validation details.
const validationSchema = yup.object({
  password: yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
  termsAndConditions: yup
    .bool()
    .oneOf([true], 'You must accept the terms and condition before registration!'),
  passwordConfirm: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('Required'),
  email: yup.string().email('Invalid email address').required('Required'),
  gdpr: yup.bool().oneOf([true], 'You must accept GDPR before registration!'),
})

const RegisterForm = ({ submitSignUp, error, email }) => {
  const classes = useStyles()
  return (
    <Formik
      onSubmit={submitSignUp}
      validationSchema={validationSchema}
      initialValues={{
        password: '',
        passwordConfirm: '',
        email,
        termsAndConditions: false,
      }}
    >
      <Form>
        <Grid container spacing={2} justifyContent="center" alignContent="flex-end">
          <Grid item xs={12} sm={6} md={5}>
            {error && <FormError message={error?.message} />}

            <TextField id="email" name="email" label="Email" margin="normal" fullWidth disabled />
            <TextField
              id="password"
              name="password"
              label="Password"
              margin="normal"
              fullWidth
              type="password"
            />
            <TextField
              id="passwordConfirm"
              name="passwordConfirm"
              label="Confirm password"
              margin="normal"
              type="password"
              fullWidth
            />
            <Checkbox
              name="termsAndConditions"
              fullWidth
              label={
                <Typography>
                  <Typography display="inline">I accept the </Typography>
                  <Link color="primary" href="https://www.weddingmanager.app/_files/ugd/4d2baf_65c9ccaa587f45e683f0b74fb2bc43c5.pdf" target="_blank">
                    Terms and conditions
                  </Link>
                </Typography>
              }
            />
            <Checkbox
              name="gdpr"
              fullWidth
              label={
                <Typography>
                  <Typography display="inline">I accept the </Typography>
                  <Link
                    color="primary"
                    href="https://www.weddingmanager.app/_files/ugd/4d2baf_50b012ea2c564f57a850fc1b30d65ddd.pdf"
                    target="_blank"
                  >
                    GDPR
                  </Link>
                </Typography>
              }
            />
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.footer} container direction="row">
          <SubmitButton variant="contained" color="primary" margin="dense">
            next
          </SubmitButton>
        </Grid>
      </Form>
    </Formik>
  )
}

export default RegisterForm
