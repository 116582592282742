import { useFormikContext } from 'formik'
import { Button } from 'modules/ui'

const SubmitButton = ({ disabled, ...props }) => {
  const { isSubmitting } = useFormikContext()

  return <Button type="submit" {...props} disabled={disabled || isSubmitting} />
}

export default SubmitButton
