import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'
import { createSagaAction } from 'saga-toolkit'

const name = 'quote'

export const quoteRequestsAdapter = createEntityAdapter()
export const quoteResponsesAdapter = createEntityAdapter()

const initialState = {
  parallelTasks: 0,
  error: null,
  quoteRequest: null,
  quoteResponse: null,
  quoteRequests: quoteRequestsAdapter.getInitialState(),
  total: 0,
  limit: 50,
  offset: 0,
  acceptRequestData: null,
}

export const fetchQuoteResponse = createSagaAction(`${name}/fetchquoteResponse`)
export const fetchQuoteRequest = createSagaAction(`${name}/fetchQuoteRequest`)
export const fetchQuoteRequests = createSagaAction(`${name}/fetchQuoteRequests`)
export const declineQuoteRequest = createSagaAction(`${name}/declineQuoteRequest`)
export const acceptQuoteRequest = createSagaAction(`${name}/acceptQuoteRequest`)

const handlePending = state => ({
  ...state,
  parallelTasks: state.parallelTasks + 1,
})

const handleRejected = (state, { error }) => ({
  ...state,
  error,
  parallelTasks: state.parallelTasks - 1,
})

const slice = createSlice({
  name,
  initialState,
  reducers: {
    clearQuoteRequest: state => ({
      ...state,
      quoteRequest: null,
    }),
    setOffset: (state, { payload }) => ({
      ...state,
      offset: payload,
    }),
    setAcceptRequestData: (state, { payload }) => ({
      ...state,
      acceptRequestData: payload,
    }),
  },
  extraReducers: {
    // quoteRequests
    [fetchQuoteRequests.pending]: handlePending,
    [fetchQuoteRequests.fulfilled]: (state, { payload }) => {
      quoteRequestsAdapter.setAll(state.quoteRequests, payload.elements)
      state.parallelTasks = state.parallelTasks - 1
      state.total = payload.total
      state.acceptRequestData = null
    },
    [fetchQuoteRequests.rejected]: handleRejected,
    // quoteRequest
    [fetchQuoteRequest.pending]: handlePending,
    [fetchQuoteRequest.fulfilled]: (state, { payload }) => ({
      ...state,
      quoteRequest: payload,
      parallelTasks: state.parallelTasks - 1,
    }),
    //rejectQuoteRequests
    [declineQuoteRequest.pending]: handlePending,
    [declineQuoteRequest.fulfilled]: state => ({
      ...state,
      quoteRequest: { ...state.quoteRequest, status: 'rejected' },
      parallelTasks: state.parallelTasks - 1,
    }),
    [declineQuoteRequest.rejected]: (state, { payload }) => ({
      ...state,
      quoteResponse: payload,
      parallelTasks: state.parallelTasks - 1,
    }),
    //acceptQuoteResponse
    [acceptQuoteRequest.pending]: handlePending,
    [acceptQuoteRequest.fulfilled]: (state, { payload }) => ({
      ...state,
      quoteResponse: payload,
      quoteRequest: { ...state.quoteRequest, status: 'accepted' },
      parallelTasks: state.parallelTasks - 1,
    }),
    [acceptQuoteRequest.rejected]: handleRejected,
    // quoteResponse
    [fetchQuoteResponse.pending]: handlePending,
    [fetchQuoteResponse.fulfilled]: (state, { payload }) => ({
      ...state,
      quoteResponse: payload,
      parallelTasks: state.parallelTasks - 1,
    }),
  },
})

export const { clearQuoteRequest, setOffset, setAcceptRequestData } = slice.actions

export default slice.reducer
