import { couplesAdapter } from './slice'

export const selectRoot = state => state.couple
export const selectCouples = state => selectRoot(state).couples
export const selectCouple = state => selectRoot(state).couple

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
  selectById,
} = couplesAdapter.getSelectors(selectCouples)
