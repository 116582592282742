import i18n from 'i18next'
import { initReactI18next, useTranslation } from 'react-i18next'

// const withPrefix = (t, keyPrefix) => key => (keyPrefix ? t(`${keyPrefix}.${key}`) : t(key))

export const useT = (keyPrefix, options) => {
  const { t } = useTranslation(undefined, { ...options, keyPrefix })

  return t
}


export const initTransalation = translations => {
  i18n.use(initReactI18next).init({
    resources: translations,
    fallbackLng: 'hu',
    ns: ['translation'],
    defaultNS: 'translation',
    interpolation: {
      escapeValue: false,
    },
    compatibilityJSON: 'v3',
  })
}

export { useTranslation }

export default i18n
