import moment from 'moment'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Badge from '@material-ui/core/Badge'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import AvatarGroup from '@material-ui/lab/AvatarGroup'
import { Avatar, Typography } from 'modules/ui'
import config from '../../../config'

const imagesPath = config.app.IMAGES_URL

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '&.Mui-selected': {
      color: 'black',
    },
  },
  itemText: {
    marginLeft: theme.spacing(1),
    '& span , p': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
  secondary: {
    display: 'flex',
  },
  primary: {
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  secondaryText: {
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  lastMessageDate: {
    whiteSpace: 'nowrap',
    marginLeft: theme.spacing(1),
  },
  unSeen: {
    fontWeight: 800,
  },
  unSeenBadgeIcon: {
    marginLeft: theme.spacing(1),
  },
}))

const ConversationsListItem = ({ participants, lastMessage, selected, onClick, userId }) => {
  const classes = useStyles()

  const displayUnseen = !lastMessage.seenAt && lastMessage.sender.id !== userId
  return (
    <ListItem className={classes.root} selected={selected} onClick={onClick}>
      <ListItemAvatar>
        <AvatarGroup max={2}>
          {participants.length ? (
            participants.map(({ profileImage, name }, i) => (
              <Avatar key={i} alt={name} src={imagesPath + '/' + profileImage?.name || ''} />
            ))
          ) : (
            <Avatar>
              <HighlightOffIcon />
            </Avatar>
          )}
        </AvatarGroup>
      </ListItemAvatar>
      <ListItemText
        className={classes.itemText}
        disableTypography
        primary={
          <Typography className={clsx(displayUnseen && classes.unSeen, classes.primary)}>
            {participants.length
              ? participants.map(({ name }) => name).join(', ')
              : 'User left conversation'}
          </Typography>
        }
        secondary={
          <div className={clsx(classes.secondary, displayUnseen && classes.unSeen)}>
            <div className={classes.secondaryText}>
              {' '}
              {lastMessage.attachment ? 'Attachment received.' : lastMessage.text}
            </div>
            <div className={classes.lastMessageDate}>
              - {moment(lastMessage.createdAt).fromNow(true)}
            </div>
          </div>
        }
      />
      {displayUnseen && (
        <Badge
          variant="dot"
          color="primary"
          overlap="circular"
          className={classes.unSeenBadgeIcon}
        />
      )}
    </ListItem>
  )
}

export default ConversationsListItem
