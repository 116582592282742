import { useState } from 'react'
import moment from 'moment'
import { DataGrid } from '@material-ui/data-grid'
import { ImageCell } from 'modules/ui'

const createColumns = ({ imagesPath }) => [
  {
    field: 'profileImage',
    headerName: 'Profile image',
    width: 150,
    renderCell: ({ row }) => (
      <ImageCell src={`${imagesPath}/${row.profileImage}`} alt="profile picture" />
    ),
  },
  {
    field: 'myName',
    headerName: 'My name',
    width: 220,
  },
  {
    field: 'partnersName',
    headerName: 'Partner`s name',
    width: 220,
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 220,
  },
  {
    field: 'likedItems',
    headerName: 'Liked items',
    width: 220,
    valueGetter: ({ row }) => row.likedItems.length,
  },
  {
    field: 'createdAt',
    headerName: 'Registered at',
    width: 220,
    valueGetter: ({ row }) => moment(row.createdAt).format('LLL'),
  },
  {
    field: 'lastSignIn',
    headerName: 'Last login',
    width: 220,
    valueGetter: ({ row }) => 'TODO', //moment(row.latSignIn).format('LLL'),
  },
  {
    field: 'likedPartners',
    headerName: 'Liked partners',
    width: 220,
    valueGetter: ({ row }) => row.likedPartners.length,
  },
]

export default ({ couples, imagesPath, ...props }) => {
  const columns = createColumns({ imagesPath })
  const [pageSize, setPageSize] = useState(25)

  return (
    <DataGrid
      rows={couples}
      columns={columns}
      pageSize={pageSize}
      onPageSizeChange={newPageSize => setPageSize(newPageSize)}
      rowsPerPageOptions={[25, 50, 100]}
      rowHeight={90}
      pagination
      {...props}
    />
  )
}
