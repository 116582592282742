import { createSlice } from '@reduxjs/toolkit'
import { createSagaAction } from 'saga-toolkit'
import { sortDirections } from 'modules/ui'

const name = 'app'

export const initialState = {
  started: false,
  snackMessage: null,
  queryParams: {
    pageSize: 10,
    page: 0,
    filter: null,
    sortBy: null,
    sortDirection: sortDirections.ASC,
  },
}

export const start = createSagaAction(`${name}/start`)
export const updateLocation = createSagaAction(`${name}/updateLocation`)

const slice = createSlice({
  name,
  initialState,
  reducers: {
    showSnackMessage: (state, { payload }) => {
      state.snackMessage = payload
    },
    hideSnackMessage: state => {
      state.snackMessage = null
    },
  },
  extraReducers: {
    [start.fulfilled]: state => {
      state.started = true
    },
    [start.rejected]: () => ({
      started: true,
    }),
    [updateLocation.fulfilled]: (state, { payload }) => {
      state.queryParams = payload
    },
  },
})

export const { showSnackMessage, hideSnackMessage, setQueryParams } = slice.actions

export default slice.reducer
