import { makeStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import BaseAutocomplete from '@material-ui/lab/Autocomplete'
import CircularProgress from '@material-ui/core/CircularProgress'
import clsx from 'clsx'
import FormHelperText from './FormHelperText'
import TextField from './TextField'

const useStyles = makeStyles(() => ({
  root: {},
}))

const Autocomplete = ({
  onChange,
  error,
  helperText,
  value = [],
  label,
  labelId,
  className,
  fullWidth,
  margin,
  loading,
  ...props
}) => {
  const classes = useStyles()

  return (
    <FormControl className={clsx(classes.root, className)} fullWidth={fullWidth} margin={margin}>
      <BaseAutocomplete
        {...props}
        options={props.options || []}
        value={value}
        loading={loading}
        defaultValue={value}
        onChange={onChange}
        renderInput={params => (
          <TextField
            {...params}
            label={label}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
      {error && (
        <FormHelperText id={`${labelId}-helper`} error>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  )
}

export default Autocomplete
