import { coverAdapter } from './slice'

export const selectRoot = state => state.cover
const selectCovers = state => selectRoot(state).covers
export const selectCover = state => selectRoot(state).cover

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
  selectById,
} = coverAdapter.getSelectors(selectCovers)
