import { useHistory } from 'react-router'
import { Page } from 'modules/ui'
import { CoverForm, useCover } from 'modules/cover'
import { uploadToS3 } from 'modules/storage'
import { Grid } from '@material-ui/core'

const initialState = {
  name: '',
  image: '',
}

const New = () => {
  const { insertCover, loading } = useCover()
  const history = useHistory()

  const onSubmit = async data => {
    insertCover({
      ...data,
      image: await uploadToS3(data.image),
    })
    history.push('/cover')
  }

  if (loading) {
    return null
  }

  return (
    <Page title="New cover">
      <Grid container alignContent="center" alignItems="center" justifyContent="center">
        <Grid item xs={12} sm={8} md={8} lg={8}>
          <CoverForm initialState={initialState} onSubmit={onSubmit}></CoverForm>
        </Grid>
      </Grid>
    </Page>
  )
}

export default New
