import { Route, useRouteMatch } from 'react-router-dom'
import List from './List'

const Couples = () => {
  const match = useRouteMatch()

  return (
    <>
      <Route path={[`${match.url}`]} exact component={List} />
    </>
  )
}

export default Couples
