import { useState } from 'react'
import { useHistory } from 'react-router'
import Grid from '@material-ui/core/Grid'
import { Page, Loader } from 'modules/ui'
import { TagGroupForm, useTagGroup } from 'modules/tagGroup'
import { useCategory } from 'modules/category'
import { uploadToS3 } from 'modules/storage'
import config from '../../../config'

export default () => {
  const { loading, createTagGroup } = useTagGroup()
  const { categories, loading: categoryLoading } = useCategory()
  const history = useHistory()
  const [submitting, setSubmitting] = useState(false)

  const handleSubmit = async ({ image, tags, ...data }) => {
    setSubmitting(true)

    const tagIdList = tags.map(item => item.id)
    const { payload } = await createTagGroup({
      ...data,
      tags: tagIdList,
      image: image && (await uploadToS3(image)),
    })
    setSubmitting(false)
    history.push(`/tag-groups/${payload.id}/edit`)
  }

  if (loading) {
    return 'Loading...'
  }

  return (
    <Page title="New TagGroup">
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={6} md={4}>
          <TagGroupForm
            categoryLoading={categoryLoading}
            categories={categories}
            onSubmit={handleSubmit}
            imagesPath={config.app.IMAGES_URL}
          />
          <Loader loading={submitting} />
        </Grid>
      </Grid>
    </Page>
  )
}
