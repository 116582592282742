import { filterGroupsAdapter } from './slice'

export const selectRoot = state => state.filterGroup
const selectFilterGroups = state => selectRoot(state).filterGroups
export const selectFilterGroup = state => selectRoot(state).filterGroup

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
  selectById,
} = filterGroupsAdapter.getSelectors(selectFilterGroups)
