import { pick } from 'rambda'
import { takeLatestAsync } from 'saga-toolkit'
import * as actions from './slice'
import { query, mutation } from 'modules/api'

const blogFields = ['id', 'name', 'author', 'ordering', { coverImage: ['name'] }, 'content']

const parseBlog = blog => ({
  ...blog,
  coverImage: blog.coverImage?.name,
})

function* fetchBlogs() {
  const options = {
    operation: 'blogs',
    fields: ['id', 'author', 'name', 'ordering'],
  }

  const result = yield query(options, true)

  return result.blogs
}

function* fetchBlog({ meta }) {
  const { id } = meta.arg
  const options = {
    operation: 'blog',
    fields: blogFields,
    variables: {
      id: {
        type: 'ID',
        value: id,
        required: true,
      },
    },
  }

  const { blog } = yield query(options, true)

  return parseBlog(blog)
}

function* createBlog({ meta }) {
  const options = {
    operation: 'insertBlog',
    fields: ['id'],
    variables: {
      input: {
        value: meta.arg,
        type: 'NewBlogInput!',
      },
    },
  }

  const result = yield mutation(options, true)

  return result.insertBlog
}

function* updateBlog({ meta }) {
  const body = pick(['content', 'name', 'coverImage', 'author', 'ordering', 'id'], meta.arg)
  const options = {
    operation: 'updateBlog',
    fields: blogFields,
    variables: {
      input: {
        value: body,
        type: 'UpdateBlogInput!',
      },
    },
  }

  const { updateBlog } = yield mutation(options, true)

  return parseBlog(updateBlog)
}

function* searchBlog({ meta }) {
  const options = {
    operation: 'blogs',
    fields: blogFields,
    variables: {
      searchByName: {
        type: 'String',
        value: meta.arg,
      },
    },
  }

  const result = yield query(options, true)

  return result.blogs
}

function* removeBlog({ meta }) {
  const { id } = meta.arg
  const options = {
    operation: 'deleteBlog',
    variables: {
      id: {
        value: id,
        type: 'ID!',
      },
    },
  }

  yield mutation(options, true)
}

export default [
  takeLatestAsync(actions.fetchBlogs.type, fetchBlogs),
  takeLatestAsync(actions.fetchBlog.type, fetchBlog),
  takeLatestAsync(actions.createBlog.type, createBlog),
  takeLatestAsync(actions.updateBlog.type, updateBlog),
  takeLatestAsync(actions.removeBlog.type, removeBlog),
  takeLatestAsync(actions.searchBlog.type, searchBlog),
]
