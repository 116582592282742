import { matchPath } from 'react-router'
import { put, select, take, takeEvery } from 'redux-saga/effects'
import { putAsync } from 'saga-toolkit'
import { LOCATION_CHANGE } from 'connected-react-router'

import { actions as adminActions } from 'modules/admin'
import { actions as appActions, selectors as appSelectors } from 'modules/app'
import {
  actions as availabilityActions,
  selectors as availabilitySelectors,
} from 'modules/availability'
import { actions as authActions } from 'modules/auth'
import { actions as blogActions, selectors as blogSelectors } from 'modules/blog'
import { actions as categoryActions, selectors as categorySelectors } from 'modules/category'
import { actions as chatActions } from 'modules/chat'
import { actions as coupleActions } from 'modules/couple'
import { actions as coverActions } from 'modules/cover'
import { actions as highlightActions, selectors as highlightSelectors } from 'modules/highlight'
import { actions as inspirationActions } from 'modules/inspiration'
import { actions as itemActions, selectors as itemSelectors } from 'modules/item'
import {
  actions as notificationActions,
  selectors as notificationSelectors,
} from 'modules/notification'
import { actions as partnerActions, selectors as partnerSelectors } from 'modules/partner'
import { actions as profileActions, selectors as profileSelectors } from 'modules/profile'
import { actions as quoteActions, selectors as quoteSelectors } from 'modules/quote'
import { actions as tagActions, selectors as tagSelectors } from 'modules/tag'
import { actions as tagGroupActions, selectors as tagGroupSelectors } from 'modules/tagGroup'
import { actions as trackingActions } from 'modules/tracking'
import { actions as userActions, selectors as userSelectors } from 'modules/user'
import { actions as weddingActions, selectors as weddingSelectors } from 'modules/wedding'

import { mapNavigationData } from '../mapNavigationData'

function* locationChange({ payload }) {
  try {
    const { location, isFirstRendering } = payload
    const { pathname } = location

    const started = yield select(appSelectors.selectStarted)
    const id = pathname.split('/')[2]

    const { eventName, data: trackingData } = mapNavigationData(pathname, location)

    const { filter, sortBy, sortDirection, offset, limit } = yield putAsync(
      appActions.updateLocation(location.search)
    )

    yield put(trackingActions.track(eventName, trackingData))

    switch (true) {
      //HOME
      case ['/', '/home'].includes(pathname): {
        if (!started) {
          yield take(appActions.start.fulfilled)
        }

        // const currentUser = (yield select(profileSelectors.selectRoot)).user

        // if (currentUser?.type === 'Admin') {
        //   yield put(adminActions.fetchHealthCheck()) // TODO fix taking too long
        // }
        break
      }

      case pathname.startsWith('/admin'): {
        if (!started) {
          yield take(appActions.start.fulfilled)
        }

        const currentUser = (yield select(profileSelectors.selectRoot)).user

        if (currentUser?.type !== 'Admin') {
          return
        }

        yield put(adminActions.fetchHealthCheck())
        break
      }

      // WEDDING
      case pathname.startsWith('/wedding'):
        yield put(weddingActions.clearWedding())
        const wedding = yield select(weddingSelectors.selectWedding)

        if (wedding?.id === +id) {
          break
        }

        yield put(weddingActions.fetchWedding({ id }))
        break

      // ITEM
      case pathname === '/items':
        yield put(itemActions.clearItem())
        yield put(itemActions.fetchItems())
        break
      case pathname.startsWith('/items/new'):
        yield put(categoryActions.fetchCategories())
        yield put(partnerActions.fetchPartners({ offset, limit, filter, sortBy, sortDirection }))
        break
      case pathname.startsWith('/items'):
        yield put(partnerActions.clear())
        yield put(categoryActions.fetchCategories())
        yield put(partnerActions.fetchPartners({ offset, limit, filter, sortBy, sortDirection }))

        const item = yield select(itemSelectors.selectItem)

        if (item?.id === +id) {
          break
        }

        yield put(itemActions.fetchItem({ id }))
        break

      // TAG
      case pathname === '/tags':
        yield put(tagActions.clearTag())
        yield put(tagActions.fetchTags())
        break
      case pathname.startsWith('/tags/new'):
        break
      case pathname.startsWith('/tags'):
        const tag = yield select(tagSelectors.selectTag)

        if (tag?.id === +id) {
          break
        }

        yield put(tagActions.fetchTag({ id }))
        break

      // QUOTE
      case pathname === '/quotes':
        if (!started) {
          yield take(appActions.start.fulfilled)
        }

        yield put(quoteActions.clearQuoteRequest())
        yield put(quoteActions.fetchQuoteRequests())
        break
      case pathname.startsWith('/quotes'):
        if (!started) {
          yield take(appActions.start.fulfilled)
        }

        const quoteRequest = yield select(quoteSelectors.selectQuoteRequest)

        if (quoteRequest?.id.toString() === id.toString()) {
          if (pathname.includes('accept')) {
            yield put(profileActions.fetchProfile())
          }
          break
        }

        yield put(quoteActions.fetchQuoteRequest({ id }))
        yield put(quoteActions.fetchQuoteResponse({ id }))

        break

      // TAGGROUP
      case pathname === '/tag-groups':
        yield put(tagGroupActions.clearTagGroup())
        yield put(tagGroupActions.fetchTagGroups())
        break
      case pathname.startsWith('/tag-groups/new'):
        yield put(categoryActions.fetchCategories())
        break
      case pathname.startsWith('/tag-groups'):
        yield put(categoryActions.fetchCategories())

        const tagGroup = yield select(tagGroupSelectors.selectTagGroup)

        if (tagGroup?.id === +id) {
          break
        }

        yield put(tagGroupActions.fetchTagGroup({ id }))
        break

      // USER
      case pathname === '/users':
        yield put(userActions.clearUser())
        yield put(userActions.fetchUsers())
        break
      case pathname.startsWith('/users/new'):
        yield put(categoryActions.fetchCategories())
        break
      case pathname.startsWith('/users'):
        const user = yield select(userSelectors.selectUser)
        yield put(categoryActions.fetchCategories())

        if (user?.id === +id) {
          break
        }

        yield put(userActions.fetchUser({ id }))
        break

      // PARTNER
      case pathname === '/partners':
        yield put(partnerActions.clearPartner())
        yield put(partnerActions.fetchPartners({ offset, limit, filter, sortBy, sortDirection }))

        break
      case pathname.startsWith('/partners/new'):
        yield put(categoryActions.fetchCategories())
        break
      case pathname.startsWith('/partners'):
        yield put(categoryActions.fetchCategories())
        yield put(itemActions.clearItem())

        const partner = yield select(partnerSelectors.selectPartner)

        if (partner?.id === +id) {
          break
        }

        yield put(partnerActions.fetchPartner({ id }))
        yield put(itemActions.setPartner(id))
        yield put(itemActions.fetchItems())
        break

      // COUPLE
      case pathname === '/couples':
        yield put(coupleActions.clearCouple())
        yield put(coupleActions.fetchCouples())
        break

      // CATEGORY
      case pathname === '/categories':
        yield put(categoryActions.clearCategory())
        yield put(categoryActions.fetchCategories())
        break
      case pathname.startsWith('/categories/new'):
        yield put(categoryActions.fetchCategories())
        break
      case pathname.startsWith('/categories'):
        yield put(categoryActions.fetchCategories())

        const category = yield select(categorySelectors.selectCategory)

        if (category?.id === +id) {
          break
        }

        yield put(categoryActions.fetchCategory({ id }))
        break

      // BLOG
      case pathname === '/blogs':
        yield put(blogActions.clearBlog())
        yield put(blogActions.fetchBlogs())
        break
      case pathname.startsWith('/blogs/new'):
        yield put(blogActions.fetchBlogs())
        break
      case pathname.startsWith('/blogs'):
        yield put(blogActions.fetchBlogs())

        const blog = yield select(blogSelectors.selectBlog)

        if (blog?.id === +id) {
          break
        }

        yield put(blogActions.fetchBlog({ id }))
        break

      // NOTIFICATION
      case pathname === '/notifications':
        yield put(notificationActions.clearNotification())
        yield put(notificationActions.fetchNotifications())
        break
      case pathname.startsWith('/notifications/send'):
        yield put(notificationActions.fetchNotifications())
        break
      case pathname.startsWith('/notifications'):
        yield put(notificationActions.fetchNotifications())

        const notification = yield select(notificationSelectors.selectNotification)

        if (notification?.id === +id) {
          break
        }

        yield put(notificationActions.fetchNotification({ id }))
        break

      // HIGHLIGHTS
      case pathname === '/highlights':
        yield put(highlightActions.clearHighlight())
        yield put(highlightActions.fetchHighlights())
        break
      case pathname.startsWith('/highlights/new'):
        yield put(highlightActions.fetchHighlights())
        break
      case pathname.startsWith('/highlights'):
        yield put(highlightActions.fetchHighlights())

        const highlight = yield select(highlightSelectors.selectHighlight)

        if (highlight?.id === +id) {
          break
        }

        yield put(highlightActions.fetchHighlight({ id }))
        break

      // CHAT
      case pathname.startsWith('/chat'):
        if (!started) {
          yield take(appActions.start.fulfilled)
        }

        const matchConversations = matchPath(pathname, {
          path: '/chat',
          strict: true,
          exact: true,
        })

        if (matchConversations) {
          !isFirstRendering && (yield put(chatActions.fetchConversations()))
        }

        const matchConversation = matchPath(pathname, {
          path: '/chat/conversations/:id([0-9a-zA-Z]{24})',
          strict: true,
          exact: true,
        })

        if (matchConversation) {
          const { id } = matchConversation.params

          yield putAsync(chatActions.fetchConversation({ id }))
          yield putAsync(chatActions.seeConversation({ id }))
        }

        // const matchNewConversation = matchPath(pathname, {
        //   path: '/chat/conversations/new',
        //   strict: true,
        // })

        // if (matchNewConversation) {
        //   yield put(chatActions.clearConversation())
        // }
        break

      // SERVICE
      case pathname === '/availabilities':
        yield put(availabilityActions.clearAvailability())
        yield put(availabilityActions.fetchAvailabilities())
        break

      case pathname.startsWith('/availabilities'):
        const availability = yield select(availabilitySelectors.selectAvailability)

        if (availability?.id === +id) {
          break
        }

        yield put(availabilityActions.fetchAvailability({ id }))
        break

      // SIGN-UP
      case pathname.startsWith('/sign-up'):
        const matchSignUp = matchPath(pathname, {
          path: '/sign-up',
          strict: true,
          exact: true,
        })

        if (matchSignUp) {
          yield put(categoryActions.fetchCategories())
        }
        break

      // SIGN-IN
      case pathname === '/sign-in':
        yield put(authActions.clearSignIn())
        break

      // INSPIRATION
      case pathname === '/inspiration':
        yield put(inspirationActions.clearItem())
        yield put(inspirationActions.fetchItems())
        break

      case pathname === '/inspiration/new':
        yield put(tagActions.fetchTags())
        break

      case pathname.startsWith('/inspiration'):
        yield put(tagActions.fetchTags())
        yield put(inspirationActions.fetchItem({ id }))

        break

      case pathname === '/cover':
        yield put(coverActions.clearCover())
        yield put(coverActions.fetchCovers())

        break

      case pathname.startsWith('/cover'):
        if (id === 'new') {
          break
        }
        yield put(coverActions.fetchCover({ id }))

        break

      default:
        break
    }
  } catch (e) {
    console.log('locationChange Error: ', e)
  }
}

const navigationSagas = [takeEvery(LOCATION_CHANGE, locationChange)]

export default navigationSagas
