export const messageFields = [
  'id',
  {
    participants: [
      { '...on Partner': ['name', 'id', 'type', { profileImage: ['name'] }] },
      { '...on Couple': ['name', 'id', 'type', { profileImage: ['name'] }] },
      { '...on Admin': ['name', 'id', 'type', { profileImage: ['name'] }] },
    ],
  },
  {
    messages: [
      'total',
      {
        elements: [
          'id',
          'text',
          'seenAt',
          'createdAt',
          { attachment: ['type', 'payload'] },
          {
            sender: [
              { '...on Partner': ['name', 'id', 'type', { profileImage: ['name'] }] },
              { '...on Couple': ['name', 'id', 'type', { profileImage: ['name'] }] },
              { '...on Admin': ['name', 'id', 'type', { profileImage: ['name'] }] },
            ],
          },
          { conversation: ['id'] },
        ],
      },
    ],
  },
]

export const conversationFields = [
  'total',
  {
    elements: messageFields,
  },
]

export const message = [
  'id',
  'text',
  {
    sender: [
      { '...on Partner': ['name', 'id', 'type', { profileImage: ['name'] }] },
      { '...on Couple': ['name', 'id', 'type', { profileImage: ['name'] }] },
      { '...on Admin': ['name', 'id', 'type', { profileImage: ['name'] }] },
    ],
  },
  'createdAt',
  { attachment: ['type', 'payload'] },
  'seenAt',
]

export const messages = [
  'total',
  {
    elements: message,
  },
]
