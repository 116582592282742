import { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { unwrapResult } from '@reduxjs/toolkit'
import { useSignIn, SingUpContext, LoginComponent } from 'modules/auth'

const SignIn = () => {
  const { signIn, error } = useSignIn()
  const history = useHistory()
  const { state } = useContext(SingUpContext)
  const { password, email } = state

  const handleLogin = async () => {
    try {
      unwrapResult(await signIn({ email, password }))
      history.push('/highlights')
    } catch (e) {
      history.push('/sign-in')
    }
  }

  return <LoginComponent handleLogin={handleLogin} error={error} />
}

export default SignIn
