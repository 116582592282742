import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'
import { createSagaAction } from 'saga-toolkit'

const name = 'user'

export const usersAdapter = createEntityAdapter()

const initialState = {
  loading: false,
  error: null,
  users: usersAdapter.getInitialState(),
  user: null,
}

export const fetchUsers = createSagaAction(`${name}/fetchUsers`)
export const fetchUser = createSagaAction(`${name}/fetchUser`)
export const searchUser = createSagaAction(`${name}/searchUser`)
export const createUser = createSagaAction(`${name}/createUser`)
export const updateUser = createSagaAction(`${name}/updateUser`)

const handlePending = state => ({
  ...state,
  loading: true,
})

const handleRejected = (state, { error }) => ({
  ...state,
  error,
  loading: false,
})

const slice = createSlice({
  name,
  initialState,
  reducers: {
    clearUser: state => ({
      ...state,
      user: null,
    }),
  },
  extraReducers: {
    // users
    [fetchUsers.pending]: handlePending,
    [fetchUsers.fulfilled]: (state, { payload }) => {
      usersAdapter.setAll(state.users, payload)
      state.loading = false
    },
    [fetchUsers.rejected]: handleRejected,

    // user
    [fetchUser.pending]: handlePending,
    [fetchUser.fulfilled]: (state, { payload }) => ({
      ...state,
      user: payload,
      loading: false,
    }),
    [fetchUser.rejected]: handleRejected,

    // createUser
    [createUser.pending]: handlePending,
    [createUser.fulfilled]: state => ({
      ...state,
      loading: false,
    }),
    [createUser.rejected]: handleRejected,

    // updateUser
    [updateUser.pending]: handlePending,
    [updateUser.fulfilled]: (state, { payload }) => ({
      ...state,
      user: payload,
      loading: false,
    }),
    [updateUser.rejected]: handleRejected,
  },
})

export const { clearUser } = slice.actions

export default slice.reducer
