import { useRouteMatch } from 'react-router'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import IconButton from '@material-ui/core/IconButton'
import { Box } from '@material-ui/core'
import { useHighlight, HighlightsList } from 'modules/highlight'
import { Page, NavButton, Tooltip } from 'modules/ui'

const RowActions = ({ row }) => {
  const match = useRouteMatch()
  const { moveHighlight } = useHighlight()
  const { id } = row

  return (
    <>
      <Tooltip title="Increase ordering">
        <IconButton onClick={() => moveHighlight({ id, dir: 'UP' })}>
          <ArrowUpwardIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Decrease ordering">
        <IconButton onClick={() => moveHighlight({ id, dir: 'DOWN' })}>
          <ArrowDownwardIcon />
        </IconButton>
      </Tooltip>
      <NavButton to={`${match.url}/${id}/edit`} tooltip="Edit">
        <EditIcon fontSize="small" />
      </NavButton>
      <NavButton to={`${match.url}/${id}/remove`} tooltip="Remove">
        <DeleteIcon fontSize="small" />
      </NavButton>
    </>
  )
}

const List = () => {
  const { highlights, loading } = useHighlight()
  const match = useRouteMatch()

  return (
    <Page title="Highlights" fullHeight>
      <HighlightsList highlights={highlights} loading={loading} rowActionsComponent={RowActions} />
      <Box display="flex" justifyContent="flex-end" p={1}>
        <NavButton to={`${match.url}/new`} tooltip="New">
          <AddIcon />
        </NavButton>
      </Box>
    </Page>
  )
}

export default List
