import { itemsAdapter } from './slice'

export const selectRoot = state => state.item
const selectItems = state => selectRoot(state).items
export const selectItem = state => selectRoot(state).item
export const selectPartner = state => selectRoot(state).partner
export const selectOffset = state => selectRoot(state).offset
export const selectLimit = state => selectRoot(state).limit
export const selectStatus = state => selectRoot(state).status
export const selectItemGallery = ({ galleryId }) => state =>
  selectRoot(state).item.galleries.find(({ id }) => id === galleryId)

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
  selectById,
} = itemsAdapter.getSelectors(selectItems)
