import { useState } from 'react'
import { DataGrid } from '@material-ui/data-grid'
import { ImageCell } from 'modules/ui'

const createColumns = ({ RowActions, imagesPath }) => [
  {
    field: 'image',
    headerName: 'Image',
    width: 150,
    renderCell: ({ row }) => <ImageCell src={`${imagesPath}/${row.image}`} alt="cover" />,
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 220,
  },
  {
    field: '',
    headerName: 'Actions',
    width: 140,
    sortable: false,
    disableClickEventBubbling: true,
    renderCell: RowActions,
  },
]

const CoversList = ({ items, imagesPath, rowActionsComponent: RowActions, ...props }) => {
  const columns = createColumns({ RowActions, imagesPath })
  const [pageSize, setPageSize] = useState(25)

  return (
    <DataGrid
      rows={items}
      columns={columns}
      pageSize={pageSize}
      onPageSizeChange={newPageSize => setPageSize(newPageSize)}
      rowsPerPageOptions={[25, 50, 100]}
      rowHeight={90}
      pagination
      {...props}
    />
  )
}

export default CoversList
