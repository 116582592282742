import { Box, makeStyles, Grid } from '@material-ui/core'
import RoomIcon from '@material-ui/icons/Room'
import { useWedding, WeddingCountdown } from 'modules/wedding'
import { Typography, OpenableImage, Page } from 'modules/ui'
import config from '../../config'
import moment from 'moment'

const imagesPath = config.app.IMAGES_URL

const useStyles = makeStyles(theme => ({
  coverImageContainer: {
    maxHeight: 250,
    overflow: 'hidden',
    width: '100%',
    padding: theme.spacing(1),
  },
  coverImage: {
    borderRadius: theme.spacing(1),
    width: '100%',
  },
  weddingCard: {
    maxWidth: 350,
    width: '100%',
    margin: '0 auto',
    padding: theme.spacing(1),
  },
  content: {
    minHeight: 50,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: theme.palette.grey[200],
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
  },
  root: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
  },
  dateContainer: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignSelf: 'center',
  },
  header: {
    marginBottom: theme.spacing(2),
  },
}))

const Wedding = () => {
  const { wedding } = useWedding()
  const classes = useStyles()

  if (!wedding) {
    return null
  }

  return (
    <Page maxWidth="xl" fullHeight disablePadding>
      <Box className={classes.weddingCard}>
        {!!wedding.coverImage && (
          <Box className={classes.coverImageContainer}>
            <OpenableImage
              className={classes.coverImage}
              src={imagesPath + '/' + wedding.coverImage.name}
            />
          </Box>
        )}
        <Grid container className={classes.header}>
          {!!wedding.date && (
            <Grid item xs={2} className={classes.dateContainer}>
              <Typography color="primary">{moment(wedding.date).format('MMM')}</Typography>
              <Typography>{moment(wedding.date).format('DD')}</Typography>
            </Grid>
          )}
          <Grid item xs={8}>
            <Typography variant="h6">{wedding.coupleName}</Typography>
            {!!wedding.address && (
              <Box display="flex">
                <RoomIcon fontSize="small" color="primary" />
                <Typography display="inline">{wedding.address.name}</Typography>
              </Box>
            )}
          </Grid>
        </Grid>
        <WeddingCountdown weddingDate={wedding.date} />
        <Box mt={2}>
          <Typography
            className={classes.content}
            variant="body2"
            color="textSecondary"
            component="p"
          >
            {wedding.description}
          </Typography>
        </Box>
      </Box>
    </Page>
  )
}

export default Wedding
