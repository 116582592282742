import React from 'react'
import { MenuItem, Grid } from '@material-ui/core'
import BaseSelect from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'

const statuses = [
  { name: 'All', value: 'all' },
  { name: 'Pending', value: 'PENDING' },
  { name: 'Active', value: 'ACTIVE' },
  { name: 'Rejected', value: 'REJECTED' },
]

const useStyles = makeStyles(theme => ({
  fill: {
    width: '100%',
  },
  container: {
    maxWidth: '300px',
    marginBottom: theme.spacing(3),
  },
}))

function StatusPicker(props) {
  const classes = useStyles()

  return (
    <Grid className={classes.container}>
      <BaseSelect className={classes.fill} {...props} defaultValue="all">
        {statuses.map(({ name, value }, i) => (
          <MenuItem key={i} value={value}>
            {name}
          </MenuItem>
        ))}
      </BaseSelect>
    </Grid>
  )
}

export default StatusPicker
