const translations = {
  hu: {
    translation: {
      Highlights: 'Kiemelések',
      Notifications: 'Értesítések',
      Covers: 'Borítók',
      Services: 'Szolgáltatások',
      Blogs: 'Blog',
      Items: 'Tételek',
      Days: 'Nap',
      Months: 'Hónap',
      Minutes: 'Perc',
      Hours: 'Óra',
      Seconds: 'Másodperc',
      Categories: 'Kategóriák',
      Couples: 'Párok',
      Partners: 'Partnerek',
      Users: 'Felhasználók',
      Home: 'Kezdőoldal',
      'Upload profile picture': 'Profil kép feltöltése',
      'Forgot Password': 'Elfelejtett jelszó',
      Quantity: 'Mennyiség',
      Type: 'Típus',
      Couple: 'Pár',
      Status: 'Státusz',
      'Send image': 'Kép küldése',
      Pick: 'Kiválaszt',
      'Number of tokens in the wallet': 'Tárcában lévő Tokenek száma',
      'Enter your email address': 'Add meg az email címedet',
      'Enter verification code': 'Add meg a hitelesítő kódod',
      'To reset your password please enter your email address:':
        'A jelszavad helyreállításához add meg az email címed',
      'Skip to verification': 'Ugrás a hitelesítéshez',
      next: 'következő',
      'Company name': 'Cég név',
      Next: 'Következő',
      'Profile picture is required': 'A profil kép kötelező',
      'Too short!': 'Túl rövid',
      'Too long': 'Túl hosszú',
      Help: 'Segítség',
      'Sign In': 'Jelentkezz be',
      'User does not exist.': 'A felhasználó nem létezik',
      'Sign out': 'Kijelentkezés',
      'Your item is under review': 'Az inspirációd vizsgálat alatt van',
      'Your item is rejected': 'Az inspirációd el lett utasítva',
      Chat: 'Chat',
      password: 'jelszó',
      'Add new image': 'Új kép hozzáadása',
      'Inspiration Scroll': 'Inspirációs adatbázis',
      Availabilities: 'Dátumlekérdezések',
      Quotes: 'Ajánlatkérések',
      'Quote response': 'Válasz',
      Accept: 'Elfogad',
      'Quote request': 'Ajánlatkérés',
      'Profile status: pending': 'Profilod státusza: feldolgozás alatt',
      'Profile status: rejected': 'Profilod státusza: elfogadva',
      'Profile status: fulfilled': 'Profilod státusza: felfüggesztve',
      'Reject availability from: {{user}}':
        'Időpontkérés elutasítása az alábbi felhasználótól: {{user}}',
      'Accept availability from: {{user}}':
        'Időpontkérés elfogadása az alábbi felhasználótól: {{user}}',
      'Reject quote from {{couple}}?':
        'Elutasítod az ajánlatkérést az alábbi felhasználótól: {{couple}}?',
      'Accept quote from {{couple}}?':
        'Elfogadod az ajánlatkérést az alábbi felhasználótól: {{couple}}?',
      'Are you sure you want to reject quote from?':
        'Biztos vagy benne, hogy elutasítod az ajánlatot tőlük: {{couple}}?',
      'Are you sure you want to accept quote from {{couple}}?':
        'Biztos vagy benne, hogy elfogadod az ajánlatot tőlük: {{couple}}?',
      Profile: 'Profil',
      "You don't have enough tokens to accept quote from {{couple}}. You need at least 10 tokens to accept a quote.":
        'Nincs elég tokened ahhoz, hogy elfogadj egy ajánlatkérést tőlük: {{couple}}. Legalább 10 token szükséges ahhoz, hogy elfogadj egy ajánlatkérést.',
      '10 tokens': '10 token',
      'Available tokens: {{tokens}}': 'Elérhető tokenek: {{tokens}}',
      'This will cost you: ': 'Ennyibe fog kerülni: ',
      'Add new item': 'Új elem hozzáadása',
      'Most viewed': 'Legnézetebb',
      'Most liked': 'Legtöbbet kedvelt',
      Oldest: 'Legrégebbi',
      Newest: 'Legújabb',
      'Cover Image': 'Borítókép',
      optional: 'opcionális',
      Wallet: 'Tokentárca',
      'Upload cover image': 'Borítókép feltöltése',
      'Add other images to your inspiration': 'További képek hozzáadása az inspirációhoz',
      'Load more': 'Még több',
      Availability: 'Időpontok',
      User: 'Felhasználó',
      'Created at': 'Létrehozva',
      Address: 'Cím',
      Radius: 'Hatókör',
      'Available nationwide': 'Országosan elérhető',
      Description: 'Leírás',
      Website: 'Weboldal',
      'Cover photos': 'Borítóképek',
      Back: 'Vissza',
      'Delete profile': 'Profil törlése',
      Date: 'Dátum',
      'Sign up': 'Regisztráció',
      Note: 'Megjegyzés',
      'Vendor name': 'Vendor név',
      'Visible email': 'Látható email cím',
      'Contact name': 'Kapcsolattartó neve',
      'Contact email': 'Kapcsolattartó email címe',
      'Contact phone number': 'Kapcsolattartó telefonszáma',
      'Contact phone': 'Kapcsolattartó telefonszáma',
      Actions: 'Műveletek',
      'Invalid phone!': 'Hibás telefonszám',
      'Requests for quotations': 'Ajánlatkérések',
      'Password reset': 'Új jelszó',
      Password: 'Jelszó',
      'Password is required': 'A jelszó megadása kötelező',
      'Email is required': 'Az email cím megadása kötelező',
      'Invalid email address': 'Hibás email cím formátum',
      Save: 'Mentés',
      Registration: 'Regisztráció',
      Details: 'Részletek',
      Category: 'Kategória',
      Contact: 'Kapcsolat',
      Verification: 'Hitelesítés',
      Company: 'Cég',
      Pending: 'Válaszra vár',
      Rejected: 'Elutasítva',
      Accepted: 'Elfogadva',
      pending: 'válaszra vár',
      rejected: 'elutasítva',
      accepted: 'elfogadva',
      'Reject quote': 'Ajánlatkérés elutasítása',
      'Upload attachments (Excel, Word, PDF)': 'Csatolmány feltöltése (Excel, Word, PDF)',
      Reject: 'Elutasít',
      Cancel: 'Vissza',
      View: 'Megtekintés',
      'Accept quote': 'Ajánlatkérés elfogadása',
      'Valid until': 'Érvényes eddig',
      Image: 'Kép',
      'My name': 'Az én nevem',
      "Partner's name": 'A partnerem neve',
      'Wedding date': 'Az esküvő dátumat',
      'Wedding location': 'Az esküvőnk helye',
      'Email address': 'Email cím',
      Attachments: 'Csatolmányok',
      "Quote's description": 'Az ajánlatkérés leírása',
      'Your profile is under review, please be patient!':
        'A profilod vizsgálat alatt van, kérlek légy türelemmel!',
      'Your profile has been successfully updated!': 'A profilod sikeresen frissítve lett!',
      Guests: 'Vendégek száma',
      'Profil image': 'Profilkép',
      min: 'minimum',
      max: 'maximum',
      Names: 'Nevek',
      Name: 'Név',
      'Remove Item': 'Elem törlése',
      Update: 'Frissítés',
      Create: 'Létrehoz',
      Conversations: 'Beszélgetések',
      'New inspiration': 'Új inspiráció',
      Tags: 'Címkék',
      'Tag Groups': 'Címkecsoportok',
      'Select your tags': 'Válaszd ki a tagjeidet',
      'Terms and conditions': 'Felhasználási feltételek',
      'You must accept terms and condition before create or update a new inspiration!':
        'El kell fogadnod a felhasználási feltételeket, mielött módosítod vagy létrehozol egy inspriációt!',
      'You must accept GDPR before create or update a new inspiration!':
        'El kell fogadnod a GDPR-t mielött készítesz vagy frissítesz egy inspirációt',
      'Confirm password': 'Jelszó megerősítése',
      'Confirmation code': 'Megerősítő kód',
      'Check your mailbox for the 6 digit confirmation code.':
        'Nézd meg az email-jeidet a 6 számjegyű megerősítő kódért.',
      'Congratulations! You have successfully reset your password.':
        'Gratulálunk! Sikeresen helyreállítottad a jelszavadat.',
      here: 'itt',
      'Cover image is required': 'A borítókép kötelező',
      'Name is required': 'A név megadása kötelező',
      'I accept the ': 'Elfogadom a ',
      'Should be at least 1 tag': 'Legalább egy taget meg kell adnod!',
      'You must accept GDPR before registration!':
        'El kell fogadnod a GDPR-t a regisztráció elött!',
      'Incorrect username or password.': 'Hibás felhasználónév vagy jelszó',
      'You must accept the terms and condition before registration!':
        'El kell fogadnod a felhasználási feltételeket a regisztráció elött!',
      'Passwords must match': 'A jelszavaknak egyezniük kell',
      'Your password must be at least 8 characters long, must contain one uppercase, one lowercase character and one number':
        'A jelszónak legalább 8 karakter hosszúnak kell lennie, tartalmaznia kell nagybetűt, kisbetűt és legalább 1 számot',
      'Confirm email': 'Email megerősítés',
      'To manage your WeddingManager Partner account, please sign in. Having problem with login? Go and reset your password':
        'Hogy kezelni tudd a WeddingManager Partner profilodat, kérlek jelentkezz be. Ha problémád van a bejelentkezéssel menj és állítsd vissza a jelszavad {{tSuffixProps}}',
      'Sign in': 'Bejelentkezés',
      Required: 'Kötelező',
      Reset: 'Visszaállítás',
      Action: 'Művelet',
      Amount: 'Mennyiség',
      Submit: 'Végrehajt',
      Search: 'Keresés',
      'Partner is required': 'A partner mező megadása kötelező',
      'Status is required': 'A státusz mező megadása kötelező',
      'Add a description to your inspiration (optional)':
        'Adj leírást az inspirációdhoz (opcionális)',
      'Search...': 'Keresés...',
      'Start to type...': 'Kezdj el gépelni...',
      'Phone number': 'Telefonszám',
      'Choose image': 'Válassz képet',
      Phone: 'Telefonszám',
      'Accept?': 'Elfogadod?',
      'Reject?': 'Elutasítod?',
      'Address is required': 'A cím kitöltése kötelező',
      'City is required': 'A város megadása kötelező',
      Monday: 'Hétfő',
      Tuesday: 'Kedd',
      Wednesday: 'Szerda',
      Thursday: 'Csütörtök',
      Friday: 'Péntek',
      Saturday: 'Szombat',
      Sunday: 'Vasárnap',
      "The uploaded content complies with the ": "A feltöltött tartalom megfelel a következőnek: ",
      'On which days can the ceremony be held': 'Mely napokon tartható szertartás',
      'Please check your fields, because some of them are missing or maybe invalid':
        'Kérlek ellenőrizd a mezőket, mert van amit nem töltöttél ki, vagy talán hibásan',
    },
    'Search in names only': 'Keresés a nevek között',
    'Quotes Rejected': 'Ajánlatok elutasítva',
    'Quotes Accepted': 'Ajánlatok elfogadva',
  },
}

export default translations
