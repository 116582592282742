import { availabilitiesAdapter } from './slice'

export const selectRoot = state => state.availability
const selectAvailabilities = state => selectRoot(state).availabilities
export const selectAvailability = state => selectRoot(state).availability

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
  selectById,
} = availabilitiesAdapter.getSelectors(selectAvailabilities)
