import { useHistory } from 'react-router-dom'
import Scrollbar from 'react-perfect-scrollbar'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import { Loader, Typography } from 'modules/ui'
import { QuoteRequestForm, QuoteResponseForm, useQuote } from 'modules/quote'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    width: '100%',
  },
  quote: {
    backgroundColor: theme.palette.background.paper,
    alignItems: 'flex-start',
    margin: 'auto',
    padding: theme.spacing(1),
    height: 'calc(100vh - 120px)',
  },
}))

const View = () => {
  const { quoteRequest, quoteResponse, loading, setAcceptRequestData, acceptRequestData } =
    useQuote()
  const classes = useStyles()
  const history = useHistory()

  if (!quoteRequest || loading) {
    return null
  }

  //accept request
  const handleQuoteResponseSubmit = async data => {
    setAcceptRequestData(data)
    history.push(`/quotes/${quoteRequest.id}/accept`)
  }

  //decline request
  const handleQuoteRequestSubmit = async () => {
    history.push(`/quotes/${quoteRequest.id}/reject`)
  }

  const initialState = quoteResponse || acceptRequestData || undefined

  return (
    <>
      <Grid className={classes.root} container spacing={2}>
        <Grid xs={4} item>
          <Paper className={classes.quote} title="Quote request">
            <Scrollbar>
              <Typography variant="h6">Quote request</Typography>
              <QuoteRequestForm
                onSubmit={handleQuoteRequestSubmit}
                initialValues={quoteRequest}
                disabled={quoteRequest.status !== 'PENDING'}
              />
            </Scrollbar>
          </Paper>
        </Grid>
        <Grid className={classes.quoteResponse} xs={8} item>
          <Paper className={classes.quote} title="Quote request">
            <Scrollbar>
              <Typography variant="h6">Quote response</Typography>
              <QuoteResponseForm
                initialValues={initialState}
                onSubmit={handleQuoteResponseSubmit}
                disabled={quoteRequest.status !== 'PENDING'}
                rejected={quoteRequest.status === 'REJECTED'}
              />
            </Scrollbar>
          </Paper>
        </Grid>
      </Grid>
      <Loader loading={loading} />
    </>
  )
}

export default View
