import { Grid, makeStyles } from '@material-ui/core'
import { Form, Formik } from 'formik'
import * as yup from 'yup'
import { TextField, SubmitButton } from 'modules/formik'
import FormError from './FormError'

const useStyles = makeStyles(({ spacing }) => ({
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: spacing(4),
    '& button': {
      marginLeft: spacing(1),
    },
  },
}))

const validationSchema = yup.object({
  code: yup.string().min(2, 'Too Short!').required('Required'),
  email: yup.string().email('Invalid email address').required('Required'),
})

function VerificationForm({ submit, email, error }) {
  const classes = useStyles()
  return (
    <Formik
      onSubmit={submit}
      validationSchema={validationSchema}
      initialValues={{ code: '', email }}
    >
      <Form>
        <Grid container spacing={2} justifyContent="center" alignContent="flex-end">
          <Grid item xs={12} sm={6} md={4}>
            {error && <FormError message={error?.message} />}

            <TextField
              id="email"
              name="email"
              label="Email"
              margin="normal"
              fullWidth
              type="email"
              disabled={!!email}
            />
            <TextField
              id="code"
              name="code"
              label="Confirmation code"
              margin="normal"
              fullWidth
              type="text"
            />
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.footer}>
          <SubmitButton variant="contained" color="primary">
            next
          </SubmitButton>
        </Grid>
      </Form>
    </Formik>
  )
}

export default VerificationForm
