import { useRef, useState } from 'react'
import { useField } from 'formik'
import { makeStyles } from '@material-ui/core/styles'
import RemoveIcon from '@material-ui/icons/Remove'
import AddIcon from '@material-ui/icons/Add'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import FormControl from '@material-ui/core/FormControl'
import { Button, FormLabel, Typography } from 'modules/ui'
import GalleryImageForm from './GalleryImageForm'
import Gallery from './Gallery'

const useStyles = makeStyles(() => ({
  addButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    backgroundColor: '#FAFAFAFA',
    border: 'solid',
    borderWidth: 0.1,
    borderColor: '#A9A9A9',
  },
  addButton: {
    width: '100%',
    height: '100%',
  },
  label: {
    marginBlock: 10,
  },
}))

export default ({
  id,
  name,
  label,
  fullWidth,
  margin,
  className,
  onChange,
  onBlur,
  imagesPath,
}) => {
  const [open, setOpen] = useState(false)
  const galleryForm = useRef()
  const [, meta, helpers] = useField({
    name,
    onChange,
    onBlur,
  })

  const classes = useStyles()
  const { setValue } = helpers
  const { value } = meta

  const handleGallerySubmit = data => {
    setValue([...value, data])
    setOpen(false)
  }

  const removeGalleryItem = ind => {
    let tmpGallery = [...value]
    tmpGallery.splice(value.length - 1 - ind, 1)
    setValue(tmpGallery)
  }

  return (
    <FormControl id={id} className={className} fullWidth={fullWidth} margin={margin}>
      <FormLabel className={classes.label}>{label}</FormLabel>
      <Gallery
        imagesPath={imagesPath}
        images={Object.values(value).reverse()}
        addItemComponent={() => (
          <div className={classes.addButtonWrapper}>
            <Button
              onClick={() => {
                setOpen(true)
              }}
              className={classes.addButton}
            >
              <AddIcon />
            </Button>
          </div>
        )}
        removeItemComponent={({ id }) => (
          <Button tooltip="Remove" onClick={() => removeGalleryItem(id)}>
            <RemoveIcon style={{ color: 'white' }} />
          </Button>
        )}
      />
      <Dialog fullWidth maxWidth="sm" open={open} onClose={() => setOpen(false)}>
        <DialogTitle id="form-dialog-title">
          <Typography variant="inherit">Add new image</Typography>
        </DialogTitle>
        <DialogContent>
          <GalleryImageForm onSubmit={handleGallerySubmit} innerRef={galleryForm} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => galleryForm.current.submitForm()} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </FormControl>
  )
}
