import { useContext, useEffect } from 'react'
import { Route, useRouteMatch, useHistory } from 'react-router-dom'
import { SignUpStateProvider, SingUpContext } from 'modules/auth'
import SignIn from './SignIn'
import Company from './Company'
import Registration from './Registration'
import Verification from './Verification'
import Details from './Details'
import Contact from './Contact'

const SignUp = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const { lastPath, setLastPath, state } = useContext(SingUpContext)
  const { userId: partnerId, type } = state
  const { pathname } = history.location

  useEffect(() => {
    if (type === 'Admin' && state.contactEmail !== '' && pathname === match.url) {
      history.push(`${match.url}/registration`)
      return
    }

    if (type !== 'Admin' && !partnerId && !lastPath && pathname !== match.url) {
      history.push(match.url)
      return
    }

    setLastPath(pathname)
  }, [pathname, lastPath, setLastPath, history, match.url, partnerId])

  return (
    <>
      <Route path={[match.url, `${match.url}/company`]} exact component={Company} />
      <Route path={`${match.url}/details`} exact component={Details} />
      <Route path={`${match.url}/contact`} exact component={Contact} />
      <Route path={`${match.url}/registration`} exact component={Registration} />
      <Route path={`${match.url}/verification`} exact component={Verification} />
      <Route path={`${match.url}/sign-in`} exact component={SignIn} />
    </>
  )
}

export default () => (
  <SignUpStateProvider>
    <SignUp />
  </SignUpStateProvider>
)
