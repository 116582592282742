import moment from 'moment'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import PeopleIcon from '@material-ui/icons/People'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import Tooltip from '@material-ui/core/Tooltip'
import Chip from '@material-ui/core/Chip'
import NoteIcon from '@material-ui/icons/Note'
import { Avatar, Typography } from 'modules/ui'
import ImageAttachment from './ImageAttachment'
import config from '../../../config'

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    backgroundColor: palette.background.paper,
    display: 'flex',
    margin: spacing(1),
    '& img': {
      borderRadius: spacing(1),
    },
  },
  primary: {
    justifyContent: 'flex-end',
  },
  secondary: {
    justifyContent: 'flex-start',
  },
  avatar: {
    marginRight: spacing(1),
  },

  messagePrimary: {
    backgroundColor: palette.primary.main,
    color: palette.primary.contrastText,
    textAlign: 'start',
    justifyContent: 'flex-start',
  },
  messageSecondary: {
    backgroundColor: palette.grey.main,
    color: 'black',
    textAlign: 'start',
    justifyContent: 'flex-end',
  },
  messageContainer: {
    display: 'inline-flex',
    flexDirection: 'column',
    borderRadius: '16px',
    minHeight: '32px',
    paddingLeft: '10px',
    paddingRight: '10px',
    paddingTop: '5px',
    paddingBottom: '5px',
    alignItems: 'center',
    fontSize: '0.825rem',
    verticalAlign: 'middle',
    justifyContent: 'center',
    width: 'auto',
    lineHeight: 1.25,
  },
  unsend: {
    opacity: 0.8,
  },
  errorIcon: {
    alignSelf: 'center',
  },
}))

const Message = ({
  imagesPath,
  text,
  attachment,
  sender,
  createdAt,
  userId,
  id,
  error,
  onClickAttachment,
}) => {
  const classes = useStyles()
  const isOwnMessage = sender.id === userId
  const unsend = !id

  return (
    <div className={clsx(classes.root, isOwnMessage ? classes.primary : classes.secondary)}>
      {!isOwnMessage && (
        <div className={classes.avatar}>
          <Tooltip placement="left" title={sender.name}>
            <div>
              <Avatar src={config.app.IMAGES_URL + '/' + sender.image.name}>
                <PeopleIcon />
              </Avatar>
            </div>
          </Tooltip>
        </div>
      )}
      {isOwnMessage && error && (
        <Tooltip title="Hiba történt!" placement="top">
          <ErrorOutlineIcon color="error" className={classes.errorIcon} />
        </Tooltip>
      )}
      <Tooltip title={moment(createdAt).format('LLL')} placement="right">
        <div
          className={clsx(
            isOwnMessage ? classes.messagePrimary : classes.messageSecondary,
            classes.messageContainer,
            unsend && classes.unsend
          )}
        >
          {!!text && (
            <Typography
              display="inline"
              className={isOwnMessage ? classes.messagePrimary : classes.messageSecondary}
            >
              {text}
            </Typography>
          )}
          {attachment?.type === 'image' && (
            <ImageAttachment
              src={imagesPath + '/' + attachment.payload}
              className={unsend && classes.unsend}
            />
          )}
          {attachment?.type === 'html' && (
            <div
              className={unsend && classes.unsend}
              dangerouslySetInnerHTML={{ __html: attachment.payload }}
            />
          )}
          {attachment?.type === 'QUOTE_RESPONSE' && (
            <Chip
              icon={<NoteIcon />}
              label="Quote response is created"
              clickable
              color="primary"
              onClick={() => onClickAttachment(attachment)}
            />
          )}
          {attachment?.type === 'QUOTE_REQUEST' && (
            <Chip
              icon={<NoteIcon />}
              label="Quote request is received"
              clickable
              onClick={() => onClickAttachment(attachment)}
            />
          )}
        </div>
      </Tooltip>
    </div>
  )
}

export default Message
