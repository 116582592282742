import * as yup from 'yup'
import { Form, Formik } from 'formik'
import { Card, CardContent, MenuItem } from '@material-ui/core'
import { TextField, SubmitButton, Select } from 'modules/formik'
import { Typography } from 'modules/ui'

const actions = [
  { name: 'Add', value: 'add' },
  { name: 'Remove', value: 'remove' },
]

const initialState = {
  action: '',
  amount: 0,
  note: '',
}

const validationSchema = yup.object({
  action: yup.string().required('Required'),
  amount: yup.number().min(1, 'Invalid amount').required('Required'),
})

function TransactionMaker({ onSubmit }) {
  return (
    <Formik initialValues={initialState} validationSchema={validationSchema} onSubmit={onSubmit}>
      <Form>
        <Card>
          <CardContent>
            <Typography variant="h6">Action</Typography>
            <Select name="action" label="Action" margin="normal" fullWidth>
              {actions.map(({ name, value }, i) => (
                <MenuItem key={i} value={value}>
                  {name}
                </MenuItem>
              ))}
            </Select>
            <TextField
              id="amount"
              name="amount"
              label="Amount"
              margin="normal"
              fullWidth
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
            />
            <TextField id="note" name="note" label="Megjegyzés" margin="normal" fullWidth />
            <SubmitButton variant="contained" color="primary" margin="dense">
              Submit
            </SubmitButton>
          </CardContent>
        </Card>
      </Form>
    </Formik>
  )
}

export default TransactionMaker
