import { pick } from 'ramda'
import { takeLatestAsync } from 'saga-toolkit'
import * as actions from './slice'
import { query, mutation } from 'modules/api'

const tagGroupField = [
  'id',
  'name',
  { 'image': ['name'] },
  { 'category': ['id', 'name'] },
  { 'tags': ['id', 'name'] },
]

const parseTagGroup = tagGroup => ({
  ...tagGroup,
  image: tagGroup.image?.name,
})

function* fetchTagGroups() {
  const options = {
    operation: 'tagGroups',
    fields: [
      'id',
      'name',
      { 'tags': ['id', 'name'] },
      { 'category': ['id', 'name'] },
    ]
  }

  const result = yield query(options, true)

  return result.tagGroups
}

function* fetchTagGroup({ meta }) {
  const { id } = meta.arg
  const options = {
    operation: 'tagGroup',
    fields: tagGroupField,
    variables: {
      id: {
        type: 'ID',
        value: id,
        required: true,
      }
    }
  }

  const { tagGroup } = yield query(options, true)

  return parseTagGroup(tagGroup)
}

function* searchTagGroup({ meta }) {
  const options = {
    operation: 'tagGroups',
    fields: ['name', 'id'],
    variables: {
      searchByName: {
        name: 'searchByName',
        type: 'String',
        value: meta.arg,
      }
    }
  }

  const result = yield query(options, true)

  return result.tagGroups
}

function* createTagGroup({ meta }) {
  const options = {
    operation: 'insertTagGroup',
    fields: ['id'],
    variables: {
      input: {
        value: meta.arg,
        type: 'TagGroupInput',
      },
    },
  }

  const result = yield mutation(options, true)

  return result.insertTagGroup
}

function* updateTagGroup({ meta }) {
  const { id } = meta.arg
  const body = pick(['name', 'image', 'category', 'tags'], meta.arg)

  const options = {
    operation: 'updateTagGroup',
    fields: tagGroupField,
    variables: {
      id: {
        value: id,
        type: 'ID'
      },
      input: {
        value: body,
        type: 'TagGroupInput'
      }
    },
  }

  const { updateTagGroup } = yield mutation(options, true)

  return parseTagGroup(updateTagGroup)
}

function* removeTagGroup({ meta }) {
  const { id } = meta.arg
  const options = {
    operation: 'deleteTagGroup',
    variables: {
      id: {
        value: id,
        type: 'ID',
      },
    },
  }

  const result = yield mutation(options, true)

  return result
}

export default [
  takeLatestAsync(actions.fetchTagGroups.type, fetchTagGroups),
  takeLatestAsync(actions.fetchTagGroup.type, fetchTagGroup),
  takeLatestAsync(actions.searchTagGroup.type, searchTagGroup),
  takeLatestAsync(actions.createTagGroup.type, createTagGroup),
  takeLatestAsync(actions.updateTagGroup.type, updateTagGroup),
  takeLatestAsync(actions.removeTagGroup.type, removeTagGroup),
]
