import { useState } from 'react'
import { ContentState, convertFromHTML, EditorState } from 'draft-js'
import { convertToHTML } from 'draft-convert'
import { Editor } from 'react-draft-wysiwyg'
import { FormHelperText, makeStyles } from '@material-ui/core'
import { FormLabel } from 'modules/ui'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import 'draft-js/dist/Draft.css'

const useStyles = makeStyles(({ spacing }) => ({
  wrapper: {
    padding: spacing(1),
    border: '1px solid #ccc',
  },
  editor: {
    padding: spacing(1),
    height: '300px',
  },
  toolbar: {
    border: '1px solid #ccc',
  },
  label: {
    fontSize: '12px',
    marginBottom: spacing(1),
    marginTop: '16px',
  },
}))

const RichTextEditor = ({
  value,
  error,
  helperText,
  label,
  onChange,
  disabled = false,
  toolbarOptions = ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'emoji', 'history'],
  converterOptions = {},
  ...props
}) => {
  const classes = useStyles()
  const blocksFromHTML = convertFromHTML(value || '')
  const state = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap
  )
  const [editorState, setEditorState] = useState(() => EditorState.createWithContent(state))

  const handleContentStateChange = () => {
    const currentContentAsHTML = convertToHTML(converterOptions)(editorState.getCurrentContent())
    onChange(currentContentAsHTML)
  }

  return (
    <>
      <FormLabel className={classes.label}>{label}</FormLabel>
      <Editor
        readOnly={disabled}
        onContentStateChange={handleContentStateChange}
        editorState={editorState}
        onEditorStateChange={setEditorState}
        defaultEditorState={value}
        wrapperClassName={classes.wrapper}
        editorClassName={classes.editor}
        toolbarClassName={classes.toolbar}
        toolbar={{
          options: toolbarOptions,
        }}
        {...props}
      />
      {error && <FormHelperText error>{helperText}</FormHelperText>}
    </>
  )
}

export default RichTextEditor
