import { blogsAdapter } from './slice'

export const selectRoot = state => state.blog
const selectBlogs = state => selectRoot(state).blogs
export const selectBlog = state => selectRoot(state).blog

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
  selectById,
} = blogsAdapter.getSelectors(selectBlogs)
