import * as yup from 'yup'
import { Formik, Form, useFormikContext } from 'formik'
import { pick } from 'rambda'
import { makeStyles, Grid, Divider, Chip } from '@material-ui/core'
import {
  TextField,
  RangeSlider,
  PlacesAutocomplete,
  Checkbox,
  RichTextEditor,
  SubmitButton,
} from 'modules/formik'
import { ProfileStatusSwitch, Covers, ProfilePicture, WeekdaySelect, radius } from 'modules/partner'
import { GoogleMap, Typography, Button } from 'modules/ui'
import { TagAutocompleteFormik } from 'modules/tag'
import validationSchemaObject from '../validationSchemaObject'

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(2),
  },
  fileUpload: {
    alignItems: 'flex-start',
  },
  categoryChip: {
    textTransform: 'uppercase',
    color: 'white',
    borderRadius: theme.spacing(1),
  },
  alignSelfCenter: {
    alignSelf: 'center',
  },
  sectionContainer: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
}))

const Section = ({ children, label }) => (
  <>
    <Typography variant="caption" color="textSecondary">
      {label}
    </Typography>
    <Divider />
    {children}
  </>
)

const validationSchema = yup
  .object()
  .shape(
    pick(
      [
        'name',
        'description',
        'phone',
        'tags',
        'email',
        'contactName',
        'contactEmail',
        'contactPhone',
        'fixLocation',
        'areaLocation',
        'profileImage',
      ],
      validationSchemaObject
    ),
    [['areaLocation', 'fixLocation']]
  )

const FormBody = ({ user, categoryName, isDisplayedField, imagesPath, showDeleteConfirm }) => {
  const { values, errors } = useFormikContext()
  const classes = useStyles()

  return (
    <Form className={classes.root} noValidate autoComplete="off">
      <Grid container spacing={4}>
        <Grid className={classes.sectionContainer} item xs={12} lg={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} sm={3} md={3} lg={3}>
                  <ProfilePicture name="profileImage" imagesPath={imagesPath} />
                </Grid>
                <Grid item xs={9} md={9} lg={8} className={classes.alignSelfCenter}>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12}>
                      <Typography variant="h6">{user?.name}</Typography>
                    </Grid>
                    {categoryName && (
                      <Grid item>
                        <Chip
                          className={classes.categoryChip}
                          color="primary"
                          label={categoryName}
                        />
                      </Grid>
                    )}
                    <Grid item>
                      <ProfileStatusSwitch status={user.status} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="vendorName"
                name="name"
                label="Vendor name"
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="email"
                name="email"
                label="Visible email"
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="phone"
                name="phone"
                label="Phone number"
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="contactName"
                name="contactName"
                label="Contact name"
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="contactEmail"
                disabled
                name="contactEmail"
                label="Contact email"
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="contactPhone"
                name="contactPhone"
                label="Contact phone number"
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            {isDisplayedField('fixLocation') && (
              <>
                <PlacesAutocomplete margin="normal" fullWidth name="fixLocation" />
                <Grid item xs={6}>
                  <TextField
                    name="fixLocation.city"
                    disabled
                    placeholder="City"
                    margin="normal"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="fixLocation.zip"
                    disabled
                    placeholder="Zip"
                    margin="normal"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="fixLocation.street"
                    disabled
                    placeholder="Street"
                    margin="normal"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="fixLocation.streetNumber"
                    disabled
                    placeholder="Street Number"
                    margin="normal"
                    fullWidth
                  />
                </Grid>
                <GoogleMap {...values.fixLocation} />
              </>
            )}
            {isDisplayedField('areaLocation') && (
              <>
                <PlacesAutocomplete onlyCities margin="normal" fullWidth name="areaLocation" />
                <TextField
                  name="areaLocation.city"
                  disabled
                  placeholder="City"
                  margin="normal"
                  fullWidth
                />
                <RangeSlider
                  singleValue
                  label="Radius (km)"
                  margin="normal"
                  valueLabelFormat={radius.valueLabelFormatForRadius}
                  step={null}
                  defaultValue={10}
                  min={10}
                  max={200}
                  marks={radius.radiusMarks}
                  fullWidth
                  disabled={values.areaLocation?.availableNationwide}
                  name="areaLocation.radius"
                />

                <Checkbox label="Available nationwide" name="areaLocation.availableNationwide" />
              </>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Grid item xs={12}>
            <RichTextEditor id="description" name="description" label="Description" />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="website"
              placeholder="https://google.com"
              name="website"
              label="Website"
              margin="normal"
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid container className={classes.sectionContainer}>
            <Grid item xs={12}>
              <TagAutocompleteFormik
                categoryId={user?.category.id}
                id="tags"
                name="tags"
                label="Tags"
                labelId="partner-edit-form-tag-ids-select"
                margin="normal"
                fullWidth
                multiple
                filterSelectedOptions
                disableClearable
              />
            </Grid>
          </Grid>
          {isDisplayedField('availableWeekdays') && <WeekdaySelect />}
          {isDisplayedField('standingCapacity') && (
            <TextField
              label="Standing capacity"
              name="standingCapacity"
              type="number"
              margin="normal"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          {isDisplayedField('seatingCapacity') && (
            <TextField
              name="seatingCapacity"
              label="Standing capacity"
              type="number"
              margin="normal"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          {isDisplayedField('guestRange') && (
            <Grid container className={classes.sectionContainer}>
              <Grid item xs={12}>
                <Section label="Number of guests">
                  <RangeSlider
                    margin="normal"
                    fullWidth
                    id="guestRange"
                    name="guestRange"
                    min={0}
                    max={300}
                    valueLabelDisplay="auto"
                  />
                </Section>
              </Grid>
            </Grid>
          )}
          <Grid container spacing={2} className={classes.sectionContainer}>
            <Grid item xs={12}>
              <Section label="Cover photos">
                <Covers name="coverImages" imagesPath={imagesPath} />
              </Section>
            </Grid>
          </Grid>

          {user?.type === 'Partner' && (
            <Grid container spacing={2} className={classes.sectionContainer}>
              <Grid item xs={12}>
                <Button onClick={showDeleteConfirm} color="primary">
                  Delete profile
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
        {!!Object.keys(errors).length && (
          <Grid xs={12} item align="center">
            <Typography color="error">
              Please check your fields, because some of them are missing or maybe invalid
            </Typography>
          </Grid>
        )}
        <Grid xs={12} item align="center">
          <SubmitButton variant="contained" color="primary">
            Save
          </SubmitButton>
        </Grid>
      </Grid>
    </Form>
  )
}

const PartnerProfileForm = ({
  onSubmit,
  initialState,
  imagesPath,
  user,
  extraFields,
  showDeleteConfirm,
}) => {
  const isDisplayedField = field => extraFields.includes(field)
  const categoryName = user.category?.name

  return (
    <Formik onSubmit={onSubmit} validationSchema={validationSchema} initialValues={initialState}>
      <FormBody
        user={user}
        imagesPath={imagesPath}
        categoryName={categoryName}
        isDisplayedField={isDisplayedField}
        showDeleteConfirm={showDeleteConfirm}
      />
    </Formik>
  )
}

export default PartnerProfileForm
