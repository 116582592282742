import { quoteRequestsAdapter } from './slice'

export const selectRoot = state => state.quote
const selectQuoteRequests = state => selectRoot(state).quoteRequests
export const selectQuoteRequest = state => selectRoot(state).quoteRequest
export const selectOffset = state => selectRoot(state).offset
export const selectLimit = state => selectRoot(state).limit
export const selectLoading = state => !!selectRoot(state).parallelTasks

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
  selectById,
} = quoteRequestsAdapter.getSelectors(selectQuoteRequests)
