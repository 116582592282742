import { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Loader, Button, Typography } from 'modules/ui'
import { useQuote } from 'modules/quote'

const Reject = () => {
  const { id } = useParams()
  const history = useHistory()
  const [open, setOpen] = useState(false)
  const { quoteRequest, declineQuoteRequest } = useQuote()
  const [loading, setLoading] = useState(false)

  const goBack = () => {
    history.push(`/quotes/${id}/view`)
  }

  const handleCancel = () => {
    setOpen(false)
  }

  const handleReject = async () => {
    setLoading(true)
    await declineQuoteRequest({
      input: {
        requestId: quoteRequest.id,
        partnerId: quoteRequest.partnerId,
        coupleId: quoteRequest.coupleId,
      },
    })
    setLoading(false)
    handleCancel()
  }

  useEffect(() => {
    setOpen(true)
  }, [])

  if (!quoteRequest) {
    return null
  }

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={handleCancel} TransitionProps={{ onExited: goBack }}>
      <DialogTitle id="form-dialog-title">
        <Typography
          tParams={{ couple: `${quoteRequest.myName} & ${quoteRequest.partnersName}` }}
          variant="inherit"
        >
          {`Reject quote from {{couple}}?`}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography
            tParams={{ couple: `${quoteRequest.myName} & ${quoteRequest.partnersName}` }}
            variant="inherit"
          >
            {`Are you sure you want to reject quote from {{couple}}?`}
          </Typography>
        </DialogContentText>
        <Loader loading={loading} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleReject} color="primary" disabled={loading}>
          Reject
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default Reject
