import { useRouteMatch } from 'react-router'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { NavButton } from 'modules/ui'
import { QuoteRequestsList, useQuote } from 'modules/quote'
import { useProfile } from 'modules/profile'
import { Page } from 'modules/ui'

const RowActions = ({ row }) => {
  const match = useRouteMatch()

  return (
    <>
      <NavButton to={`${match.url}/${row.id}/view`} tooltip="View">
        <VisibilityIcon fontSize="small" />
      </NavButton>
    </>
  )
}

const List = () => {
  const { user } = useProfile()
  const { quoteRequests, loading, fetchQuoteRequests, total, limit, offset, setOffset } = useQuote()

  return (
    <Page title="Requests for quotations" fullHeight>
      <QuoteRequestsList
        quoteRequests={quoteRequests}
        loading={loading}
        rowActionsComponent={RowActions}
        fetchQuoteRequests={fetchQuoteRequests}
        total={total}
        hiddenPartner={user.type === 'Partner'}
        setOffset={setOffset}
        offset={offset}
        limit={limit}
      />
    </Page>
  )
}

export default List
