import { forwardRef } from 'react'
import { Avatar, Tooltip } from '@material-ui/core'
import { Link } from 'modules/ui'
import config from '../../../config'

const NavLinkRef = forwardRef((props, ref) => (
  <div ref={ref}>
    <Link {...props} />
  </div>
))

const AvatarWithLink = ({ user, to }) => {
  const { profileImage, name = '', email = '' } = user
  const profileToolTipText = `${name} - ${email}`

  const imagesPath = config.app.IMAGES_URL

  return (
    <Tooltip title={profileToolTipText}>
      <Avatar
        alt={profileToolTipText}
        src={`${imagesPath}/${profileImage?.name}`}
        component={NavLinkRef}
        to={to}
      />
    </Tooltip>
  )
}

export default AvatarWithLink
