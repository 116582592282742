import { useRouteMatch } from 'react-router'
import DoneIcon from '@material-ui/icons/Done'
import ClearIcon from '@material-ui/icons/Clear'
import { NavButton } from 'modules/ui'
import { AvailabilitiesList, useAvailability } from 'modules/availability'
import { Page } from 'modules/ui'

const RowActions = ({ row }) => {
  const match = useRouteMatch()
  const { id, status } = row

  return (
    <>
      {status !== 'accepted' && (
        <NavButton to={`${match.url}/${id}/accept`} tooltip="Accepted">
          <DoneIcon fontSize="small" />
        </NavButton>
      )}
      {status !== 'rejected' && (
        <NavButton to={`${match.url}/${id}/reject`} tooltip="Rejected">
          <ClearIcon fontSize="small" />
        </NavButton>
      )}
    </>
  )
}

const List = () => {
  const {
    availabilities,
    fetchAvailabilities,
    total,
    limit,
    offset,
    setOffset,
    loading,
  } = useAvailability()

  return (
    <Page title="Availability" fullHeight>
      <AvailabilitiesList
        fetchAvailabilities={fetchAvailabilities}
        setOffset={setOffset}
        offset={offset}
        limit={limit}
        total={total}
        availabilities={availabilities}
        loading={loading}
        rowActionsComponent={RowActions}
      />
    </Page>
  )
}

export default List
