import { takeLatestAsync } from 'saga-toolkit'
import moment from 'moment'
import { select } from '@redux-saga/core/effects'
import { query, mutation } from 'modules/api'
import * as fields from './fields'
import * as actions from './slice'
import * as selectors from './selectors'

const parseQuoteRequest = quoteRequest => ({
  ...quoteRequest,
  weddingDate: moment(quoteRequest.weddingDate).format('LL'),
})

function* fetchQuoteRequest({ meta }) {
  const { id } = meta.arg
  const result = yield query(
    [
      {
        operation: 'quoteRequest',
        variables: {
          id: {
            value: id,
            type: 'ID',
            required: true,
          },
        },
        fields: fields.quoteRequestFields,
      },
    ],
    true
  )

  return parseQuoteRequest(result.quoteRequest)
}

function* declineQuoteRequest({ meta }) {
  const { input } = meta.arg
  const { declineQuoteRequest } = yield mutation(
    [
      {
        operation: 'rejectQuoteRequest',
        variables: {
          input: {
            value: input,
            type: 'NewQuoteResponseInput',
            required: true,
          },
        },
        fields: ['id'],
      },
    ],
    true
  )

  return declineQuoteRequest
}

function* fetchQuoteResponse({ meta }) {
  const { id } = meta.arg
  const result = yield query(
    [
      {
        operation: 'quoteResponse',
        variables: {
          requestId: {
            value: id,
            type: 'ID',
          },
        },
        fields: fields.quoteResponseFields,
      },
    ],
    true
  )

  return result.quoteResponse
}

function* fetchQuoteRequests() {
  const limit = yield select(selectors.selectLimit)
  const offset = yield select(selectors.selectOffset)
  const { quoteRequests } = yield query(
    [
      {
        operation: 'quoteRequests',
        fields: ['total', { elements: fields.quoteRequestsFields }],
        variables: {
          filter: {
            value: {
              limit,
              offset,
            },
            type: 'QuoteRequestFilter!',
          },
        },
      },
    ],
    true
  )

  return quoteRequests
}

function* acceptQuoteRequest({ meta }) {
  const { input } = meta.arg

  const { acceptQuoteRequest } = yield mutation(
    [
      {
        operation: 'acceptQuoteRequest',
        variables: {
          input: {
            value: input,
            type: 'NewQuoteResponseInput',
            required: true,
          },
        },
        fields: fields.quoteResponseFields,
      },
    ],
    true
  )

  return acceptQuoteRequest
}

export default [
  takeLatestAsync(actions.declineQuoteRequest.type, declineQuoteRequest),
  takeLatestAsync(actions.acceptQuoteRequest.type, acceptQuoteRequest),
  takeLatestAsync(actions.fetchQuoteRequest.type, fetchQuoteRequest),
  takeLatestAsync(actions.fetchQuoteRequests.type, fetchQuoteRequests),
  takeLatestAsync(actions.fetchQuoteResponse.type, fetchQuoteResponse),
]
