import UITypography from '@material-ui/core/Typography'
import { useT } from 'modules/i18n'

const isString = value => {
  return typeof value === 'string'
}

export const Typography = ({ children, tParams, ...props }) => {
  const t = useT()

  return (
    <UITypography {...props}>
      {isString(children) ? t(children, { ...tParams }) : children}
    </UITypography>
  )
}

export default Typography
