import UIFormHelperText from '@material-ui/core/FormHelperText'
import { useT } from 'modules/i18n'

const isString = value => {
  return typeof value === 'string'
}

export const FormHelperText = ({ children, ...props }) => {
  const t = useT()

  return (
    <UIFormHelperText {...props}>{isString(children) ? t(children) : children}</UIFormHelperText>
  )
}

export default FormHelperText
