import { categoriesAdapter } from './slice'

export const selectRoot = state => state.category
export const selectCategories = state => selectRoot(state).categories
export const selectCategory = state => selectRoot(state).category

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
  selectById,
} = categoriesAdapter.getSelectors(selectCategories)
