import { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { useCategory } from 'modules/category'
import { SignUpStepper, SingUpContext, CompanyDetailsForm } from 'modules/auth'

const Details = () => {
  const { categories, loading: categoryLoading } = useCategory()
  const history = useHistory()
  const { state, setState } = useContext(SingUpContext)

  const submitForm = values => {
    setState({ ...state, ...values })
    history.push('/sign-up/contact')
  }

  return (
    <SignUpStepper>
      <CompanyDetailsForm
        categories={categories}
        categoryLoading={categoryLoading}
        submitForm={submitForm}
        state={state}
      />
    </SignUpStepper>
  )
}

export default Details
