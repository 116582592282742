import { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Loader, Typography, Button } from 'modules/ui'

const AvailabilityDialog = ({
  dialogContentText,
  onAccept,
  onReject,
  submitLabel,
  dialogTitle,
}) => {
  const history = useHistory()
  const { id } = useParams()
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)

  const close = () => {
    setOpen(false)
  }

  const goBack = () => {
    history.push(`/availabilities`)
  }

  const handleSubmit = async () => {
    setLoading(true)

    switch (true) {
      case !!onAccept:
        await onAccept({ id })
        break
      case !!onReject:
        await onReject({ id })
        break
      default:
        break
    }

    setLoading(false)
    close()
  }

  useEffect(() => {
    setOpen(true)
  }, [])

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={close} TransitionProps={{ onExited: goBack }}>
      <DialogTitle id="form-dialog-title">
        <Typography variant="inherit">{dialogTitle}</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{dialogContentText} </DialogContentText>
        <Loader loading={loading} />
      </DialogContent>
      <DialogActions>
        <Button size="small" onClick={close}>
          Cancel
        </Button>
        <Button
          size="small"
          color="primary"
          variant="contained"
          disabled={loading}
          onClick={handleSubmit}
        >
          {submitLabel}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AvailabilityDialog
