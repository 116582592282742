import { useState } from 'react'
import { DataGrid } from '@material-ui/data-grid'
import moment from 'moment'

const createColumns = ({ RowActions }) => [
  {
    field: 'name',
    headerName: 'Name',
    width: 220,
  },
  {
    field: 'type',
    headerName: 'Type',
    width: 140,
  },
  {
    field: 'role',
    headerName: 'Role',
    width: 140,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 140,
  },
  {
    field: 'createdAt',
    headerName: 'Created at',
    width: 220,
    valueGetter: ({ row }) => moment(row.createdAt).format('LLL'),
  },
  {
    field: '',
    headerName: 'Actions',
    width: 220,
    sortable: false,
    disableClickEventBubbling: true,
    renderCell: RowActions,
  },
]

export default ({ users, rowActionsComponent: RowActions, ...props }) => {
  const columns = createColumns({ RowActions })
  const [pageSize, setPageSize] = useState(25)

  return (
    <DataGrid
      rows={users}
      columns={columns}
      pageSize={pageSize}
      onPageSizeChange={newPageSize => setPageSize(newPageSize)}
      rowsPerPageOptions={[25, 50, 100]}
      pagination
      {...props}
    />
  )
}
