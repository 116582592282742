import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import GridList from '@material-ui/core/GridList'
import GridListTile from '@material-ui/core/GridListTile'
import ImageListItemBar from '@material-ui/core/ImageListItemBar'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: theme.palette.background.paper,
    alignItems: 'center',
    flexDirection: 'row',
  },
  gridList: {
    width: '100%',
    flexWrap: 'wrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
}))

const useCols = () => {
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.up('sm'))
  const md = useMediaQuery(theme.breakpoints.up('md'))
  const lg = useMediaQuery(theme.breakpoints.up('lg'))

  switch (true) {
    case lg: return 4.0
    case md: return 3.0
    case sm: return 2.0
    default: return 2.0
  }
}

export default ({ imagesPath, images, addItemComponent: AddItemComponent, removeItemComponent: RemoveItemComponent }) => {
  const classes = useStyles()
  const cols = useCols()

  return (
    <div>
      <div className={classes.root}>
        <GridList className={classes.gridList} cols={cols}>
          <GridListTile>
            {AddItemComponent && <AddItemComponent />}
          </GridListTile>
          {images.map(({ name, image }, i) => (
            <GridListTile key={i}>
              {
                image instanceof File ?
                  <img src={URL.createObjectURL(image)} alt={name} />
                  :
                  <img src={imagesPath + '/' + image} alt={name} />
              }
              <ImageListItemBar
                title={name}
                classes={{
                  root: classes.titleBar,
                  title: classes.title,
                }}
                actionIcon={(() => <RemoveItemComponent id={i} />)()}
              />
            </GridListTile>
          ))}
        </GridList>
      </div>
    </div>
  )
}
