import { useState } from 'react'
import { FormControl, makeStyles } from '@material-ui/core'
import { Formik, useFormikContext, Form } from 'formik'
import { FileUploaderField, TextField, SubmitButton } from 'modules/formik'
import { Button, FormLabel } from 'modules/ui'
import config from '../../../config'

const useStyles = makeStyles(theme => ({
  cover: {
    width: 'auto',
    height: 'auto',
    maxWidth: '50%',
    maxHeight: '50%',
    marginTop: theme.spacing(2),
  },
  center: {
    alignItems: 'center',
  },
  replaceButton: {
    marginTop: theme.spacing(2),
  },
}))

const RemoveImageButton = ({ setImage }) => {
  const { values, setValues } = useFormikContext()

  const removeImage = () => {
    setImage(null)
    setValues({ ...values, image: '' })
  }

  return (
    <Button size="small" onClick={removeImage}>
      replace image
    </Button>
  )
}

function CoverForm({ initialState, onSubmit }) {
  const classes = useStyles()
  const imagesPath = config.app.IMAGES_URL
  const [image, setImage] = useState(initialState?.image)

  if (!initialState) {
    return null
  }

  return (
    <Formik initialValues={initialState} onSubmit={onSubmit}>
      <Form noValidate autoComplete="off">
        <TextField id="name" name="name" label="Name" margin="normal" fullWidth />
        {image ? (
          <FormControl fullWidth margin="normal" className={classes.center}>
            <FormLabel>Cover Image</FormLabel>
            <img alt="cover" className={classes.cover} src={`${imagesPath}/${image}`} />
            <div className={classes.replaceButton}>
              <RemoveImageButton setImage={setImage} />
            </div>
          </FormControl>
        ) : (
          <FileUploaderField
            margin="normal"
            label="Upload cover image"
            name="image"
            fullWidth
            singleImage
            withIcon={false}
            withPreview
            buttonText="Choose image"
            imgExtension={['.jpg', '.png']}
            maxFileSize={config.app.MAX_ATTACHMENT_SIZE}
          />
        )}
        <SubmitButton>Save</SubmitButton>
      </Form>
    </Formik>
  )
}

export default CoverForm
