import { Route, useRouteMatch } from 'react-router-dom'
import View from './View'
import Reject from './Reject'
import Accept from './Accept'
import List from './List'

const Quotes = () => {
  const match = useRouteMatch()

  return (
    <>
      <Route path={`${match.url}`} exact component={List} />
      <Route
        path={[`${match.url}/:id/view`, `${match.url}/:id/reject`, `${match.url}/:id/accept`]}
        component={View}
      />
      <Route path={`${match.url}/:id/reject`} component={Reject} />
      <Route path={`${match.url}/:id/accept`} component={Accept} />
    </>
  )
}

export default Quotes
