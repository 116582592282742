import { usersAdapter } from './slice'

export const selectRoot = state => state.user
export const selectUsers = state => selectRoot(state).users
export const selectUser = state => selectRoot(state).user

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
  selectById,
} = usersAdapter.getSelectors(selectUsers)
