import { takeLatestAsync } from 'saga-toolkit'
import * as actions from './slice'
import { query } from 'modules/api'

const coupleFields = [
  'id',
  'myName',
  'partnersName',
  'whoAreYou',
  'createdAt',
  'email',
  { profileImage: ['name'] },
  { likedPartners: ['id'] },
  {
    likedItems: ['id'],
  },
]

const parseCouple = couple => ({
  ...couple,
  profileImage: couple.profileImage?.name,
})

function* fetchCouples() {
  const options = {
    operation: 'couples',
    fields: coupleFields,
  }

  const result = yield query(options, true)

  const parsedCouples = result.couples?.map(parseCouple)

  return parsedCouples
}

function* fetchCouple({ meta }) {
  const { id } = meta.arg
  const options = {
    operation: 'couple',
    fields: coupleFields,
    variables: {
      id: {
        type: 'ID',
        value: id,
        required: true,
      },
    },
  }

  const { couple } = yield query(options, true)

  return parseCouple(couple)
}

export default [
  takeLatestAsync(actions.fetchCouples.type, fetchCouples),
  takeLatestAsync(actions.fetchCouple.type, fetchCouple),
]
