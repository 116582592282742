import { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import { makeStyles } from '@material-ui/core'
import { Loader, Typography, Button } from 'modules/ui'
import { uploadToS3 } from 'modules/storage'
import { useQuote } from 'modules/quote'
import { useProfile } from 'modules/profile'

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(1),
  },
  bold: {
    fontWeight: 800,
  },
  tokens: { display: 'flex', marginTop: theme.spacing(2), justifyContent: 'center' },
}))

const Reject = () => {
  const { id } = useParams()
  const history = useHistory()
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const { quoteRequest, acceptQuoteRequest, acceptRequestData } = useQuote()
  const {
    user: { wallet },
  } = useProfile()
  const [loading, setLoading] = useState(false)

  const goBack = () => {
    history.push(`/quotes/${id}/view`)
  }

  const handleCancel = () => {
    setOpen(false)
  }

  const handleAccept = async () => {
    setLoading(true)
    const { attachments } = acceptRequestData
    const filesToUpload = attachments.filter(attachment => attachment instanceof File)
    const tasks = filesToUpload.map(async file => {
      return {
        hash: await uploadToS3(file),
        name: file.name,
      }
    })
    const newFiles = await Promise.all(tasks)

    const response = {
      ...acceptRequestData,
      requestId: quoteRequest.id,
      partnerId: quoteRequest.partnerId,
      coupleId: quoteRequest.coupleId,
      attachments: [...attachments.filter(attachment => !attachment instanceof File), ...newFiles],
    }

    await acceptQuoteRequest({ input: response })
    setLoading(false)
    handleCancel()
  }

  useEffect(() => {
    setOpen(true)
  }, [])

  const getDialogStatus = () => {
    return wallet.balance >= 10
      ? {
          text: (
            <>
              <Typography
                variant="body1"
                tParams={{ couple: `${quoteRequest.myName} & ${quoteRequest.partnersName}` }}
              >
                {'Are you sure you want to accept quote from {{couple}}?'}
              </Typography>
              <Typography>
                <Typography display="inline" variant="body1">
                  {'This will cost you: '}
                </Typography>
                <Typography display="inline" className={classes.bold}>
                  10 tokens
                </Typography>
              </Typography>
            </>
          ),
          status: 'acceptable',
        }
      : {
          text: (
            <Typography
              tParams={{ couple: `${quoteRequest.myName} & ${quoteRequest.partnersName}` }}
              variant="body1"
            >
              {
                "You don't have enough tokens to accept quote from {{couple}}. You need at least 10 tokens to accept a quote."
              }
            </Typography>
          ),
          status: 'unacceptable',
        }
  }

  if (!quoteRequest) {
    return null
  }

  const { text, status } = getDialogStatus()
  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={handleCancel} TransitionProps={{ onExited: goBack }}>
      <DialogTitle id="form-dialog-title">
        <Typography tParams={{ couple: `${quoteRequest.myName} & ${quoteRequest.partnersName}` }}>
          {'Accept quote from {{couple}}?'}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {text}
          <Typography variant="body1" className={classes.tokens}>
            <Typography tParams={{ tokens: wallet.balance }} className={classes.bold}>
              {'Available tokens: {{tokens}}'}
            </Typography>
            <MonetizationOnIcon className={classes.icon} />
          </Typography>
        </DialogContentText>
        <Loader loading={loading} />
      </DialogContent>
      <DialogActions>
        <Button size="small" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={loading || status !== 'acceptable'}
          size="small"
          onClick={handleAccept}
        >
          Accept
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default Reject
