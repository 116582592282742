import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'
import { createSagaAction } from 'saga-toolkit'

const name = 'couple'

export const couplesAdapter = createEntityAdapter()

const initialState = {
  loading: false,
  error: null,
  couples: couplesAdapter.getInitialState(),
  couple: null,
}

export const fetchCouples = createSagaAction(`${name}/fetchCouples`)
export const fetchCouple = createSagaAction(`${name}/fetchCouple`)

const handlePending = state => ({
  ...state,
  loading: true,
})

const handleRejected = (state, { error }) => ({
  ...state,
  error,
  loading: false,
})

const slice = createSlice({
  name,
  initialState,
  reducers: {
    clearCouple: state => ({
      ...state,
      couple: null,
    }),
    clear: () => initialState,
  },
  extraReducers: {
    // fetchCouples
    [fetchCouples.pending]: handlePending,
    [fetchCouples.fulfilled]: (state, { payload }) => {
      couplesAdapter.setAll(state.couples, payload)
      state.loading = false
    },
    [fetchCouples.rejected]: handleRejected,

    // fetchCouple
    [fetchCouple.pending]: handlePending,
    [fetchCouple.fulfilled]: (state, { payload }) => ({
      ...state,
      couple: payload,
      loading: false,
    }),
    [fetchCouple.rejected]: handleRejected,
  },
})

export const { clearCouple, clear } = slice.actions

export default slice.reducer
