import { useState } from 'react'
import { useHistory } from 'react-router'
import Scrollbar from 'react-perfect-scrollbar'
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core'
import { useProfile } from 'modules/profile'
import { uploadToS3 } from 'modules/storage'
import { useInspiration, PartnerItemForm } from 'modules/inspiration'
import { useTag } from 'modules/tag'
import { Typography } from 'modules/ui'

export default function New() {
  const { createItem } = useInspiration()
  const history = useHistory()
  const [open, setOpen] = useState(true)
  const { user } = useProfile()
  const { tags } = useTag()

  const handleSubmit = async data => {
    const { gallery } = data

    const tasks = gallery.map(async ({ image, name }) => {
      const _image = await uploadToS3(image)
      return {
        name,
        image: _image,
      }
    })

    const newImages = await Promise.all(tasks)
    const itemData = {
      ...data,
      termsAndConditions: undefined,
      partner: user.id,
      coverImage: data.coverImage && (await uploadToS3(data.coverImage)),
      gallery: newImages,
    }
    await createItem(itemData)
    handleCancel()
  }

  const goBack = () => {
    history.push(`/inspiration`)
  }

  const handleCancel = () => {
    setOpen(false)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Dialog
      open={open}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="sm"
      onClose={handleCancel}
      TransitionProps={{ onExited: goBack }}
    >
      <Scrollbar>
        <DialogTitle id="form-dialog-title">
          <Typography variant="inherit">New inspiration</Typography>
        </DialogTitle>
        <DialogContent>
          <PartnerItemForm
            category={user.category}
            onSubmit={handleSubmit}
            onClose={handleClose}
            tags={tags}
          />
        </DialogContent>
      </Scrollbar>
    </Dialog>
  )
}
