import { createTheme } from '@material-ui/core/styles'
import shadows from '@material-ui/core/styles/shadows'

export default createTheme({
  palette: {
    primary: {
      main: '#FF5F5F',
      contrastText: '#FFFFFF',
    },
    linearPrimary: {
      main: 'linear-gradient(135deg, rgba(247,56,97,1) 0%, rgba(254,113,44,1) 100%)',
    },
    grey: {
      main: '#E4E4E4',
    },
  },
  overrides: {
    MuiButton: {
      containedPrimary: {
        color: '#FFFFFF',
        background: 'linear-gradient(135deg, rgba(247,56,97,1) 0%, rgba(254,113,44,1) 100%)',
        border: 0,
        borderRadius: 3,
        boxShadow: 'none',
        '&:hover': {
          boxShadow: shadows[5],
        },
      },
    },
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: '#f2f2f2',
        },
      },
    },
  },
})
