import { takeLatestAsync } from 'saga-toolkit'
import * as actions from './slice'
import { query, mutation } from 'modules/api'

const fields = ['id', 'name', { image: ['name'] }]

const parseCover = cover => ({
  ...cover,
  image: cover.image?.name,
})

function* fetchCovers() {
  const options = {
    operation: 'covers',
    fields,
  }

  const result = yield query(options, true)

  const covers = result?.covers?.map(parseCover)
  return covers
}

function* fetchCover({ meta }) {
  const { id } = meta.arg

  const options = {
    operation: 'cover',
    fields,
    variables: {
      id: {
        type: 'ID',
        value: id,
        required: true,
      },
    },
  }

  const { cover } = yield query(options, true)

  return parseCover(cover)
}

function* insertCover({ meta }) {
  const options = {
    operation: 'insertCover',
    fields,
    variables: {
      input: {
        type: 'CoverInput!',
        value: meta.arg,
      },
    },
  }

  const result = yield mutation(options, true)

  return result.insertCover
}

function* updateCover({ meta }) {
  const options = {
    operation: 'updateCover',
    fields,
    variables: {
      input: { type: 'CoverInput', value: meta.arg, required: true },
    },
  }

  const { updateCover } = yield mutation(options, true)

  return parseCover(updateCover)
}

function* deleteCover({ meta }) {
  const { id } = meta.arg
  const options = {
    operation: 'deleteCover',
    variables: {
      id: {
        value: id,
        type: 'ID!',
      },
    },
  }

  const result = yield mutation(options, true)

  return result
}

const sagas = [
  takeLatestAsync(actions.fetchCovers.type, fetchCovers),
  takeLatestAsync(actions.fetchCover.type, fetchCover),
  takeLatestAsync(actions.insertCover.type, insertCover),
  takeLatestAsync(actions.updateCover.type, updateCover),
  takeLatestAsync(actions.deleteCover.type, deleteCover),
]

export default sagas
