import { useField } from 'formik'
import PlacesAutocompleteBase, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import FormControl from '@material-ui/core/FormControl'
import { makeStyles } from '@material-ui/core/styles'
import { TextField, FormLabel, FormHelperText } from 'modules/ui'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(1),
    width: '100%',
  },
}))

const PlacesAutocomplete = ({ name, margin, fullWidth, onlyCities, disabled, ...props }) => {
  const classes = useStyles()
  const [, meta, helpers] = useField({
    name,
  })

  const { setValue } = helpers
  const { value } = meta

  const handleChange = address => {
    setValue({ ...value, address })
  }

  const getDataFromAddressComponents = (addressComponents, data) =>
    addressComponents.find(component => component.types.includes(data))?.long_name

  const handleSelect = async address => {
    const results = await geocodeByAddress(address)
    const formattedAddress = results[0].formatted_address
    const city = getDataFromAddressComponents(results[0]?.address_components, 'locality')

    if (onlyCities) {
      setValue({ ...value, address: formattedAddress, city })
      return
    }

    const zip = getDataFromAddressComponents(results[0]?.address_components, 'postal_code')
    const street = getDataFromAddressComponents(results[0]?.address_components, 'route')
    const street_number = getDataFromAddressComponents(
      results[0]?.address_components,
      'street_number'
    )
    const latLng = await getLatLng(results[0])
    setValue({
      ...value,
      address: formattedAddress,
      coordinate: latLng,
      city,
      zip,
      street,
      streetNumber: street_number,
    })
  }

  const searchOptions = {
    componentRestrictions: { country: ['hu'] },
    types: onlyCities && ['(cities)'],
  }

  return (
    <div className={classes.container}>
      <FormControl margin={margin} fullWidth={fullWidth}>
        <FormLabel>Address</FormLabel>
        <PlacesAutocompleteBase
          value={value?.address}
          onChange={handleChange}
          onSelect={handleSelect}
          searchOptions={searchOptions}
          {...props}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div>
              {
                <TextField
                  fullWidth
                  {...getInputProps()}
                  placeholder="Search..."
                  className="location-search-input"
                  id="standard-basic"
                  InputLabelProps={{ shrink: true }}
                  disabled={disabled}
                />
              }
              <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion, i) => {
                  const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item'
                  const style = suggestion.active
                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                    : { backgroundColor: '#ffffff', cursor: 'pointer' }
                  return (
                    <div
                      key={i}
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </PlacesAutocompleteBase>
        {meta.error && <FormHelperText error>{meta.error.address}</FormHelperText>}
      </FormControl>
    </div>
  )
}

export default PlacesAutocomplete
