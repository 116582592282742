import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'
import { createSagaAction } from 'saga-toolkit'

const name = 'category'

export const categoriesAdapter = createEntityAdapter()

const initialState = {
  loading: false,
  error: null,
  categories: categoriesAdapter.getInitialState(),
  category: null,
}

export const fetchCategories = createSagaAction(`${name}/fetchCategories`)
export const fetchCategory = createSagaAction(`${name}/fetchCategory`)
export const searchCategory = createSagaAction(`${name}/searchCategory`)
export const createCategory = createSagaAction(`${name}/createCategory`)
export const updateCategory = createSagaAction(`${name}/updateCategory`)
export const removeCategory = createSagaAction(`${name}/removeCategory`)

const handlePending = state => ({
  ...state,
  loading: true,
})

const handleRejected = (state, { error }) => ({
  ...state,
  error,
  loading: false,
})

const slice = createSlice({
  name,
  initialState,
  reducers: {
    clearCategory: state => ({
      ...state,
      category: null,
    }),
  },
  extraReducers: {
    // categories
    [fetchCategories.pending]: handlePending,
    [fetchCategories.fulfilled]: (state, { payload }) => {
      categoriesAdapter.setAll(state.categories, payload)
      state.loading = false
    },
    [fetchCategories.rejected]: handleRejected,

    // category
    [fetchCategory.pending]: handlePending,
    [fetchCategory.fulfilled]: (state, { payload }) => ({
      ...state,
      category: payload,
      loading: false,
    }),
    [fetchCategory.rejected]: handleRejected,

    // createCategory
    [createCategory.pending]: handlePending,
    [createCategory.fulfilled]: state => ({
      ...state,
      loading: false,
    }),
    [createCategory.rejected]: handleRejected,

    // updateCategory
    [updateCategory.pending]: handlePending,
    [updateCategory.fulfilled]: (state, { payload } ) => {
      categoriesAdapter.updateOne(state.categories, { id: payload.id, changes: payload })
      state.category = { ...state.category, ...payload }
      state.loading = false
    },
    [updateCategory.rejected]: handleRejected,

  },
})

export const { clearCategory } = slice.actions

export default slice.reducer
