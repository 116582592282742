import { Formik, Form, useFormikContext } from 'formik'
import { makeStyles } from '@material-ui/core'
import ImageList from '@material-ui/core/ImageList'
import GridListTile from '@material-ui/core/GridListTile'
import moment from 'moment'
import { TextField, SubmitButton } from 'modules/formik'
import { OpenableImage, Typography } from 'modules/ui'
import config from '../../../config'
const imagesPath = config.app.IMAGES_URL

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(2),
  },
  rejectButtonContainer: {
    textAlign: 'center',
    margin: theme.spacing(2),
  },
  attachment: {
    width: '100%',
  },
  gridList: {
    flexWrap: 'nowrap',
    transform: 'translateZ(0)',
  },
  attachmentsTitle: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

const FormBody = ({ disabled }) => {
  const { values } = useFormikContext()
  const classes = useStyles()

  const inputProps = {
    style: { color: 'black' },
    disableUnderline: true,
  }

  const inputLabelProps = { shrink: true, style: { color: 'black' } }

  return (
    <Form className={classes.root} noValidate autoComplete="off">
      <TextField
        id="myName"
        name="myName"
        label="My name"
        margin="normal"
        fullWidth
        disabled
        InputProps={inputProps}
        InputLabelProps={inputLabelProps}
      />
      <TextField
        id="partnersName"
        name="partnersName"
        label="Partner's name"
        margin="normal"
        fullWidth
        disabled
        InputProps={inputProps}
        InputLabelProps={inputLabelProps}
      />
      <TextField
        id="weddingDate"
        name="weddingDate"
        label="Wedding date"
        margin="normal"
        fullWidth
        disabled
        InputProps={inputProps}
        InputLabelProps={inputLabelProps}
      />
      <TextField
        id="weddingLocation"
        name="weddingLocation.name"
        label="Wedding location"
        margin="normal"
        fullWidth
        disabled
        InputProps={inputProps}
        InputLabelProps={inputLabelProps}
      />
      <TextField
        id="phone"
        name="phone"
        label="Phone number"
        disabled
        margin="normal"
        fullWidth
        InputProps={inputProps}
        InputLabelProps={inputLabelProps}
      />
      <TextField
        id="email"
        name="email"
        label="Email address"
        disabled
        margin="normal"
        fullWidth
        InputProps={inputProps}
        InputLabelProps={inputLabelProps}
      />
      <TextField
        id="text"
        name="text"
        label="Quote's description"
        disabled
        margin="normal"
        color="black"
        fullWidth
        multiline
        InputProps={inputProps}
        InputLabelProps={inputLabelProps}
      />
      <TextField
        id="createdAt"
        name="createdAt"
        label="Created at"
        disabled
        margin="normal"
        color="black"
        fullWidth
        InputProps={inputProps}
        InputLabelProps={inputLabelProps}
      />
      <Typography variant="caption" className={classes.attachmentsTitle}>
        Attachments
      </Typography>
      <ImageList className={classes.gridList} cols={2.5}>
        {values.attachments.map(attachment => (
          <GridListTile>
            <OpenableImage queryParams="height=240" src={`${imagesPath}/${attachment}`} />
          </GridListTile>
        ))}
      </ImageList>
      {!disabled && (
        <div className={classes.rejectButtonContainer}>
          <SubmitButton variant="contained" color="primary">
            Reject quote
          </SubmitButton>
        </div>
      )}
    </Form>
  )
}

const QuoteRequestForm = ({ onSubmit, disabled, initialValues }) => (
  <Formik onSubmit={onSubmit} initialValues={{
    ...initialValues,
    createdAt: moment(initialValues?.createdAt).format('LLL'),
  }}>
    <FormBody disabled={disabled} />
  </Formik>
)

export default QuoteRequestForm
