import { notificationsAdapter } from './slice'

export const selectRoot = state => state.notification
export const selectNotifications = state => selectRoot(state).notifications
export const selectNotification = state => selectRoot(state).notification

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
  selectById,
} = notificationsAdapter.getSelectors(selectNotifications)
