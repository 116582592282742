import { Route, useRouteMatch } from 'react-router-dom'
import Reject from './Reject'
import Accept from './Accept'
import List from './List'

const Availabilities = () => {
  const match = useRouteMatch()

  return (
    <>
      <Route
        path={[`${match.url}`, `${match.url}/:id/accept`, `${match.url}/:id/reject`]}
        exact
        component={List}
      />
      <Route path={`${match.url}/:id/accept`} exact component={Accept} />
      <Route path={`${match.url}/:id/reject`} exact component={Reject} />
    </>
  )
}

export default Availabilities
