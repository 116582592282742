import * as yup from 'yup'
import { Formik, Form } from 'formik'
import { SubmitButton, TextField, Checkbox } from 'modules/formik'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from 'modules/ui'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  checkBox: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}))

const validationSchema = yup.object({
  name: yup
    .string('Enter name')
    .min(2, 'Should be of minimum 2 characters length')
    .required('Name is required'),
  priority: yup.number('Set a number').min(0).required('Priority is required'),
})

const TagForm = ({ categories, partners, ...props }) => {
  const classes = useStyles()

  return (
    <Formik validationSchema={validationSchema} {...props}>
      <Form className={classes.root} noValidate autoComplete="off">
        <TextField id="name" name="name" label="Name" margin="normal" fullWidth />
        <TextField
          id="priority"
          name="priority"
          label="Priority"
          type="number"
          margin="normal"
          InputProps={{ inputProps: { min: 0 } }}
        />
        <Checkbox
          className={classes.checkBox}
          name="hide"
          fullWidth
          label={<Typography>Hide</Typography>}
        />
        <SubmitButton>Save</SubmitButton>
      </Form>
    </Formik>
  )
}

export default TagForm
