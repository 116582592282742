import { useField } from 'formik'
import clsx from 'clsx'
import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import { makeStyles } from '@material-ui/core/styles'
import { FormHelperText, FormLabel, FormControlLabel } from 'modules/ui'

const useStyles = makeStyles(({ spacing, palette }) => ({
  itemCheckbox: {
    marginTop: spacing(1),
  },
  root: {
    marginTop: spacing(2),
  },
}))

const CheckboxSelect = ({ name, label, options, className, ...props }) => {
  const classes = useStyles()
  const [field, meta, helper] = useField({ name })
  const value = field.value || []

  const handleChange = ({ e, item }) => {
    const { target } = e
    let _value

    if (target.checked) {
      _value = [...value, item]
    } else {
      _value = value.filter(i => i !== item)
    }

    helper.setValue(_value)
  }

  return (
    <div className={classes.root}>
      <FormLabel>{label}</FormLabel>
      {options.map((item, i) => (
        <FormControl key={i} className={clsx(classes.itemCheckbox, className)} fullWidth {...props}>
          <FormControlLabel
            label={item.label}
            control={
              <Checkbox
                checked={!!value.includes(item.value)}
                onChange={e => handleChange({ e, item: item.value })}
              />
            }
          />
          {meta.error && <FormHelperText id={`${item.id}-helper`}>{meta.error}</FormHelperText>}
        </FormControl>
      ))}
    </div>
  )
}

export default CheckboxSelect
