import { select } from '@redux-saga/core/effects'
import { takeLatestAsync } from 'saga-toolkit'
import * as actions from './slice'
import { query, mutation } from 'modules/api'
import { selectors } from '.'

function* fetchAvailabilities() {
  const { limit, offset } = yield select(selectors.selectRoot)
  const options = {
    operation: 'availabilities',
    fields: [
      'total',
      {
        availabilities: ['id', { user: ['name'] }, { partner: ['name'] }, 'date', 'createdAt'],
      },
    ],
    variables: {
      filter: {
        value: {
          limit,
          offset,
          status: 'PENDING',
        },
        type: 'AvailabilityFilter',
      },
    },
  }

  const result = yield query(options, true)

  return result.availabilities
}

function* fetchAvailability({ meta }) {
  const { id } = meta.arg
  const options = {
    operation: 'availability',
    fields: ['id', { user: ['name'] }, { partner: ['name'] }, 'date', 'status'],
    variables: {
      id: {
        type: 'ID',
        value: id,
        required: true,
      },
    },
  }

  const result = yield query(options, true)

  return result.availability
}

function* acceptAvailability({ meta }) {
  const { id } = meta.arg
  const options = {
    operation: 'acceptAvailability',
    variables: {
      id: {
        value: id,
        type: 'ID',
        required: true,
      },
    },
  }

  yield mutation(options, true)

  return id
}

function* rejectAvailability({ meta }) {
  const { id } = meta.arg
  const options = {
    operation: 'rejectAvailability',
    variables: {
      id: {
        value: id,
        type: 'ID',
        required: true,
      },
    },
  }

  yield mutation(options, true)

  return id
}

export default [
  takeLatestAsync(actions.fetchAvailabilities.type, fetchAvailabilities),
  takeLatestAsync(actions.fetchAvailability.type, fetchAvailability),
  takeLatestAsync(actions.acceptAvailability.type, acceptAvailability),
  takeLatestAsync(actions.rejectAvailability.type, rejectAvailability),
]
