import { useState } from 'react'
import { DataGrid } from '@material-ui/data-grid'

const createColumns = () => [
  {
    field: 'id',
    headerName: 'Conversation Id',
    width: 250,
  },
  {
    field: 'conversationName',
    headerName: 'Conversation name',
    width: 150,
    valueGetter: ({ row }) => row.conversation.name,
  },
  {
    field: 'participants',
    headerName: 'Participants',
    width: 450,
    valueGetter: ({ row }) => row.conversation.participants.map(({ id, name }) => `${name} (${id})`).join(', '),
  },
  {
    field: 'issues',
    headerName: 'Issues',
    width: 450,
    valueGetter: ({ row }) => row.issues.join(', '),
  },
]

export default ({ conversationIssues, ...props }) => {
  const columns = createColumns()
  const [pageSize, setPageSize] = useState(25)

  return (
    <DataGrid
      rows={conversationIssues.map(({ conversation, issues }) => ({
        id: conversation.id,
        conversation,
        issues,
      }))}
      columns={columns}
      pageSize={pageSize}
      onPageSizeChange={newPageSize => setPageSize(newPageSize)}
      rowsPerPageOptions={[25, 50, 100]}
      rowHeight={90}
      pagination
      {...props}
    />
  )
}
