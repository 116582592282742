import { useEffect, useRef } from 'react'
import useRouteQueryParams from '../../routingHook'
import { sortDirections } from 'modules/ui'

export const useIsMount = () => {
  const isMountRef = useRef(true)
  useEffect(() => {
    isMountRef.current = false
  }, [])
  return isMountRef.current
}

export const useTable = () => {
  const [setQueryParam] = useRouteQueryParams()

  function handleSort(newSortBy, sortBy, sortDirection) {
    let newSort = `${newSortBy},${sortDirections.ASC}`
    if (newSortBy === sortBy) {
      if (sortDirection === sortDirections.DESC) {
        // Already sorting by this field DESC -> disable sorting
        newSort = null
      } else {
        // Already sorting by this field ASC -> change to DESC
        newSort = `${newSortBy},${sortDirections.DESC}`
      }
    }
    setQueryParam('sort', newSort)
  }

  const handleChangePage = page => {
    setQueryParam('page', page)
  }

  const handleChangeRowsPerPage = rows => {
    setQueryParam('pageSize', rows)
  }

  const handleSearch = search => {
    setQueryParam('filter', search)
  }

  return { handleChangePage, handleChangeRowsPerPage, handleSearch, handleSort }
}
