import { useRef, useState } from 'react'
import { useHistory, useRouteMatch, useLocation } from 'react-router'
import Scrollbar from 'react-perfect-scrollbar'
import { DialogContent, Dialog, makeStyles, DialogTitle } from '@material-ui/core'
import { useInspiration, PartnerItemForm } from 'modules/inspiration'
import { useProfile } from 'modules/profile'
import { uploadToS3 } from 'modules/storage'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
  },
  dialogActions: {
    justifyContent: 'center',
  },
}))

export default function New() {
  const match = useRouteMatch()
  const { id: itemId } = match.params
  const classes = useStyles()
  const history = useHistory()
  const [open, setOpen] = useState(true)
  const partnerItemForm = useRef()
  const {
    user: { category, id },
  } = useProfile()
  const { item, updateItem } = useInspiration()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const addGalleryOpen = queryParams.get('addToGallery')

  const handleSubmit = async data => {
    const { gallery } = data
    const imagesToUpload = gallery.filter(({ image }) => image instanceof File)
    const tasks = imagesToUpload.map(async ({ image, name }) => ({
      name,
      image: await uploadToS3(image),
    }))

    const newImages = await Promise.all(tasks)

    const parsedGallery = gallery.filter(({ image }) => !(image instanceof File))

    await updateItem({
      ...data,
      partner: id,
      termsAndConditions: undefined,
      status: 'PENDING',
      coverImage:
        data.coverImage !== item.coverImage ? await uploadToS3(data.coverImage) : data.coverImage,
      gallery: [...parsedGallery, ...newImages],
    })
    handleClose()
  }

  const goBack = () => {
    history.push(`/inspiration`)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleRemoveItem = () => {
    history.push(`/inspiration/${itemId}/remove`)
  }

  if (!item) {
    return null
  }

  const initialState = { ...item, termsAndConditions: false }

  return (
    <Dialog
      open={open}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="sm"
      onClose={handleClose}
      TransitionProps={{ onExited: goBack }}
      className={classes.root}
    >
      <Scrollbar>
        <DialogTitle id="form-dialog-title">Edit inspiration</DialogTitle>
        <DialogContent>
          <PartnerItemForm
            initialValues={initialState}
            innerRef={partnerItemForm}
            category={category}
            onSubmit={handleSubmit}
            handleRemoveItem={handleRemoveItem}
            addGalleryOpen={!!addGalleryOpen}
            onClose={handleClose}
            edit
          />
        </DialogContent>
      </Scrollbar>
    </Dialog>
  )
}
