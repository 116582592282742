export const quoteRequestFields = [
  'id',
  'attachments',
  'myName',
  'partnersName',
  'weddingDate',
  { weddingLocation: ['placeId', 'name', { coordinate: ['lat', 'lng'] }] },
  'email',
  'phone',
  'text',
  'status',
  'partnerId',
  'coupleId',
  'createdAt',
]

export const quoteRequestsFields = [
  'id',
  'attachments',
  'myName',
  {
    partner: ['name'],
  },
  'partnersName',
  'weddingDate',
  { weddingLocation: ['placeId', 'name', { coordinate: ['lat', 'lng'] }] },
  'email',
  'text',
  'status',
  'createdAt',
]

export const quoteResponseFields = [
  'id',
  { attachments: ['hash', 'name'] },
  'name',
  'partnerId',
  'coupleId',
  'validUntil',
  'email',
  'phone',
  'text',
  'status',
  'createdAt',
]
