import { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { useCategory } from 'modules/category'
import { TagGroupForm, useTagGroup } from 'modules/tagGroup'
import { Page, Loader, Typography } from 'modules/ui'
import { uploadToS3 } from 'modules/storage'
import config from '../../../config'

export default () => {
  const { tagGroup, updateTagGroup } = useTagGroup()
  const { categories, loading: categoryLoading } = useCategory()
  const [submitting, setSubmitting] = useState(false)

  const handleSubmit = async ({ image, tags, ...data }) => {
    setSubmitting(true)
    const tagIdList = tags.map(item => item.id)
    await updateTagGroup({
      ...data,
      tags: tagIdList,
      image: image !== tagGroup.image ? await uploadToS3(image) : image,
    })
    setSubmitting(false)
  }

  if (!tagGroup) {
    return 'Loading...'
  }

  return (
    <Page title={`Edit "${tagGroup.name}"`}>
      <Grid container>
        <Grid item xs={12} sm={8} md={6} lg={4}>
          <Typography variant="h5">TagGroup details</Typography>
          <TagGroupForm
            categoryLoading={categoryLoading}
            initialValues={tagGroup}
            categories={categories}
            imagesPath={config.app.IMAGES_URL}
            onSubmit={handleSubmit}
          />
          <Loader loading={submitting} />
        </Grid>
      </Grid>
    </Page>
  )
}
