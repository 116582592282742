import { delay, put, takeEvery } from 'redux-saga/effects'
import { putAsync, takeEveryAsync } from 'saga-toolkit'
import { push as navigate } from 'connected-react-router'
import { actions as appActions } from 'modules/app'
import { actions as authActions } from 'modules/auth'
import { actions as chatActions } from 'modules/chat'
import { actions as notificationActions } from 'modules/notification'
import { actions as profileActions } from 'modules/profile'
import { actions as trackingActions } from 'modules/tracking'
import { initTransalation } from 'modules/i18n'
import translations from '../transalations'
import config from '../config'

function* initSession() {
  const profile = yield putAsync(profileActions.fetchProfile())
  const { id, email, type } = profile

  const data = {
    id,
    profile: {
      $email: email,
      Type: type,
    },
  }

  switch (type) {
    case 'Admin':
    case 'Partner':
      data.profile.$name = profile.name
      break
    default:
      break
  }

  yield putAsync(profileActions.registerDevice())
  yield put(trackingActions.identify(data))
  yield startNotificationService()
  yield put(chatActions.setUserId(id))

  try {
    yield putAsync(chatActions.fetchConversations())
  } catch (error) {
    console.warn('Unable to fetch chat conversations')
  }
}

function* appStart() {
  initTransalation(translations)

  if (config.enableTracking) {
    yield putAsync(
      trackingActions.init({
        analytics: config.analytics,
        sentryDsn: config.sentryDsn,
        mixpanelToken: config.mixpanelToken,
      })
    )
  }

  try {
    yield putAsync(authActions.continueSession())
    yield initSession()
    yield put(trackingActions.track('Continue user session'))
  } catch (error) {
    if (error?.name === 'NoCurrentUser') {
      return
    }
    yield putAsync(authActions.signOut())
  }

  yield put(trackingActions.track('App start'))
  yield delay(300)
}

function* signIn() {
  yield put(trackingActions.track('Sign in'))
  yield initSession()
  yield put(navigate('/'))
}

function* signOut() {
  yield put(trackingActions.track('Sign out'))
  yield putAsync(profileActions.signOut())
  yield put(notificationActions.stopService())
}

function* startNotificationService() {
  yield putAsync(notificationActions.init())
  yield put(notificationActions.startService())
}

export default [
  takeEveryAsync(appActions.start.type, appStart),
  takeEvery(authActions.signIn.fulfilled, signIn),
  takeEvery(authActions.signOut.pending, signOut),
]
