import { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import { Autocomplete, useIsMount, Typography } from 'modules/ui'
import { useUser } from '../hooks'
import config from '../../../config'

const useStyles = makeStyles(theme => ({
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: 15,
  },
}))

const UserAutocomplete = ({ filterOptions, ...props }) => {
  const classes = useStyles()
  const { searchUser } = useUser()
  const [open, setOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState([])
  const isMountRef = useIsMount()

  useEffect(() => {
    if (isMountRef) return

    if (!open) {
      setSearchTerm('')
      return
    }

    ;(async () => {
      setLoading(true)
      const { payload } = await searchUser(searchTerm)
      payload && setOptions(payload)
      setLoading(false)
    })()
  }, [open, searchTerm])

  const handleInputChange = (e, value) => {
    setSearchTerm(value)
  }

  const filteredOptions = filterOptions ? options.filter(filterOptions) : options

  return (
    <Autocomplete
      {...props}
      options={filteredOptions}
      open={open}
      loading={loading}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onInputChange={handleInputChange}
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={option => option.name}
      renderOption={option => (
        <>
          <Avatar
            alt={option?.name}
            src={config.app.IMAGES_URL + '/' + option?.profileImage?.name}
            className={classes.small}
          />
          <Typography>{option?.name + ' (' + option?.email + ')'}</Typography>
        </>
      )}
    />
  )
}

export default UserAutocomplete
