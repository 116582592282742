import { makeStyles } from '@material-ui/core/styles'
import BaseCheckbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import clsx from 'clsx'
import { useField } from 'formik'
import { FormHelperText, FormControlLabel } from 'modules/ui'

const useStyles = makeStyles(theme => ({
  root: {},
}))

const Checkbox = ({
  name,
  value,
  label,
  labelId,
  className,
  fullWidth,
  margin,
  onChange,
  onBlur,
  ...props
}) => {
  const classes = useStyles()
  const [field, meta, helpers] = useField({
    name,
    value,
    onChange,
    onBlur,
  })

  const handleChange = async (e, value) => {
    helpers.setValue(value)
  }

  return (
    <FormControl className={clsx(classes.root, className)} fullWidth={fullWidth} margin={margin}>
      <FormControlLabel
        label={label}
        control={
          <BaseCheckbox
            {...field}
            {...props}
            checked={field.value}
            onChange={handleChange}
            color="primary"
          />
        }
      />
      {meta.error && (
        <FormHelperText error id={`${labelId}-helper`}>
          {meta.error}
        </FormHelperText>
      )}
    </FormControl>
  )
}

export default Checkbox
