import { Route, useRouteMatch } from 'react-router-dom'
import ChatPage from './Chat'

const Chat = () => {
  const match = useRouteMatch()

  //TODO refactor routing
  return (
    <>
      <Route
        path={[
          `${match.url}`,
          `${match.url}/conversations/:id`,
        ]}
        exact
        component={ChatPage}
      />
    </>
  )
}

export default Chat
