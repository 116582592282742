import { useState, useEffect } from 'react'
import { Typography } from 'modules/ui'
import { Box, Grid, makeStyles } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  timeBlock: {
    height: 54,
    textAlign: 'center',
    width: '100%',
    backgroundColor: 'lightGrey',
    alignItems: 'center',
    justifyContent: 'center',
  },
  roundedLeft: {
    borderTopLeftRadius: theme.spacing(1),
    borderBottomLeftRadius: theme.spacing(1),
  },
  roundedRight: {
    borderTopRightRadius: theme.spacing(1),
    borderBottomRightRadius: theme.spacing(1),
  },
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    borderRadius: theme.spacing(2),
  },
  dateContainer: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignSelf: 'center',
  },
}))

const Countdown = ({ untilDate, precision = ['D', 'H', 'M', 'S'], ...props }) => {
  const [timeLeft, setTimeLeft] = useState()
  const classes = useStyles()

  const getTimeLeft = until => ({
    seconds: until % 60,
    minutes: parseInt(until / 60, 10) % 60,
    hours: parseInt(until / (60 * 60), 10) % 24,
    days: parseInt(until / (60 * 60 * 24), 10),
  })

  const renderTime = (until, type) => {
    const { days, hours, minutes, seconds } = getTimeLeft(until)

    return (
      (type === 'D' && days) ||
      (type === 'H' && hours) ||
      (type === 'M' && minutes) ||
      (type === 'S' && Math.trunc(seconds))
    )
  }

  const renderType = type =>
    (type === 'D' && 'Days') ||
    (type === 'H' && 'Hours') ||
    (type === 'M' && 'Minutes') ||
    (type === 'S' && 'Seconds')

  const renderSeparator = () => (
    <Box height={54} paddingBottom={4} bgcolor="lightGray" justifyContent="center">
      {[1, 2].map(element => (
        <Box
          key={element}
          height={3}
          width={3}
          bgcolor="white"
          borderRadius={2}
          marginTop={2}
          marginBottom={2}
        />
      ))}
    </Box>
  )

  const updateTimer = () => {
    const updatedTimeLeft = (new Date(untilDate) - new Date()) / 1000

    setTimeLeft(updatedTimeLeft > 0 ? updatedTimeLeft : 0)
  }

  useEffect(() => {
    updateTimer()

    const interval = setInterval(updateTimer, 1000)
    if (timeLeft === 0) {
      clearInterval(interval)
    }

    return () => clearInterval(interval)
  }, [untilDate])

  return (
    <Box className={classes.root}>
      <Grid container>
        {precision.map((type, index) => (
          <Grid key={index} xs item>
            <Box key={index} display="flex">
              <Box
                className={clsx(
                  classes.timeBlock,
                  index === 0 && classes.roundedLeft,
                  index + 1 === precision.length && classes.roundedRight
                )}
              >
                <Typography variant="h6" fontSize="lg" color="primary">
                  {renderTime(timeLeft, type) || 0}
                </Typography>
                <Typography variant="caption">{renderType(type)}</Typography>
              </Box>
              {precision.length > index + 1 && renderSeparator()}
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default Countdown
