import React from 'react'
import { Card, CardContent, makeStyles } from '@material-ui/core'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import { Typography } from 'modules/ui'

const useStyles = makeStyles(theme => ({
  card: {
    backgroundColor: theme.palette.primary.main,
  },
  text: {
    color: theme.palette.primary.contrastText,
    fontSize: '20px',
  },
  tokens: {
    color: theme.palette.primary.contrastText,
    marginTop: '10px',
  },
  icon: {
    marginRight: '5px',
  },
}))

function TokenCounter({ wallet }) {
  const classes = useStyles()

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography className={classes.text} variant="body3">
          Number of tokens in the wallet
        </Typography>
        <Typography className={classes.tokens} variant="h4">
          <MonetizationOnIcon className={classes.icon} />
          {wallet.balance}
        </Typography>
      </CardContent>
    </Card>
  )
}

export default TokenCounter
