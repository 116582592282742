import { useEffect, useState } from 'react'
import { useCategory } from '../hooks'
import { Autocomplete, useIsMount } from 'modules/ui'

const CategoryAutocomplete = props => {
  const { searchCategory } = useCategory()
  const [open, setOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState([])
  const isMountRef = useIsMount()

  const handleInputChange = (e, value) => {
    setSearchTerm(value)
  }

  useEffect(() => {
    if(isMountRef) return

    if (!open) {
      setSearchTerm('')
      return
    }

    (async () => {
      setLoading(true)
      const { payload } = await searchCategory(searchTerm)
      payload && setOptions(payload)
      setLoading(false)
    })()
  }, [open, searchTerm])

  return (
    <Autocomplete
      {...props}
      options={options}
      open={open}
      loading={loading}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onInputChange={handleInputChange}
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={option => option.name}
    />
  )
}

export default CategoryAutocomplete
