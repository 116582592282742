import { createContext, useState } from 'react'
import { useHistory } from 'react-router-dom'

const decodeInfoHash = hash => {
  try {
    const decode = Buffer.from(hash, 'base64').toString('ascii')
    return JSON.parse(decode)
  } catch (e) {
    return null
  }
}

export const SingUpContext = createContext()

// TODO: Handle contact information on backend.
export const SignUpStateProvider = ({ children }) => {
  const history = useHistory()
  const { search } = history.location
  const infoHash = new URLSearchParams(search).get('info')
  const info = decodeInfoHash(infoHash)
  const [lastPath, setLastPath] = useState()
  const [state, setState] = useState({
    name: '',
    phone: '',
    email: '',
    website: '',
    category: '',
    description: '',
    password: '',
    code: '',
    passwordConfirm: '',
    tags: [],
    class: info ? info?.class : 'C',
    verified: info ? info?.verified : false,
    address: {
      name: '',
      coordinate: {},
    },
    fixLocation: null,
    areaLocation: null,
    contactName: '',
    contactEmail: info ? info.contactEmail : '',
    contactPhone: '',
    userId: info ? info.id : '',
    ...(info?.type && {
      type: info.type,
      name: info.name,
      contactEmail: info.email,
    }),
  })

  return (
    <SingUpContext.Provider
      value={{
        state,
        setState,
        lastPath,
        setLastPath,
      }}
    >
      {children}
    </SingUpContext.Provider>
  )
}
