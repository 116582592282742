import React from 'react'
import { makeStyles, lighten } from '@material-ui/core/styles'
import {
  Table as MuiTable,
  TablePagination,
  TableSortLabel,
  Toolbar,
  Tooltip,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
} from '@material-ui/core'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import FilterListIcon from '@material-ui/icons/FilterList'
import { Typography, Button } from 'modules/ui'
import { sortDirections } from '../types'
import { useState } from 'react'

const useStyles = makeStyles({
  tableContainer: {
    minWidth: 650,
    maxWidth: 'calc(100vw - 380px)',
  },
  height: {
    maxHeight: '80vh',
  },
})

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    maxWidth: 'calc(100vw - 380px)',
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
}))

const Row = ({ columns, row, renderCell }) => {
  return (
    <TableRow>
      {columns.map(column => {
        return <TableCell>{renderCell(column, row)}</TableCell>
      })}
    </TableRow>
  )
}

const EnhancedTableToolbar = ({
  title,
  handleFilterClick,
  count,
  rowsPerPage,
  page,
  onChangePage,
  onChangeRowsPerPage,
}) => {
  const classes = useToolbarStyles()

  return (
    <Toolbar className={classes.root}>
      <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
        {title}
      </Typography>
      <div style={{ flex: 1 }} />
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
      />

      <Tooltip title="Filter list">
        <IconButton aria-label="filter list" onClick={handleFilterClick}>
          <FilterListIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  )
}

const Table = ({
  data,
  columns,
  totalElements,
  pageSize,
  sortBy,
  page,
  handleChangePage,
  sortDirection,
  handleChangeRowsPerPage,
  toggleSort,
  title,
  filter,
  handleSearch,
}) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [search, setSearch] = useState(filter || '')

  const renderCell = (column, row) => {
    if (column?.renderCell) {
      return column.renderCell(row)
    }
    if (column?.valueGetter) {
      return column.valueGetter(row)
    }

    return row[column.field]
  }

  const onSearch = () => {
    handleSearch(search)
    setOpen(false)
  }

  const onSearchClose = () => {
    setOpen(false)
    setSearch('')
    handleSearch('')
  }

  //TODO: extend dialog with more search options
  return (
    <>
      <EnhancedTableToolbar
        title={title}
        handleFilterClick={() => setOpen(prevState => !prevState)}
        count={totalElements}
        rowsPerPage={pageSize}
        page={page}
        onChangePage={(e, page) => {
          handleChangePage(page)
        }}
        onChangeRowsPerPage={e => {
          handleChangeRowsPerPage(e.target.value)
        }}
      />
      <TableContainer className={classes.tableContainer} component={Paper}>
        <MuiTable stickyHeader className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              {columns.map(({ headerName, field, sortable }) => (
                <TableCell>
                  {sortable ? (
                    <TableSortLabel
                      align="center"
                      active={sortBy === field}
                      direction={
                        sortBy === field
                          ? sortDirection.toLowerCase()
                          : sortDirections.ASC.toLowerCase()
                      }
                      onClick={() => toggleSort(field, sortBy, sortDirection)}
                    >
                      <Typography>{headerName}</Typography>
                    </TableSortLabel>
                  ) : (
                    <Typography>{headerName}</Typography>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map(row => (
              <Row row={row} columns={columns} renderCell={renderCell} />
            ))}
          </TableBody>
        </MuiTable>
      </TableContainer>

      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
        <DialogTitle>
          <Typography variant="h6">Search</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant="body">Search in names only</Typography>
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            fullWidth
            value={search}
            onChange={({ target }) => setSearch(target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onSearchClose} size="small">
            {filter ? 'Clear' : 'Cancel'}
          </Button>
          <Button onClick={onSearch} color="primary" variant="contained" size="small">
            Search
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default Table
