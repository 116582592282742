import { tagGroupsAdapter } from './slice'

export const selectRoot = state => state.tagGroup
const selectTagGroups = state => selectRoot(state).tagGroups
export const selectTagGroup = state => selectRoot(state).tagGroup

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
  selectById,
} = tagGroupsAdapter.getSelectors(selectTagGroups)
