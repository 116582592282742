import { DataGrid } from 'modules/ui'
import moment from 'moment'

const createColumns = ({ RowActions }) => [
  {
    field: 'user',
    headerName: 'User',
    width: 220,
    valueGetter: ({ row }) => row.user.name,
  },
  {
    field: 'createdAt',
    headerName: 'Created at',
    width: 220,
    valueGetter: ({ row }) => moment(row.createdAt).format('LLL'),
  },
  {
    field: 'date',
    headerName: 'Date',
    width: 220,
    valueGetter: ({ row }) => moment(row.date).format('LL'),
  },
  {
    field: '',
    headerName: 'Actions',
    width: 140,
    sortable: false,
    disableClickEventBubbling: true,
    renderCell: RowActions,
  },
]

const AvailabilitiesList = ({
  availabilities,
  total,
  setOffset,
  limit,
  offset,
  fetchAvailabilities,
  rowActionsComponent: RowActions,
  ...props
}) => {
  const columns = createColumns({ RowActions })

  const onPageChange = newPageNumber => {
    const newOffset = limit * newPageNumber
    setOffset(newOffset)
    fetchAvailabilities()
  }

  return (
    <DataGrid
      rows={availabilities}
      columns={columns}
      pageSize={limit}
      paginationMode="server"
      rowCount={total}
      rowsPerPageOptions={[]}
      onPageChange={onPageChange}
      rowHeight={90}
      pagination
      {...props}
    />
  )
}

export default AvailabilitiesList
