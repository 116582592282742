import { useState } from 'react'
import { orderBy } from 'lodash'
import moment from 'moment'
import { makeStyles } from '@material-ui/core'
import { DataGrid } from 'modules/ui'

const createColumns = () => [
  {
    field: 'createdAt',
    headerName: 'Date',
    width: 220,
    valueGetter: ({ row }) => moment(row.createdAt).format('LLL'),
  },
  {
    field: 'type',
    headerName: 'Type',
    width: 120,
    renderCell: ({ row }) => (row.amount > 0 ? 'Hozzáad' : 'Levon'),
  },
  {
    field: 'amount',
    headerName: 'Quantity',
    width: 150,
    renderCell: ({ row }) => Math.abs(row.amount),
  },
  {
    field: 'note',
    headerName: 'Note',
    width: 170,
  },
]

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '500px',
    maxHeight: '100%',
    width: '100%',
  },
}))

const TransactionList = ({ wallet, ...props }) => {
  const classes = useStyles()

  const columns = createColumns()
  const [pageSize, setPageSize] = useState(25)

  return (
    <DataGrid
      rows={wallet ? orderBy(wallet?.transactions, ['createdAt'], ['desc']) : []}
      columns={columns}
      pageSize={pageSize}
      onPageSizeChange={newPageSize => setPageSize(newPageSize)}
      rowsPerPageOptions={[25, 50, 100]}
      pagination
      className={classes.root}
      {...props}
    />
  )
}

export default TransactionList
