import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'
import { createSagaAction } from 'saga-toolkit'

const name = 'chat'

export const conversationsAdapter = createEntityAdapter()

const initialState = {
  loading: false,
  error: null,
  userId: null,
  conversations: conversationsAdapter.getInitialState(),
  totalConversations: 0,
  limit: 15,
}

export const fetchConversations = createSagaAction(`${name}/fetchConversations`)
export const fetchConversation = createSagaAction(`${name}/fetchConversation`)
export const fetchConversationMessages = createSagaAction(`${name}/fetchConversationMessages`)
export const newConversation = createSagaAction(`${name}/newConversation`)
export const sendMessage = createSagaAction(`${name}/sendMessage`)
export const seeConversation = createSagaAction(`${name}/seeConversation`)
export const waitForConversation = createSagaAction(`${name}/waitForConversation`)
export const searchConversation = createSagaAction(`${name}/searchConversation`)

const getConversationById = (conversations, id) =>
  conversationsAdapter.getSelectors().selectById(conversations, id)

const updateConversation = (conversations, id, changes) =>
  conversationsAdapter.updateOne(conversations, { id, changes })

const updateConversationMessage = (messages, requestId, changes) =>
  messages.map(message => ({
    ...message,
    ...(message.requestId === requestId && changes),
  }))

const handlePending = state => ({
  ...state,
  loading: true,
})

const handleRejected = (state, { error }) => ({
  ...state,
  error,
  loading: false,
})

const slice = createSlice({
  name,
  initialState,
  reducers: {
    clearChat: () => initialState,
    setUserId: (state, { payload }) => {
      state.userId = payload
    },
    receiveMessage: ({ conversations, userId }, { payload }) => {
      const conversation = getConversationById(conversations, payload.conversation)

      if (!conversation) {
        console.warn('Message received to a non-existing conversation:', payload.conversation)
        return
      }

      if (payload.sender === userId) {
        // the message was sent by us - do nothing
        return
      }

      const message = {
        ...payload,
        sender: conversation.participants.find(({ id }) => id === payload.sender),
      }

      updateConversation(conversations, conversation.id, {
        messages: [message, ...conversation.messages],
        totalMessages: conversation.totalMessages + 1,
      })
    },
  },
  extraReducers: {
    // seeConversation
    [seeConversation.pending]: handlePending,
    [seeConversation.fulfilled]: (state, { payload }) => {
      state.loading = false

      if (!payload) {
        return
      }

      updateConversation(state.conversations, payload.id, payload)
    },
    [seeConversation.rejected]: handleRejected,

    // searchConversation
    [searchConversation.pending]: handlePending,
    [searchConversation.fulfilled]: (state, { payload }) => {
      conversationsAdapter.setAll(state.conversations, payload.elements)
      state.totalConversations = payload.total
      state.loading = false
    },
    [searchConversation.rejected]: handleRejected,

    // fetchConversations
    [fetchConversations.pending]: handlePending,
    [fetchConversations.fulfilled]: (state, { payload }) => {
      conversationsAdapter.addMany(state.conversations, payload.elements)
      state.totalConversations = payload.total
      state.loading = false
    },
    [fetchConversations.rejected]: handleRejected,

    // fetchConversation
    [fetchConversation.pending]: handlePending,
    [fetchConversation.fulfilled]: (state, { payload }) => {
      const { conversations } = state

      conversationsAdapter.upsertOne(conversations, payload)

      state.loading = false
    },
    [fetchConversation.rejected]: handleRejected,

    // fetchConversationMessages
    [fetchConversationMessages.pending]: state => ({
      ...state,
      loading: true,
    }),

    [fetchConversationMessages.fulfilled]: (state, { meta, payload }) => {
      const { conversations } = state
      const { id } = meta.arg.conversation
      const conversation = getConversationById(conversations, id)

      updateConversation(conversations, id, {
        messages: [...conversation.messages, ...payload.messages.elements],
      })

      state.loading = false
    },

    [fetchConversationMessages.rejected]: (state, { error }) => ({
      ...state,
      error,
      loading: false,
    }),

    // sendMessage
    [sendMessage.pending]: (state, { meta }) => {
      const { conversations } = state
      const { id } = meta.arg.conversation
      const { requestId } = meta
      const message = {
        ...meta.arg,
        pending: true,
        requestId,
      }
      const conversation = getConversationById(conversations, id)

      updateConversation(conversations, id, {
        messages: [message, ...conversation.messages],
      })
    },
    [sendMessage.fulfilled]: (state, { meta, payload }) => {
      const { conversations } = state
      const { id } = meta.arg.conversation
      const { requestId } = meta
      const conversation = getConversationById(conversations, id)

      updateConversation(conversations, id, {
        messages: updateConversationMessage(conversation.messages, requestId, {
          ...payload,
          pending: false,
        }),
        totalMessages: conversation.totalMessages + 1,
      })
    },
    [sendMessage.rejected]: (state, { meta, error }) => {
      const { conversations } = state
      const { id } = meta.arg.conversation
      const { requestId } = meta
      const conversation = getConversationById(conversations, id)

      updateConversation(conversations, id, {
        messages: updateConversationMessage(conversation.messages, requestId, { error }),
      })
    },

    // newConversation
    [newConversation.pending]: handlePending,
    [newConversation.fulfilled]: (state, { payload }) => {
      conversationsAdapter.addOne(state.conversations, payload)
      state.loading = false
    },
    [newConversation.rejected]: handleRejected,
  },
})

export const { receiveMessage, setUserId, clearChat } = slice.actions

export default slice.reducer
