const extraFields = [
  { label: 'Guest Range', field: 'guestRange' },
  { label: 'Services', field: 'services' },
  { label: 'Fix location', field: 'fixLocation'},
  { label: 'Area location', field: 'areaLocation'},
  { label: 'Availability', field: 'availability'},
  { label: 'Hidden chat', field: 'hiddenChat'},
  { label: 'Standing capacity', field: 'standingCapacity'},
  { label: 'Seating capacity', field: 'seatingCapacity'},
  { label: 'Available weekdays', field: 'availableWeekdays'},
  { label: 'Hidden quote request', field: 'hiddenQuoteRequest'},
]

export default extraFields
