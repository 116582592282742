import { makeStyles } from '@material-ui/core/styles'
import { Form, Formik } from 'formik'
import * as yup from 'yup'
import { FileUploaderField } from 'modules/formik'
import config from '../../../config'


const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}))

const validationSchema = yup.object({
  image: yup.mixed().required('Image is required'),
})

const UploadImageForm = ({
  initialValues = {
    image: '',
  },
  ...props
}) => {
  const classes = useStyles()

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      {...props}
    >
      <Form className={classes.root} noValidate autoComplete='off'>
        <FileUploaderField
          label='Image'
          id='image'
          name='image'
          fullWidth
          singleImage
          withIcon={false}
          withPreview
          buttonText='Choose image'
          imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
          maxFileSize={config.app.MAX_ATTACHMENT_SIZE}
        />
      </Form>
    </Formik>
  )
}

export default UploadImageForm
