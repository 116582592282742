import { pick } from 'rambda'
import { takeLatestAsync, takeAggregateAsync } from 'saga-toolkit'
import * as actions from './slice'
import { query, mutation } from 'modules/api'
import { extraFields } from 'modules/category'

const parseCategory = category => ({
  ...category,
  image: category.image.name,
  extraFields: category.extraFields.map(profileField =>
    extraFields.find(field => profileField === field.field)
  ),
})

function* fetchCategories() {
  const options = {
    operation: 'categories',
    fields: [
      'id',
      'name',
      { image: ['name'] },
      { tags: ['name'] },
      'ordering',
      'extraFields',
      'hide',
    ],
  }

  const result = yield query(options, false)
  const parsedCategories = result.categories?.map(parseCategory)

  return parsedCategories
}

function* fetchCategory({ meta }) {
  const { id } = meta.arg
  const options = {
    operation: 'category',
    fields: [
      'id',
      'name',
      { image: ['name'] },
      { tags: ['id', 'name'] },
      'ordering',
      'extraFields',
      'hide',
    ],
    variables: {
      id: {
        type: 'ID',
        value: id,
        required: true,
      },
    },
  }

  const { category } = yield query(options, false)

  return parseCategory(category)
}

function* searchCategory({ meta }) {
  const options = {
    operation: 'categories',
    fields: [
      'id',
      'name',
      { image: ['name'] },
      { tags: ['id', 'name'] },
      'ordering',
      'extraFields',
      'hide',
    ],
    variables: {
      searchByName: {
        type: 'String',
        value: meta.arg,
      },
    },
  }

  const result = yield query(options, true)

  return result.categories
}

function* createCategory({ meta }) {
  const options = {
    operation: 'insertCategory',
    fields: ['id'],
    variables: {
      input: {
        value: meta.arg,
        type: 'NewCategoryInput!',
      },
    },
  }

  const result = yield mutation(options, true)

  return result.insertCategory
}

function* updateCategory({ meta }) {
  const body = pick(['name', 'image', 'ordering', 'tags', 'extraFields', 'hide', 'id'], meta.arg)
  const options = {
    operation: 'updateCategory',
    fields: [
      'id',
      'name',
      { image: ['name'] },
      { tags: ['id', 'name'] },
      'ordering',
      'extraFields',
      'hide',
    ],
    variables: {
      input: {
        value: body,
        type: 'UpdateCategoryInput!',
      },
    },
  }

  const { updateCategory } = yield mutation(options, true)

  return parseCategory(updateCategory)
}

function* removeCategory({ meta }) {
  const { id } = meta.arg
  const options = {
    operation: 'deleteCategory',
    variables: {
      id: {
        value: id,
        type: 'ID!',
      },
    },
  }

  const result = yield mutation(options, true)

  return result
}

export default [
  takeLatestAsync(actions.fetchCategories.type, fetchCategories),
  takeAggregateAsync(actions.fetchCategory.type, fetchCategory),
  takeLatestAsync(actions.searchCategory.type, searchCategory),
  takeLatestAsync(actions.createCategory.type, createCategory),
  takeLatestAsync(actions.updateCategory.type, updateCategory),
  takeLatestAsync(actions.removeCategory.type, removeCategory),
]
