import { useRouteMatch } from 'react-router'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import { Box } from '@material-ui/core'
import { CategoriesList, useCategory } from 'modules/category'
import { Page, NavButton } from 'modules/ui'
import config from '../../../config'

const RowActions = ({ row }) => {
  const match = useRouteMatch()

  return (
    <>
      <NavButton to={`${match.url}/${row.id}/edit`} tooltip="Edit">
        <EditIcon fontSize="small" />
      </NavButton>
      <NavButton to={`${match.url}/${row.id}/remove`} tooltip="Delete">
        <DeleteIcon fontSize="small" />
      </NavButton>
    </>
  )
}

const List = () => {
  const { categories, loading } = useCategory()
  const match = useRouteMatch()
  const imagesPath = config.app.IMAGES_URL

  return (
    <Page title="Categories" fullHeight>
      <CategoriesList
        imagesPath={imagesPath}
        categories={categories}
        loading={loading}
        rowActionsComponent={RowActions}
      />
      <Box display="flex" justifyContent="flex-end" p={1}>
        <NavButton to={`${match.url}/new`} tooltip="New">
          <AddIcon />
        </NavButton>
      </Box>
    </Page>
  )
}

export default List
