import UIFormLabel from '@material-ui/core/FormLabel'
import { useT } from 'modules/i18n'

const isString = value => {
  return typeof value === 'string'
}

export const FormLabel = ({ children, ...props }) => {
  const t = useT()

  return <UIFormLabel {...props}>{isString(children) ? t(children) : children}</UIFormLabel>
}

export default FormLabel
