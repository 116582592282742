import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'
import { createSagaAction } from 'saga-toolkit'

const name = 'highlight'

export const highlightsAdapter = createEntityAdapter()

const initialState = {
  loading: false,
  error: null,
  highlights: highlightsAdapter.getInitialState(),
  highlight: null,
}

export const fetchHighlights = createSagaAction(`${name}/fetchHighlights`)
export const fetchHighlight = createSagaAction(`${name}/fetchHighlight`)
export const createHighlight = createSagaAction(`${name}/createHighlight`)
export const updateHighlight = createSagaAction(`${name}/updateHighlight`)
export const removeHighlight = createSagaAction(`${name}/removeHighlight`)
export const moveHighlight = createSagaAction(`${name}/moveHighlight`)

const handlePending = state => ({
  ...state,
  loading: true,
})

const handleRejected = (state, { error }) => ({
  ...state,
  error,
  loading: false,
})

const slice = createSlice({
  name,
  initialState,
  reducers: {
    clearHighlight: state => ({
      ...state,
      highlight: null,
    }),
  },
  extraReducers: {
    // fetchHighlights
    [fetchHighlights.pending]: handlePending,
    [fetchHighlights.fulfilled]: (state, { payload }) => {
      highlightsAdapter.setAll(state.highlights, payload)
      state.loading = false
    },
    [fetchHighlights.rejected]: handleRejected,

    // fetchHighlight
    [fetchHighlight.pending]: handlePending,
    [fetchHighlight.fulfilled]: (state, { payload }) => ({
      ...state,
      highlight: payload,
      loading: false,
    }),
    [fetchHighlight.rejected]: handleRejected,

    // createHighlight
    [createHighlight.pending]: handlePending,
    [createHighlight.fulfilled]: state => ({
      ...state,
      loading: false,
    }),
    [createHighlight.rejected]: handleRejected,

    // updateHighlight
    [updateHighlight.pending]: handlePending,
    [updateHighlight.fulfilled]: (state, { payload }) => {
      highlightsAdapter.updateOne(state.highlights, { id: payload.id, changes: payload })
      state.highlight = { ...state.highlight, ...payload }
      state.loading = false
    },
    [updateHighlight.rejected]: handleRejected,

    // moveHighlight
    [moveHighlight.pending]: handlePending,
    [moveHighlight.fulfilled]: (state, { payload }) => {
      highlightsAdapter.setAll(state.highlights, payload)
      state.loading = false
    },
    [moveHighlight.rejected]: handleRejected,

    //removeHighlight
    [removeHighlight.pending]: handlePending,
    [removeHighlight.fulfilled]: (state) => {
      state.loading = false
    },
    [removeHighlight.rejected]: handleRejected,
  },
})

export const { clearHighlight } = slice.actions

export default slice.reducer
