import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { unwrapResult } from '@reduxjs/toolkit'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Loader, Button } from 'modules/ui'
import { useAdmin } from 'modules/admin'

const FixChatConversationIssues = () => {
  const history = useHistory()
  const [open, setOpen] = useState(false)
  const { fixConversationIssues } = useAdmin()
  const [loading, setLoading] = useState(false)
  const [fixedIssues, setFixedIssues] = useState()

  const goBack = () => {
    history.goBack()
  }

  const close = () => {
    setOpen(false)
  }

  const handleConfirm = async () => {
    setLoading(true)

    try {
      const fixedIssues = unwrapResult(await fixConversationIssues())
      setFixedIssues(fixedIssues)
    } catch (error) {
      console.error(error)
    }

    setLoading(false)
  }

  useEffect(() => {
    setOpen(true)
  }, [])

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={close} TransitionProps={{ onExited: goBack }}>
      <DialogTitle id="form-dialog-title">Fix chat conversation issues?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {fixedIssues
            ? 'The followings have been fixed: ' +
              fixedIssues.map(({ conversation }) => conversation.id).join(', ')
            : "I hope you know what you're doing."}
        </DialogContentText>
        <Loader loading={loading} />
      </DialogContent>
      <DialogActions>
        <Button size="small" onClick={close}>
          Close
        </Button>
        {!fixedIssues && (
          <Button
            color="primary"
            variant="contained"
            disabled={loading}
            size="small"
            onClick={handleConfirm}
          >
            Fix
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default FixChatConversationIssues
