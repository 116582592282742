import { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { unwrapResult } from '@reduxjs/toolkit'
import { useSignUp, SignUpStepper } from 'modules/auth'
import VerificationForm from 'modules/auth/components/SignUp/VerificationForm'
import { SingUpContext } from 'modules/auth'

const Verification = () => {
  const { confirmSignUp, error } = useSignUp()
  const history = useHistory()
  const { state, setState } = useContext(SingUpContext)
  const { email } = state

  const submitVerificationCode = async values => {
    try {
      unwrapResult(await confirmSignUp({ ...values, code: values?.code.toString() }))
      setState({ ...state, ...values })
      history.push('/sign-up/sign-in')
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <SignUpStepper>
      <VerificationForm submit={submitVerificationCode} email={email} error={error} />
    </SignUpStepper>
  )
}

export default Verification
