import { takeLatestAsync } from 'saga-toolkit'
import * as actions from './slice'
import { put, select } from 'redux-saga/effects'
import { query, mutation } from 'modules/api'
import { selectPartner } from './selectors'
import { createUpdateUserInput, partnerProfileFields } from '../profile/fields'

const partnerFields = [
  'id',
  'name',
  'email',
  'phone',
  'website',
  'description',
  { coverImages: ['name'] },
  { category: ['id', 'name'] },
  { tags: ['id', 'name'] },
  {
    fixLocation: [
      'address',
      'city',
      'zip',
      'street',
      'streetNumber',
      { coordinate: ['lat', 'lng'] },
    ],
  },
  { areaLocation: ['address', 'radius', 'city', 'availableNationwide'] },
  { guestRange: ['low', 'high'] },
  { profileImage: ['name'] },
  'registered',
  'contactName',
  'contactEmail',
  'contactPhone',
  'verified',
  'class',
  'standingCapacity',
  'seatingCapacity',
  'availableWeekdays',
]

const partnerFieldsWithWallet = [
  ...partnerFields,
  {
    wallet: [
      'id',
      'balance',
      { transactions: ['id', 'from', 'to', 'amount', 'note', 'createdAt'] },
    ],
  },
]

const parsePartner = partner => ({
  ...partner,
  profileImage: partner.profileImage?.name,
  coverImages: partner.coverImages?.map(({ name }) => name),
})

function* fetchPartners({ meta }) {
  const { offset, limit, filter, sortBy, sortDirection } = meta.arg

  const options = {
    operation: 'partners',
    fields: [
      'total',
      {
        elements: [
          'id',
          'name',
          { profileImage: ['name'] },
          { category: ['name', 'id'] },
          { tags: ['name', 'id'] },
          { items: ['name', 'id'] },
          'phone',
          'email',
          'registered',
          'verified',
          'website',
          'contactPhone',
          // { quotes: quoteRequestFields },
          { wallet: ['balance'] },
          'updatedAt',
        ],
      },
    ],
    variables: {
      input: {
        value: {
          limit,
          offset,
          filter,
          sortBy,
          sortDirection,
        },
        type: 'PartnerFilter!',
      },
    },
  }

  const { partners } = yield query(options, true)

  return partners
}

function* fetchPartner({ meta }) {
  const { id } = meta.arg
  const options = {
    operation: 'partner',
    fields: partnerFieldsWithWallet,
    variables: {
      id: {
        type: 'ID',
        value: id,
        required: true,
      },
    },
  }

  const { partner } = yield query(options, true)

  return parsePartner(partner)
}

function* searchPartner({ meta }) {
  const options = {
    operation: 'partners',
    fields: [{ elements: ['id', 'name'] }],
    variables: {
      input: {
        type: 'PartnerFilter!',
        value: { filter: meta.arg, limit: 20, offset: 0 },
      },
    },
  }

  const { partners } = yield query(options, true)

  return partners.elements
}

function* createPartner({ meta }) {
  const input = createUpdateUserInput(meta.arg)
  const options = {
    operation: 'insertUser',
    fields: [{ '...on Partner': ['id'] }],
    variables: {
      input: {
        value: input,
        type: 'NewUserInput!',
      },
    },
  }

  const { newPartner } = yield mutation(options, true)

  return newPartner
}

function* updatePartner({ meta }) {
  const input = createUpdateUserInput(meta.arg)

  const options = {
    operation: 'updateUser',
    fields: [{ '...on Partner': partnerProfileFields }],
    variables: {
      input: {
        value: input,
        type: 'UpdateUserInput!',
      },
    },
  }

  const { updateUser } = yield mutation(options, true)

  return parsePartner(updateUser)
}

function* removePartner({ meta }) {
  const { id } = meta.arg
  const options = {
    operation: 'deletePartner',
    variables: {
      id: {
        value: id,
        type: 'ID',
      },
    },
  }

  const result = yield mutation(options, true)

  return result
}

function* invitePartner({ meta }) {
  const { id } = meta.arg
  const options = {
    operation: 'invitePartner',
    variables: {
      id: {
        value: id,
        type: 'ID',
        required: true,
      },
    },
  }

  const { invitePartner } = yield mutation(options, true)

  return invitePartner
}

function* addTokenToPartner({ meta }) {
  const transactionInput = meta.arg

  const options = {
    operation: 'addTokenToPartner',
    fields: ['id'],
    variables: {
      input: {
        value: transactionInput,
        type: 'AdminTransactionInput',
      },
    },
  }

  yield mutation(options, true)
  const partner = yield select(selectPartner)
  yield put(actions.fetchPartner({ id: partner.id }))
}

export default [
  takeLatestAsync(actions.fetchPartners.type, fetchPartners),
  takeLatestAsync(actions.fetchPartner.type, fetchPartner),
  takeLatestAsync(actions.searchPartner.type, searchPartner),
  takeLatestAsync(actions.createPartner.type, createPartner),
  takeLatestAsync(actions.updatePartner.type, updatePartner),
  takeLatestAsync(actions.removePartner.type, removePartner),
  takeLatestAsync(actions.invitePartner.type, invitePartner),
  takeLatestAsync(actions.addTokenToPartner.type, addTokenToPartner),
]
