import { useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import Grid from '@material-ui/core/Grid'
import { unwrapResult } from '@reduxjs/toolkit'
import { NotificationForm, useNotification } from 'modules/notification'
import { Page, Loader } from 'modules/ui'
import config from '../../../config'

export default () => {
  const { sendNotification } = useNotification()
  const history = useHistory()
  const { state } = useLocation()
  const [submitting, setSubmitting] = useState(false)

  const handleSubmit = async data => {
    setSubmitting(true)

    try {
      const { title, body, target } = data
      const { id } = unwrapResult(await sendNotification({
        title,
        body,
        target,
        targetUserId: data.targetUser?.id,
      }))
      history.push(`/notifications/${id}/view`)
    } catch (error) {
      setSubmitting(false)
    }
  }

  return (
    <Page title='New notification'>
      <Grid container justifyContent='center'>
        <Grid item xs={12} sm={6} md={4}>
          <NotificationForm
            initialValues={state}
            imagesPath={config.app.IMAGES_URL}
            onSubmit={handleSubmit}
          />
          <Loader loading={submitting} />
        </Grid>
      </Grid>
    </Page>
  )
}
