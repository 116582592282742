import moment from 'moment'
import { DataGrid } from 'modules/ui'

const statusMap = {
  ACCEPTED: 'Accepted',
  PENDING: 'Pending',
  REJECTED: 'Rejected',
}

const createColumns = ({ RowActions, hiddenPartner }) => [
  {
    field: 'name',
    headerName: 'Couple',
    valueGetter: ({ row }) => `${row.myName} & ${row.partnersName}`,
    width: 220,
  },
  ...(!hiddenPartner
    ? [
        {
          field: 'partner',
          headerName: 'Partner',
          valueGetter: ({ row }) => `${row.partner?.name}`,
          width: 220,
        },
      ]
    : []),
  {
    field: 'weddingLocation',
    headerName: 'Wedding location',
    valueGetter: ({ row }) => row.weddingLocation?.name,
    width: 220,
  },
  {
    field: 'weddingDate',
    headerName: 'Wedding date',
    width: 200,
    valueGetter: ({ row }) => moment(row.weddingDate).format('LL'),
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 140,
    valueGetter: ({ row }) => statusMap[row.status],
  },
  {
    field: 'createdAt',
    headerName: 'Created at',
    width: 220,
    valueGetter: ({ row }) => moment(row.createdAt).format('LLL'),
  },
  {
    field: '',
    headerName: 'Actions',
    width: 140,
    sortable: false,
    disableClickEventBubbling: true,
    renderCell: RowActions,
  },
]

const QuoteRequestsList = ({
  quoteRequests,
  rowActionsComponent: RowActions,
  total,
  setOffset,
  limit,
  offset,
  fetchQuoteRequests,
  hiddenPartner,
  imagesPath,
  ...props
}) => {
  const columns = createColumns({ RowActions, hiddenPartner })
  const onPageChange = newPageNumber => {
    const newOffset = limit * newPageNumber
    setOffset(newOffset)
    fetchQuoteRequests()
  }

  return (
    <DataGrid
      rows={quoteRequests}
      columns={columns}
      pagination
      pageSize={limit}
      paginationMode="server"
      rowCount={total}
      rowsPerPageOptions={[]}
      rowHeight={90}
      onPageChange={onPageChange}
      {...props}
    />
  )
}

export default QuoteRequestsList
