import UITooltip from '@material-ui/core/Tooltip'
import { useT } from 'modules/i18n'

const isString = value => {
  return typeof value === 'string'
}

export const Tooltip = ({ title, ...props }) => {
  const t = useT()

  return (
    <UITooltip
      title={isString(title) ? t(title) : title}
      {...props}
    />
  )
}

export default Tooltip
