import { useHistory, useRouteMatch } from 'react-router'
import { InspirationItemList, useInspiration } from 'modules/inspiration'
import { Page } from 'modules/ui'
import { useProfile } from 'modules/profile'

const List = () => {
  const match = useRouteMatch()
  const history = useHistory()
  const { items, updateItem, loading } = useInspiration()
  const {
    user: { id },
  } = useProfile()

  const handleClickItem = ({ id }) => {
    history.push(`${match.url}/${id}/edit`)
  }

  // TODO: implement logic
  const handleChangeOrder = order => {}

  const handleUpdateActiveStatus = async input => {
    if (!loading) {
      await updateItem({ ...input, partner: id })
    }
  }

  const handleAddNewItem = () => {
    history.push(`${match.url}/new`)
  }

  return (
    <Page>
      <InspirationItemList
        handleAddNewItem={handleAddNewItem}
        handleChangeOrder={handleChangeOrder}
        items={items}
        handleUpdateActiveStatus={handleUpdateActiveStatus}
        handleClickItem={handleClickItem}
        loading={loading}
      />
    </Page>
  )
}

export default List
