import { useRouteMatch } from 'react-router'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import NotificationsIcon from '@material-ui/icons/Notifications'
import { Box } from '@material-ui/core'
import { NavButton } from 'modules/ui'
import { UsersList, useUser } from 'modules/user'
import { Page } from 'modules/ui'

const RowActions = ({ row }) => {
  const match = useRouteMatch()

  return (
    <>
      <NavButton to={`${match.url}/${row.id}/edit`} tooltip='Edit'><EditIcon fontSize='small' /></NavButton>
      <NavButton to={`${match.url}/${row.id}/delete`} tooltip='Delete'><DeleteIcon fontSize='small' /></NavButton>
      <NavButton
        to={
          {
            pathname: 'notifications/send',
            state: {
              target: 'single-user',
              targetUser: { name: row.name, id: row.id },
            }
          }}
        tooltip="Send Notification"
      >
        <NotificationsIcon fontSize="small" />
      </NavButton>
    </>
  )
}

export default () => {
  const { users, loading } = useUser()
  const match = useRouteMatch()

  return (
    <Page title='Users' fullHeight>
      <UsersList users={users} loading={loading} rowActionsComponent={RowActions} />
      <Box display='flex' justifyContent='flex-end' p={1}>
        <NavButton to={`${match.url}/new`} tooltip='New'><AddIcon /></NavButton>
      </Box>
    </Page>
  )
}
