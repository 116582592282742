import { useField } from 'formik'

export default Field => ({ name, value, onChange, onBlur, ...props }) => {
  const [field, meta, helpers] = useField({ name, value, onChange, onBlur })

  const handleChange = ({ target }) => {
    helpers.setTouched(true)
    helpers.setValue(target.value)
  }

  return (
    <Field
      {...field}
      {...props}
      onChange={handleChange}
      error={meta.touched && !!meta.error}
      helperText={meta.error || ''}
    />
  )
}
