import { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Loader, Button } from 'modules/ui'
import { useItem } from 'modules/item'

export default () => {
  const { id } = useParams()
  const history = useHistory()
  const [open, setOpen] = useState(false)
  const { item, removeItem } = useItem()
  const [loading, setLoading] = useState(false)

  const goBack = () => {
    history.goBack()
  }

  const handleCancel = () => {
    setOpen(false)
  }

  const handleRemove = async () => {
    setLoading(true)
    await removeItem({ id })
    setLoading(false)
    handleCancel()
  }

  useEffect(() => {
    setOpen(true)
  }, [])

  if (!item) {
    return null
  }

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={handleCancel} TransitionProps={{ onExited: goBack }}>
      <DialogTitle id="form-dialog-title">Remove {item?.name}?</DialogTitle>
      <DialogContent>
        <DialogContentText>Some instruction text can come here.</DialogContentText>
        <Loader loading={loading} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleRemove} color="primary" disabled={loading}>
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  )
}
