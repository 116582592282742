import { DataGrid } from '@material-ui/data-grid'
import { ImageCell } from 'modules/ui'
import moment from 'moment'
import { Link } from 'react-router-dom'

const createColumns = ({ RowActions, imagesPath }) => [
  {
    field: 'coverImage',
    headerName: 'Cover',
    width: 150,
    renderCell: ({ row }) => <ImageCell src={`${imagesPath}/${row.coverImage}`} alt="cover" />,
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 220,
    renderCell: ({ row }) => <Link to={`/items/${row.id}/edit`}>{row.name}</Link>,
  },
  {
    field: 'partnerName',
    headerName: 'Partner',
    valueGetter: ({ row }) => row.partner?.name,
    width: 220,
  },
  {
    field: 'likes',
    headerName: 'Likes',
    valueGetter: ({ row }) => (row.likes != null ? row.likes : 0),
    width: 140,
  },
  {
    field: 'views',
    headerName: 'Views',
    valueGetter: ({ row }) => (row.views != null ? row.views : 0),
    width: 140,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 140,
  },
  {
    field: 'active',
    headerName: 'Active',
    valueGetter: ({ row }) => (row.active ? 'Yes' : 'No'),
    width: 140,
  },
  {
    field: 'createdAt',
    headerName: 'Created at',
    width: 200,
    valueGetter: ({ row }) => moment(row.createdAt).format('LLL'),
  },
  {
    field: '',
    headerName: 'Actions',
    width: 140,
    sortable: false,
    disableClickEventBubbling: true,
    renderCell: RowActions,
  },
]

const ItemsList = ({
  items,
  rowActionsComponent: RowActions,
  total,
  setOffset,
  limit,
  offset,
  fetchItems,
  imagesPath,
  ...props
}) => {
  const columns = createColumns({ RowActions, imagesPath })
  const onPageChange = newPageNumber => {
    const newOffset = limit * newPageNumber
    setOffset(newOffset)
    fetchItems()
  }

  return (
    <DataGrid
      rows={items}
      columns={columns}
      pagination
      pageSize={limit}
      paginationMode="server"
      rowCount={total}
      rowsPerPageOptions={[]}
      rowHeight={90}
      onPageChange={onPageChange}
      {...props}
    />
  )
}

export default ItemsList
