import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'
import { createSagaAction } from 'saga-toolkit'

const name = 'blog'

export const blogsAdapter = createEntityAdapter()

const initialState = {
  loading: false,
  error: null,
  blogs: blogsAdapter.getInitialState(),
  blog: null,
}

export const fetchBlogs = createSagaAction(`${name}/fetchBlogs`)
export const fetchBlog = createSagaAction(`${name}/fetchBlog`)
export const createBlog = createSagaAction(`${name}/createBlog`)
export const updateBlog = createSagaAction(`${name}/updateBlog`)
export const searchBlog = createSagaAction(`${name}/searchBlog`)
export const removeBlog = createSagaAction(`${name}/removeBlog`)

const handlePending = state => ({
  ...state,
  loading: true,
})

const handleRejected = (state, { error }) => ({
  ...state,
  error,
  loading: false,
})

const slice = createSlice({
  name,
  initialState,
  reducers: {
    clearBlog: state => ({
      ...state,
      blog: null,
    }),
  },
  extraReducers: {
    // blogs
    [fetchBlogs.pending]: handlePending,
    [fetchBlogs.fulfilled]: (state, { payload }) => {
      blogsAdapter.setAll(state.blogs, payload)
      state.loading = false
    },
    [fetchBlogs.rejected]: handleRejected,

    // blog
    [fetchBlog.pending]: handlePending,
    [fetchBlog.fulfilled]: (state, { payload }) => ({
      ...state,
      blog: payload,
      loading: false,
    }),
    [fetchBlog.rejected]: handleRejected,

    // createBlog
    [createBlog.pending]: handlePending,
    [createBlog.fulfilled]: state => ({
      ...state,
      loading: false,
    }),
    [createBlog.rejected]: handleRejected,

    // updateBlog
    [updateBlog.pending]: handlePending,
    [updateBlog.fulfilled]: (state, { payload }) => {
      blogsAdapter.updateOne(state.blogs, { id: payload.id, changes: payload })
      state.blog = { ...state.blog, ...payload }
      state.loading = false
    },
    [updateBlog.rejected]: handleRejected,
  },
})

export const { clearBlog } = slice.actions

export default slice.reducer
