import React from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import { Typography, Button, Link } from 'modules/ui'
export default function NavigateToSignIn({ title, classes }) {
  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12} sm={6} md={4}>
        <Typography variant="body1" gutterBottom>
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.footer}>
        <Link to="/sign-in" className={classes.footer}>
          <Button variant="contained" color="primary">
            Sign in
          </Button>
        </Link>
      </Grid>
    </Grid>
  )
}

NavigateToSignIn.propTypes = {
  title: PropTypes.string.isRequired,
}
