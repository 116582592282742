import { useEffect } from 'react'
import * as yup from 'yup'
import { pick } from 'rambda'
import { Form, Formik, useFormikContext } from 'formik'
import { Grid, makeStyles } from '@material-ui/core'
import { PlacesAutocomplete, TextField, SubmitButton, RangeSlider, Checkbox } from 'modules/formik'
import { GoogleMap } from 'modules/ui'
import { validationSchemaObject } from 'modules/partner'

const useStyles = makeStyles(({ spacing }) => ({
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: spacing(4),
  },
}))

const validationSchema = yup
  .object()
  .shape(pick(['email', 'phone', 'fixLocation', 'areaLocation'], validationSchemaObject), [
    ['areaLocation', 'fixLocation'],
  ])

const FormBody = ({ categories }) => {
  const classes = useStyles()
  const { values, setFieldValue } = useFormikContext()
  const isDisplayedField = field =>
    categories
      .find(category => category.id === values.category)
      ?.extraFields.map(extraField => extraField.field)
      ?.includes(field)

  useEffect(() => {
    if (isDisplayedField('fixLocation')) {
      setFieldValue('fixLocation', { city: '', zip: '', address: '', street: '', streetNumber: '' })
      setFieldValue('areaLocation', null)
    } else if (isDisplayedField('areaLocation')) {
      setFieldValue('fixLocation', null)
      setFieldValue('areaLocation', {
        radius: 0,
        city: '',
        address: '',
        availableNationwide: false,
      })
    }
  }, [values.category])

  return (
    <Form>
      <Grid container justifyContent="center" alignContent="flex-end">
        <Grid item xs={12} sm={8} md={6}>
          <TextField id="email" name="email" label="Email" margin="normal" fullWidth />
          <TextField id="phone" name="phone" label="Phone" margin="normal" fullWidth />
          <TextField id="website" name="website" label="Website" margin="normal" fullWidth />
          {isDisplayedField('fixLocation') && (
            <>
              <PlacesAutocomplete margin="normal" fullWidth name="fixLocation" />
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="fixLocation.city"
                    disabled
                    placeholder="City"
                    margin="normal"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="fixLocation.zip"
                    disabled
                    placeholder="Zip"
                    margin="normal"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="fixLocation.street"
                    disabled
                    placeholder="Street"
                    margin="normal"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="fixLocation.streetNumber"
                    disabled
                    placeholder="Street Number"
                    margin="normal"
                    fullWidth
                  />
                </Grid>
              </Grid>
              <GoogleMap {...values.fixLocation} />
            </>
          )}
          {isDisplayedField('areaLocation') && (
            <>
              <PlacesAutocomplete onlyCities margin="normal" fullWidth name="areaLocation" />
              <TextField
                name="areaLocation.city"
                disabled
                placeholder="City"
                margin="normal"
                fullWidth
              />
              <RangeSlider
                singleValue
                label="Radius"
                margin="normal"
                step={5}
                valueLabelDisplay="auto"
                marks
                min={0}
                max={50}
                fullWidth
                disabled={values.areaLocation?.availableNationwide}
                name="areaLocation.radius"
              />
              <Checkbox label="Available nationwide" name="areaLocation.availableNationwide" />
            </>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.footer}>
        <SubmitButton variant="contained" color="primary" margin="dense">
          next
        </SubmitButton>
      </Grid>
    </Form>
  )
}

const CompanyDetailsForm = ({ submitForm, state, categories }) => {
  return (
    <Formik initialValues={state} onSubmit={submitForm} validationSchema={validationSchema}>
      <FormBody categories={categories} />
    </Formik>
  )
}

export default CompanyDetailsForm
