import { useState } from 'react'
import { useHistory } from 'react-router'
import Grid from '@material-ui/core/Grid'
import { Page, Loader } from 'modules/ui'
import { ItemForm, useItem } from 'modules/item'
import { usePartner } from 'modules/partner'
import { uploadToS3 } from 'modules/storage'
import config from '../../../config'

export default () => {
  const { loading, createItem } = useItem()
  const [submitting, setSubmitting] = useState(false)
  const { partners } = usePartner()
  const history = useHistory()

  const handleSubmit = async data => {
    setSubmitting(true)
    const { gallery, partner } = data

    const imagesToUpload = gallery.filter(({ image }) => image instanceof File)
    const tasks = imagesToUpload.map(async ({ image, name }) => {
      const _image = await uploadToS3(image)
      return {
        name,
        image: _image,
      }
    })
    const newImages = await Promise.all(tasks)
    const { category, ...rest } = data
    await createItem({
      ...rest,
      partner: partner.id,
      coverImage: data.coverImage && (await uploadToS3(data.coverImage)),
      gallery: [...gallery.filter(({ image }) => !(image instanceof File)), ...newImages],
    })

    setSubmitting(false)
    history.push(`/items`)
  }

  if (loading) {
    return 'Loading...'
  }

  return (
    <Page title="New item">
      <Loader loading={submitting} />
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={8} md={8}>
          <ItemForm
            imagesPath={config.app.IMAGES_URL}
            partners={partners}
            onSubmit={handleSubmit}
          />
        </Grid>
      </Grid>
    </Page>
  )
}
