import { useState, useEffect } from 'react'
import { Formik, Form } from 'formik'
import * as yup from 'yup'
import FormControl from '@material-ui/core/FormControl'
import { makeStyles } from '@material-ui/core'
import { TextField, FileUploaderField, SubmitButton, Checkbox } from 'modules/formik'
import { TagAutocompleteFormik } from 'modules/tag'
import { ExtraProfileFieldsAutoComplete, extraFields } from 'modules/category'
import config from '../../../config'
import { Typography, Button } from 'modules/ui'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  fileUpload: {
    alignItems: 'flex-start',
  },
  checkBox: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}))

const validationSchema = yup.object({
  name: yup
    .string('Enter name')
    .min(3, 'Should be of minimum 3 characters length')
    .required('Name is required'),
})

const CategoryForm = ({ initialValues, imagesPath, ...props }) => {
  const classes = useStyles()
  const [image, setImage] = useState(initialValues?.image)

  useEffect(() => {
    setImage(initialValues?.image)
  }, [initialValues])

  const handleReplaceClick = setter => () => setter()

  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues} {...props}>
      <Form className={classes.root} noValidate autoComplete="off">
        <TextField id="name" name="name" label="Name" margin="normal" fullWidth />
        {image ? (
          <FormControl className={classes.fileUpload} fullWidth>
            <img width={200} src={`${imagesPath}/${image}`} alt="Category" />
            <Button size="small" onClick={handleReplaceClick(setImage)}>
              replace image
            </Button>
          </FormControl>
        ) : (
          <FileUploaderField
            label="Max file size: 15mb, accepted: jpg|png"
            name="image"
            fullWidth
            singleImage
            withIcon={false}
            withPreview
            buttonText="Choose image"
            imgExtension={['.jpg', '.png', '.gif']}
            maxFileSize={config.app.MAX_ATTACHMENT_SIZE}
          />
        )}
        <TagAutocompleteFormik
          id="tags"
          name="tags"
          label="Tags"
          labelId="category-edit-form-tag-ids-select"
          margin="normal"
          fullWidth
          multiple
          filterSelectedOptions
          disableClearable
        />
        <ExtraProfileFieldsAutoComplete
          id="extraFields"
          name="extraFields"
          label="Extra profile fields"
          labelId="category-edit-form-profile-fields-select"
          margin="normal"
          fullWidth
          multiple
          filterSelectedOptions
          disableClearable
          options={extraFields}
        />
        <TextField
          id="ordering"
          name="ordering"
          label="Ordering"
          type="number"
          margin="normal"
          fullWidth
        />
        <Checkbox
          className={classes.checkBox}
          name="hide"
          fullWidth
          label={<Typography>Hide</Typography>}
        />
        <SubmitButton>Save</SubmitButton>
      </Form>
    </Formik>
  )
}

export default CategoryForm
