import { Page } from 'modules/ui'
import { TransactionList, TokenCounter } from 'modules/partner'
import { useProfile } from 'modules/profile'
import { Grid } from '@material-ui/core'

function View() {
  const { user } = useProfile()
  const { wallet } = user
  return (
    <Page title="Wallet" fullHeight>
      <Grid spacing={2} container direction="row" style={{ height: '100%' }}>
        <Grid item xs={12} sm={12} md={8}>
          <TransactionList wallet={wallet} />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <TokenCounter wallet={wallet} />
        </Grid>
      </Grid>
    </Page>
  )
}

export default View
