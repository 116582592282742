import { Page } from 'modules/ui'
import { CoverForm, useCover } from 'modules/cover'
import { uploadToS3 } from 'modules/storage'
import { Grid } from '@material-ui/core'

const Edit = () => {
  const { updateCover, cover, loading } = useCover()

  const onSubmit = async data => {
    updateCover({
      ...data,
      image: data.image instanceof File ? await uploadToS3(data.image) : data.image,
    })
  }

  if (loading) {
    return null
  }

  return (
    <Page title={`Edit ${cover?.name}`}>
      <Grid container alignContent="center" alignItems="center" justifyContent="center">
        <Grid item xs={12} sm={8} md={8} lg={8}>
          <CoverForm initialState={cover} onSubmit={onSubmit}></CoverForm>
        </Grid>
      </Grid>
    </Page>
  )
}

export default Edit
