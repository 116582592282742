import { useState } from 'react'
import { useRouteMatch, useHistory } from 'react-router-dom'
import { unwrapResult } from '@reduxjs/toolkit'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { Conversation, ConversationsHead, ConversationsList, useChat } from 'modules/chat'
import { Page } from 'modules/ui'
import { useProfile } from 'modules/profile'
import { uploadToS3 } from 'modules/storage'

const messagesLimit = 25

const useStyles = makeStyles(theme => ({
  container: {
    flex: 1,
    height: '100%',
    display: 'flex',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  fillParent: {
    height: '100%',
  },
  conversationList: {
    borderBottom: `3px solid ${theme.palette.grey.main}`,
    width: 400,
    height: '100%',
    flexDirection: 'column',

    [theme.breakpoints.down('md')]: {
      width: 250,
    },
    [theme.breakpoints.down('sm')]: {
      height: '50%',
      width: '100%',
      marginBottom: theme.spacing(4),
    },
  },
  conversationContainer: {
    display: 'flex',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      flexGrow: 1,
      height: '50%',
    },
  },
  scrollableColumn: {
    display: 'flex',
    maxHeight: '100%',
  },
}))

const processAttachment = async ({ type, payload }, onProgress) => {
  const attachment = { type }

  switch (type) {
    case 'image':
      attachment.payload = await uploadToS3(payload, onProgress)
      break
    default:
      attachment.payload = payload
  }

  return attachment
}

const Chat = () => {
  const classes = useStyles()
  const history = useHistory()
  const [submitting, setSubmitting] = useState(false)
  const [uploading, setUploading] = useState(0)
  const go = path => history.push(`/chat/${path}`)

  const matchConversation = useRouteMatch({
    path: `/chat/conversations/:id([0-9a-zA-Z]{24})`,
  })

  const matchNewConversation = useRouteMatch({
    path: `/chat/conversations/new`,
    strict: true,
  })
  const {
    conversations,
    totalConversations,
    findCoversationById,
    newConversation,
    sendMessage,
    searchConversation,
    fetchConversations,
    fetchConversationMessages,
    loading,
  } = useChat()
  const conversation = findCoversationById(matchConversation?.params.id)
  const { user } = useProfile()

  const handleConversationClick = ({ id }) => {
    go(`conversations/${id}`)
  }

  const handleNewConversationClick = () => {
    go('conversations/new')
  }

  const handleProgress = ({ total, loaded }) => {
    setUploading((loaded / total) * 100)
  }

  const handleEnterMessage = async ({ text, attachment }) => {
    setSubmitting(true)

    await sendMessage({
      text,
      ...(attachment && { attachment: await processAttachment(attachment, handleProgress) }),
      conversation: conversation,
      sender: { id: user.id },
    })

    setSubmitting(false)
  }

  const navigateToQuoteView = id => {
    history.push(`/quotes/${id}/view`)
  }

  const handleSearchChange = ({ target }) => {
    searchConversation({ value: target.value, debounced: true })
  }

  const handleNewConversation = async ({ text, attachment, participants }) => {
    setSubmitting(true)
    const { id } = unwrapResult(
      await newConversation({
        text,
        ...(attachment && { attachment: await processAttachment(attachment, handleProgress) }),
        participants: [...participants, user],
      })
    )

    setSubmitting(false)
    go(`conversations/${id}`)
  }

  const handleLoadMore = async () => {
    if (!loading && conversation && conversation.messages.length < conversation.totalMessages) {
      await fetchConversationMessages({
        conversation,
        limit: messagesLimit,
        offset: conversation.messages.length,
      })
    }
  }

  const handleClickAttachment = ({ type, payload }) => {
    switch (type) {
      case 'QUOTE_REQUEST':
      case 'QUOTE_RESPONSE':
        const { requestId } = JSON.parse(payload)
        navigateToQuoteView(requestId)
        break
      default:
        break
    }
  }

  const handleClickMore = async () => {
    if (conversations.length < totalConversations) {
      await fetchConversations({ offset: conversations.length })
    }
  }

  return (
    <Page maxWidth={false} fullHeight>
      <div className={classes.container}>
        <div className={clsx(classes.scrollableColumn, classes.conversationList)}>
          <ConversationsHead
            profileImage={user?.profileImage}
            onNewConversationClick={handleNewConversationClick}
            onSearchChange={handleSearchChange}
            role={user?.role}
          />
          <ConversationsList
            userId={user?.id}
            conversations={conversations}
            activeConversationId={matchConversation?.params.id}
            newConversation={!!matchNewConversation}
            onClick={handleConversationClick}
            onClickMore={handleClickMore}
            showMore={conversations.length < totalConversations}
          />
        </div>
        <div className={clsx(classes.scrollableColumn, classes.conversationContainer)}>
          {(!!matchNewConversation || conversation) && (
            <Conversation
              {...conversation}
              userId={user?.id}
              loading={loading || submitting}
              newConversation={!!matchNewConversation}
              uploading={uploading}
              onEnterMessage={handleEnterMessage}
              onNewConversation={handleNewConversation}
              onClickAttachment={handleClickAttachment}
              onLoadMore={handleLoadMore}
            />
          )}
        </div>
      </div>
    </Page>
  )
}

export default Chat
