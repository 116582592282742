import { useState } from 'react'
import { unwrapResult } from '@reduxjs/toolkit'
import Grid from '@material-ui/core/Grid'
import { useSignIn } from 'modules/auth'
import { Page, Typography, TextField, Button, Link } from 'modules/ui'
import config from '../../config'

let qaTestingCreateUser

if (config.app.QA_TESTING === 'true') {
  qaTestingCreateUser = async ({ email, password }) => {
    const API = require('@aws-amplify/api').default
    const { mutation } = require('gql-query-builder')
    const options = {
      operation: 'createQATestingUser',
      variables: {
        input: {
          type: 'NewQATestingUserInput',
          required: true,
          value: {
            email: email.toLowerCase(),
            password,
          },
        },
      },
    }

    const { query, variables } = mutation(options)
    const version = config.apiVersion
    const result = await API.graphql({ query, variables }, { version: version })

    console.log(result)

    return true
  }
}

export default () => {
  const [state, setState] = useState({
    email: '',
    password: '',
  })
  const { signIn, error } = useSignIn()

  const handleChange = ({ target }) => {
    setState({
      ...state,
      [target.id]: target.value,
    })
  }

  const handleSubmit = async event => {
    event.preventDefault()

    try {
      unwrapResult(await signIn(state))
    } catch (err) {
      if (err.code === 'UserNotFoundException') {
        await qaTestingCreateUser?.(state)
      }
    }
  }

  return (
    <Page title="Sign In" maxWidth="md">
      <Typography>
        <Typography variant="body1" display="inline">
          To manage your WeddingManager Partner account, please sign in. Having problem with login?
          Go and reset your password
        </Typography>
        <Typography variant="body1" gutterBottom display="inline">
          <Link href="/forgot-password">here</Link>.
        </Typography>
      </Typography>

      {error && <Typography color="error">{error.message}</Typography>}
      <form onSubmit={handleSubmit} noValidate autoComplete="off">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              autoFocus
              id="email"
              type="email"
              label="e-mail"
              value={state.email}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              autoFocus
              id="password"
              type="password"
              label="password"
              value={state.password}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" type="submit">
              <Typography>Sign in</Typography>
            </Button>
          </Grid>
        </Grid>
      </form>
    </Page>
  )
}
