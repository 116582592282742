import { useField } from 'formik'

export default Field => ({ name, value, onChange, onBlur, ...props }) => {
  const [field, meta] = useField({ name, value, onChange, onBlur })

  return (
    <Field
      {...field}
      {...props}
      error={meta.touched && !!meta.error}
      helperText={meta.error || ''}
    />
  )
}
