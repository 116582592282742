import {
  Grid,
  makeStyles,
  Card,
  CardContent,
  CardMedia,
  CardActions,
  IconButton,
} from '@material-ui/core'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'
import SettingsIcon from '@material-ui/icons/Settings'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import AddIcon from '@material-ui/icons/Add'
import { Typography, Tooltip } from 'modules/ui'
import { ActiveSwitch } from 'modules/item'
import config from '../../../config'

const useStyles = makeStyles(theme => ({
  card: {
    width: 340,
    padding: theme.spacing(1),
  },
  galleryContainer: {
    height: 65,
  },
  media: {
    height: 200,
    borderRadius: theme.spacing(1),
  },
  statusPendingCard: {
    border: `1px solid ${theme.palette.warning.main}`,
  },
  statusRejectedCard: {
    border: `1px solid ${theme.palette.error.main}`,
  },
  statusPendingColor: {
    color: theme.palette.warning.main,
  },
  statusRejectedColor: {
    color: theme.palette.error.main,
  },
  galleryImage: {
    height: 65,
    width: 65,
    objectFit: 'cover',
    borderRadius: theme.spacing(1),
  },
  counterContainer: {
    height: 65,
    width: 65,
    backgroundColor: theme.palette.grey.main,
    borderRadius: theme.spacing(1),
  },
  infoIcon: {
    position: 'relative',
    left: 0,
    bottom: 0,
  },
  addPhoto: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

const statusInfoMap = status => {
  switch (status) {
    case 'pending':
      return 'Your item is under review'
    case 'rejected':
      return 'Your item is rejected'
    default:
      return
  }
}

const InspirationItemCard = ({
  id,
  coverImage,
  name,
  views,
  likes,
  gallery,
  status,
  onUpdateActiveStatus,
  active,
  onClick,
  tags,
  item,
}) => {
  const size = 3
  const imagesPath = config.app.IMAGES_URL
  const history = useHistory()
  const isStatusPendingOrRejected = status === 'PENDING' || status === 'REJECTED'

  const handleChangeItemSwitch = ({ target }) =>
    onUpdateActiveStatus({ ...item, active: target.checked })

  const renderWarningOrSwitch = () => {
    return isStatusPendingOrRejected ? (
      <Grid container>
        <Grid item>
          <Tooltip title={statusInfoMap(status)} arrow placement="right">
            <ErrorOutlineIcon
              className={clsx(
                classes.infoIcon,
                status === 'PENDING' && classes.statusPendingColor,
                status === 'REJECTED' && classes.statusRejectedColor
              )}
            />
          </Tooltip>
        </Grid>
      </Grid>
    ) : (
      <Tooltip title="Show on inspiration scroll" arrow placement="bottom">
        <div style={{ marginTop: '9px' }}>
          <ActiveSwitch onChange={handleChangeItemSwitch} active={active} />
        </div>
      </Tooltip>
    )
  }

  const classes = useStyles()
  return (
    <Grid item xs={12} sm={12} md={6} lg={4}>
      <Card className={classes.card}>
        <Grid container>
          <Grid item xs={3}>
            {renderWarningOrSwitch()}
          </Grid>

          <Grid item xs={6}>
            <CardMedia
              className={classes.media}
              image={imagesPath + '/' + coverImage}
              title="Contemplative Reptile"
            />
          </Grid>
          <Grid item xs={3}>
            <IconButton onClick={() => onClick({ id })}>
              <SettingsIcon fontSize="large" />
            </IconButton>
          </Grid>
        </Grid>

        <CardContent>
          <Typography gutterBottom variant="h5" component="h2" noWrap>
            {name}
          </Typography>
          <Grid
            container
            spacing={1}
            direction="row-reverse"
            className={classes.galleryContainer}
            alignContent="center"
          >
            <Grid container alignContent="flex-start">
              {gallery.slice(0, size).map((item, i) => (
                <Grid key={i} item xs={3}>
                  <img
                    className={classes.galleryImage}
                    src={imagesPath + '/' + item.image}
                    alt="Gallery"
                  ></img>
                </Grid>
              ))}
              {gallery.length > size && (
                <Grid item xs={3}>
                  <Grid
                    container
                    className={classes.counterContainer}
                    direction="column"
                    alignItems="center"
                    align="center"
                    justifyContent="center"
                  >
                    <Typography color="textSecondary">+ {gallery.length - size}</Typography>
                  </Grid>
                </Grid>
              )}

              {!gallery.length && (
                <Grid item xs={12} className={classes.addPhoto}>
                  <Grid
                    container
                    className={classes.counterContainer}
                    direction="column"
                    alignItems="center"
                    align="center"
                    justifyContent="center"
                  >
                    <Typography color="textSecondary">
                      <IconButton
                        onClick={() =>
                          history.push({
                            pathname: `/inspiration/${id}/edit`,
                            search: 'addToGallery=true',
                          })
                        }
                      >
                        <AddIcon />
                      </IconButton>
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Grid
            container
            alignItems="center"
            alignContent="center"
            spacing={2}
            justifyContent="center"
          >
            <Grid item>
              <VisibilityOutlinedIcon />
            </Grid>
            <Grid item>
              <Typography display="inline">{views}</Typography>
            </Grid>
            <Grid item>
              <FavoriteBorderIcon />
            </Grid>
            <Grid item>
              <Typography display="inline">{likes}</Typography>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </Grid>
  )
}

export default InspirationItemCard
