import 'date-fns'
import DateFnsUtils from '@date-io/date-fns'
import { useField } from 'formik'
import FormControl from '@material-ui/core/FormControl'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { FormHelperText, FormLabel } from 'modules/ui'

const DatePicker = ({ name, label, labelId, fullWidth, margin, ...props }) => {
  const [field, meta, helpers] = useField({ name })

  const handleChange = date => {
    helpers.setValue(date)
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <FormControl fullWidth={fullWidth} margin={margin}>
        <FormLabel>{label}</FormLabel>
        <KeyboardDatePicker
          {...field}
          margin="normal"
          id="date-picker-dialog"
          format="MM/dd/yyyy"
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          {...props}
          onChange={handleChange}
        />
        {meta.error && (
          <FormHelperText error id={`${labelId}-helper`}>
            {meta.error}
          </FormHelperText>
        )}
      </FormControl>
    </MuiPickersUtilsProvider>
  )
}

export default DatePicker
