import { useState, useEffect } from 'react'
import { Formik, Form, useFormikContext } from 'formik'
import * as yup from 'yup'
import { makeStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import { FormLabel } from 'modules/ui'
import { Button } from 'modules/ui'
import { TextField, FileUploaderField, SubmitButton } from 'modules/formik'
import config from '../../../config'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  fileUpload: {
    margin: 20,
  },
  profilePictureContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
}))

const initialState = {
  name: '',
  email: '',
  phone: '',
  profileImage: '',
}

const validationSchema = yup.object().shape({
  name: yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
  email: yup.string().email('Invalid email address').required('Required'),
  phone: yup.string().min(6, 'Invalid phone!').required('Required'),
  profileImage: yup.mixed().test('profile picture test', 'Profile picture is required', value => {
    if (Array.isArray(value)) {
      return value.length > 0
    } else {
      return !!value
    }
  }),
})

const AdminForm = ({ initialValues = initialState, imagesPath, submitButton = true, ...props }) => {
  // const categoryId = initialValues.category?.id

  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues} {...props}>
      <FormBody {...{ imagesPath, submitButton }} />
    </Formik>
  )
}

const FormBody = ({ imagesPath, submitButton }) => {
  const classes = useStyles()
  const { initialValues, setFieldValue } = useFormikContext()
  const [profileImage, setProfileImage] = useState(initialValues?.profileImage)

  const handleReplaceClick = () => {
    setProfileImage()
    setFieldValue('profileImage', '')
  }

  useEffect(() => {
    // TODO fix
    setProfileImage(initialValues?.profileImage)
  }, [initialValues])

  return (
    <Form className={classes.root} autoComplete="off">
      <TextField id="name" name="name" label="Name" margin="normal" fullWidth />
      <TextField id="phone" name="phone" label="Phone number" margin="normal" fullWidth />
      <TextField id="email" name="email" label="Email address" margin="normal" fullWidth />
      <FormControl margin="normal" fullWidth>
        <FormLabel>Profile picture</FormLabel>
        {profileImage ? (
          <div className={classes.fileUpload}>
            <div className={classes.profilePictureContainer}>
              <img width={400} src={`${imagesPath}/${profileImage}`} alt="Profile" />
            </div>
            <Button size="small" onClick={handleReplaceClick}>
              replace image
            </Button>
          </div>
        ) : (
          <FileUploaderField
            label="Profile image"
            id="profileImage"
            name="profileImage"
            fullWidth
            singleImage
            withIcon={false}
            withPreview
            buttonText="Choose image"
            imgExtension={['.jpg', '.png', '.gif']}
            maxFileSize={config.app.MAX_ATTACHMENT_SIZE}
          />
        )}
      </FormControl>
      {!!submitButton && <SubmitButton>Save</SubmitButton>}
    </Form>
  )
}

export default AdminForm
