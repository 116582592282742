import { takeLatestAsync } from 'saga-toolkit'
import { query } from 'modules/api'
import * as actions from './slice'

const weddingField = [
  'coupleName',
  'date',
  'description',
  { coverImage: ['name'] },
  { address: ['name'] },
]

function* fetchWedding({ meta }) {
  const { id } = meta.arg
  const options = {
    operation: 'weddingById',
    fields: weddingField,
    variables: {
      id: {
        type: 'ID',
        value: id,
        required: true,
      },
    },
  }

  const { weddingById } = yield query(options)

  return weddingById
}

export default [takeLatestAsync(actions.fetchWedding.type, fetchWedding)]
