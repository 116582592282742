import { pick } from 'rambda'
import { takeLatestAsync } from 'saga-toolkit'
import * as actions from './slice'
import { query, mutation } from 'modules/api'

const parseHighlight = highlight => ({
  ...highlight,
  elements: highlight.elements.sort((a, b) => a.order - b.order),
})

const highlightFields = [
  'id',
  'name',
  'type',
  'variant',
  'description',
  {
    elements: [
      'order',
      {
        element: [
          {
            '... on Blog': ['id', 'name'],
          },
          {
            '... on Category': ['id', 'name'],
          },
          {
            '... on TagGroup': ['id', 'name'],
          },
          {
            '... on Partner': ['id', 'name'],
          },
        ],
      },
    ],
  },
  'ordering',
]

const highlightsFields = ['id', 'name', 'type', 'variant', 'ordering']

function* fetchHighlights() {
  const options = {
    operation: 'highlights',
    fields: highlightsFields,
  }

  const result = yield query(options, true)

  return result.highlights
}

function* fetchHighlight({ meta }) {
  const { id } = meta.arg
  const options = {
    operation: 'highlight',
    fields: highlightFields,
    variables: {
      id: {
        type: 'ID',
        value: id,
        required: true,
      },
    },
  }

  const result = yield query(options, true)

  return parseHighlight(result.highlight)
}

function* createHighlight({ meta }) {
  const options = {
    operation: 'insertHighlight',
    fields: ['id'],
    variables: {
      input: {
        value: meta.arg,
        type: 'HighlightInput!',
      },
    },
  }

  const { insertHighlight } = yield mutation(options, true)

  return insertHighlight
}

function* updateHighlight({ meta }) {
  const body = pick(
    ['name', 'description', 'type', 'ordering', 'variant', 'elements', 'id'],
    meta.arg
  )
  const options = {
    operation: 'updateHighlight',
    fields: highlightFields,
    variables: {
      input: {
        value: body,
        type: 'HighlightInput!',
      },
    },
  }

  const result = yield mutation(options, true)

  return result.updateHighlight
}

function* removeHighlight({ meta }) {
  const { id } = meta.arg
  const options = {
    operation: 'delete',
    variables: {
      id: {
        value: id,
        type: 'ID!',
      },
    },
  }

  const result = yield mutation(options, true)

  return result
}

function* moveHighlight({ meta }) {
  const { id, dir } = meta.arg
  const options = {
    operation: 'moveHighlight',
    fields: highlightsFields,
    variables: {
      id: {
        value: id,
        type: 'ID!',
      },
      dir: {
        value: dir,
        type: 'Direction!',
      },
    },
  }

  const { moveHighlight } = yield mutation(options, true)

  return moveHighlight
}

export default [
  takeLatestAsync(actions.fetchHighlights.type, fetchHighlights),
  takeLatestAsync(actions.fetchHighlight.type, fetchHighlight),
  takeLatestAsync(actions.createHighlight.type, createHighlight),
  takeLatestAsync(actions.updateHighlight.type, updateHighlight),
  takeLatestAsync(actions.removeHighlight.type, removeHighlight),
  takeLatestAsync(actions.moveHighlight.type, moveHighlight),
]
