import { tagsAdapter } from './slice'

export const selectRoot = state => state.tag
const selectTags = state => selectRoot(state).tags
export const selectTag = state => selectRoot(state).tag

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
  selectById,
} = tagsAdapter.getSelectors(selectTags)
