import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { routerMiddleware } from 'connected-react-router'
import createSagaMiddleware from 'redux-saga'
import { all } from 'redux-saga/effects'
import * as Sentry from '@sentry/react'
import createRootReducer from './reducers'
import mainSagas from '../sagas'
import { sagas as authSagas, actions as authActions } from 'modules/auth'
import { sagas as categorySagas } from 'modules/category'
import { sagas as chatSagas } from 'modules/chat'
import { sagas as tagGroupSagas } from 'modules/tagGroup'
import { sagas as itemSagas } from 'modules/item'
import { sagas as notificationSagas } from 'modules/notification'
import { sagas as tagSagas } from 'modules/tag'
import { sagas as userSagas } from 'modules/user'
import { sagas as partnerSagas } from 'modules/partner'
import { sagas as filterGroupSagas } from 'modules/filter-group'
import { sagas as blogSagas } from 'modules/blog'
import { sagas as highlightSagas } from 'modules/highlight'
import { sagas as profileSagas } from 'modules/profile'
import { sagas as trackingSagas } from 'modules/tracking'
import { sagas as inspirationSagas } from 'modules/inspiration'
import { sagas as coverSagas } from 'modules/cover'
import { sagas as adminSagas } from 'modules/admin'
import { sagas as availabilitySagas } from 'modules/availability'
import { sagas as coupleSagas } from 'modules/couple'
import { sagas as quoteSagas } from 'modules/quote'
import { sagas as weddingSagas } from 'modules/wedding'
import { sagas as appSagas } from 'modules/app'

const createStore = ({ history, signOutAction, initialState }) => {
  const sagaMiddleware = createSagaMiddleware()
  const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    actionTransformer: action => {
      const { signIn } = authActions

      if ([signIn.pending, signIn.fulfilled, signIn.rejected].includes(action.type)) {
        return {
          ...action,
          meta: {
            ...action.meta,
            arg: {
              ...action.meta.arg,
              password: '***',
            },
          },
        }
      }

      return action
    },
    stateTransformer: state => state,
  })
  const store = configureStore({
    reducer: createRootReducer({ history, signOutAction, initialState }),
    middleware: [...getDefaultMiddleware(), routerMiddleware(history), sagaMiddleware],
    enhancers: [sentryReduxEnhancer],
    devTools: process.env.NODE_ENV !== 'production',
  })

  sagaMiddleware.run(function* () {
    yield all([
      ...mainSagas,
      ...authSagas,
      ...categorySagas,
      ...chatSagas,
      ...tagGroupSagas,
      ...itemSagas,
      ...notificationSagas,
      ...tagSagas,
      ...userSagas,
      ...partnerSagas,
      ...blogSagas,
      ...filterGroupSagas,
      ...highlightSagas,
      ...profileSagas,
      ...trackingSagas,
      ...inspirationSagas,
      ...coverSagas,
      ...adminSagas,
      ...availabilitySagas,
      ...coupleSagas,
      ...quoteSagas,
      ...weddingSagas,
      ...appSagas,
    ])
  })

  // if (process.env.NODE_ENV !== 'production' && module.hot) {
  //   module.hot.accept('../src/modules', () => store.replaceReducer(rootReducer))
  // }

  return store
}

export default createStore
