import { useRef, useState } from 'react'
import { useField } from 'formik'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import ImageList from '@material-ui/core/ImageList'
import ImageListItem from '@material-ui/core/ImageListItem'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import ImageListItemBar from '@material-ui/core/ImageListItemBar'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { UploadImageForm } from 'modules/partner'
import { Button } from 'modules/ui'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    backgroundColor: theme.palette.background.paper,
    alignItems: 'center',
    flexDirection: 'row',
  },
  gridList: {
    width: '100%',
    flexWrap: 'wrap',
    transform: 'translateZ(0)',
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  addButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    backgroundColor: '#FAFAFAFA',
    border: 'solid',
    borderWidth: 0.1,
    borderColor: '#A9A9A9',
  },
  addButton: {
    width: '100%',
    height: '100%',
  },
  label: {
    marginBlock: 10,
  },
}))

const useCols = () => {
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.up('sm'))
  const md = useMediaQuery(theme.breakpoints.up('md'))
  const lg = useMediaQuery(theme.breakpoints.up('lg'))

  switch (true) {
    case lg:
      return 4.0
    case md:
      return 3.0
    case sm:
      return 2.0
    default:
      return 2.0
  }
}

export default ({ name, id, imagesPath, isOpen = false }) => {
  const classes = useStyles()
  const [openDialog, setOpenDialog] = useState(isOpen)
  const [, meta, helpers] = useField({
    name,
  })
  const coverImageForm = useRef()
  const { setValue } = helpers
  const { value } = meta

  const cols = useCols()

  const handleClickAddItem = () => {
    setOpenDialog(true)
  }

  const handleCoverImageSubmit = data => {
    setValue([...value, data.image])
    setOpenDialog(false)
  }

  const handleClickRemoveItem = ind => {
    const tmpGallery = value.filter((item, i) => ind !== value.length - 1 - i)
    setValue(tmpGallery)
  }

  return (
    <div>
      <ImageList className={classes.gridList} cols={cols}>
        <ImageListItem>
          <div className={classes.addButtonWrapper}>
            <Button onClick={handleClickAddItem} className={classes.addButton}>
              <AddIcon />
            </Button>
          </div>
        </ImageListItem>
        {Object.values(value)
          .reverse()
          .map((image, i) => (
            <ImageListItem key={i}>
              {image instanceof File ? (
                <img src={URL.createObjectURL(image)} alt="Cover" />
              ) : (
                <img src={imagesPath + '/' + image} alt="Cover" />
              )}
              <ImageListItemBar
                classes={{
                  root: classes.titleBar,
                  title: classes.title,
                }}
                actionIcon={
                  <Button tooltip="Remove" onClick={() => handleClickRemoveItem(i)}>
                    <RemoveIcon style={{ color: 'white' }} />
                  </Button>
                }
              />
            </ImageListItem>
          ))}
      </ImageList>

      <Dialog fullWidth maxWidth="sm" open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle id="form-dialog-title">Upload cover picture</DialogTitle>
        <DialogContent>
          <UploadImageForm onSubmit={handleCoverImageSubmit} innerRef={coverImageForm} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => coverImageForm.current.submitForm()} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
