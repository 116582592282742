export const radiusMarks = [
  {
    value: 10,
    label: '10',
  },
  {
    value: 20,
    label: '20',
  },
  {
    value: 50,
    label: '50',
  },
  {
    value: 100,
    label: '100',
  },
  {
    value: 150,
    label: '150',
  },
  {
    value: 200,
    label: '200',
  },
]

export const valueLabelFormatForRadius = value =>
  radiusMarks.findIndex(mark => mark.value === value) + 1
