import { DataGrid as UIDataGrid } from '@material-ui/data-grid'
import { useT } from 'modules/i18n'

const translateFunctionReturnValue = (old, t) =>
  function () {
    return t(old.apply(old, arguments))
  }

const translateColumns = (columns, t) =>
  columns.map(column => ({
    ...column,
    headerName: t(column.headerName),
    ...(column.field === 'status' &&
      column.valueGetter && { valueGetter: translateFunctionReturnValue(column.valueGetter, t) }),
  }))

const DataGrid = ({ columns, ...props }) => {
  const t = useT()
  const translatedColumns = translateColumns(columns, t)

  return <UIDataGrid columns={translatedColumns} {...props} />
}

export default DataGrid
