import Box from '@material-ui/core/Box'
import { useProfile } from 'modules/profile'
import { Page, Typography } from 'modules/ui'

const Home = () => {
  const { user } = useProfile()

  if (!user) {
    return null
  }

  return (
    <Page title="Home">
      <Typography gutterBottom variant="caption">
        {`Profile status: ${user.status}`}
      </Typography>
      {user.type === 'Partner' ? (
        <Box mt={1}>
          <Typography>
            <Typography gutterBottom variant="h6">
              Örülünk, hogy itt vagy! 🥳🥳
            </Typography>
            <Typography gutterBottom>
              Sok-sok hónapnyi fejlesztés után, végre büszkén fogadunk Téged a Wedding Manager Admin
              felületén! Ez itt az applikáció szíve és lelke, innen fogod tudni irányítani a
              vállalkozásodat! Módosíthatod és alakíthatod a profilodat, feltölthetsz új inspirációs
              anyagokat, illetve innen in kommunikálhatsz az ügyfeleiddel!
            </Typography>
            <Typography gutterBottom>
              A csapatunk azon dolgozik nap, mint nap, hogy folyamatosan fejlessze a rendszert.
              Rengeteg funkció van készülőben, amiket alig várunk, hogy megmutathassunk! Ezzel
              egyidőben szeretettel várunk minden visszajelzést! 🥰
            </Typography>
            <Typography>
              A velünk való kommunikáció a belső messenger rendszeren a leggyorsabb és
              leghatékonyabb. Ha elakadtál és segítségre van szükséged, csak dobj egy üzenetet! 🤗
            </Typography>
          </Typography>
        </Box>
      ) : (
        <Typography>Yo {user.name}! </Typography>
      )}
    </Page>
  )
}

export default Home
