import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import { Typography, TextField, Button } from 'modules/ui'
import { useForgotPassword } from '../../hooks'

const ConfirmFormSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email address').required('Required'),
  password: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/,
      'Your password must be at least 8 characters long, must contain one uppercase, one lowercase character and one number'
    )
    .required('Required'),
  passwordConfirm: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/,
      'Must Contain 8 Characters, one uppercase, one lowercase, one number'
    )
    .required('Required')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),

  code: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
})

export default function ConfirmForm({ onSubmit, classes, renderError }) {
  const { loading, currentEmail } = useForgotPassword()
  const formik = useFormik({
    initialValues: {
      email: currentEmail,
      password: '',
      passwordConfirm: '',
      code: '',
    },
    onSubmit: val => onSubmit(val),
    validationSchema: ConfirmFormSchema,
  })

  const { values, handleChange, validateForm, errors, handleSubmit } = formik
  const { email, password, passwordConfirm, code } = values

  useEffect(() => {
    validateForm()
    // eslint-disable-next-line
  }, [])

  return (
    <form onSubmit={handleSubmit} noValidate autoComplete="off">
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body1" gutterBottom>
            Check your mailbox for the 6 digit confirmation code.
          </Typography>
          <TextField
            fullWidth
            margin="normal"
            id="email"
            type="email"
            label="e-mail"
            value={email}
            onChange={handleChange}
          />
          <TextField
            autoFocus
            fullWidth
            margin="normal"
            id="code"
            type="text"
            label="Confirmation code"
            value={code}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            margin="normal"
            id="password"
            type="password"
            label="Password"
            value={password}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            margin="normal"
            id="passwordConfirm"
            type="password"
            label="Confirm password"
            value={passwordConfirm}
            onChange={handleChange}
          />
        </Grid>
        {renderError()}
        <Grid item xs={12} className={classes.footer}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={!!Object.keys(errors).length || loading}
          >
            next
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

ConfirmForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}
