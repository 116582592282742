import { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { SignUpStepper, SingUpContext, ContactForm } from 'modules/auth'

const Contact = () => {
  const history = useHistory()
  const { state, setState } = useContext(SingUpContext)

  const submitForm = values => {
    setState({ ...state, ...values })
    history.push('/sign-up/registration')
  }

  return (
    <SignUpStepper>
      <ContactForm submitForm={submitForm} state={state} />
    </SignUpStepper>
  )
}

export default Contact
