import * as yup from 'yup'
import { Formik, Form } from 'formik'
import { makeStyles, Grid } from '@material-ui/core'
import { AttachmentsUploader, Typography } from 'modules/ui'
import { TextField, DatePicker, SubmitButton, RichTextEditor } from 'modules/formik'
import config from '../../../config'

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(2),
  },
  fileUpload: {
    alignItems: 'flex-start',
  },
  categoryChip: {
    textTransform: 'uppercase',
    color: 'white',
    borderRadius: theme.spacing(1),
  },
  alignSelfCenter: {
    alignSelf: 'center',
  },
  sectionContainer: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  rejectedContainer: {
    display: 'flex',
    flex: 1,
    alignContent: 'center',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

const initialState = {
  attachments: [],
  validUntil: new Date(),
  text: '',
  name: '',
  email: '',
  phone: '',
}

const validationSchema = yup.object({
  name: yup.string().min(2, 'Too short!').required('Required'),
  phone: yup.string().min(2, 'Invalid phone!').required('Required'),
  email: yup.string().email('Invalid email address').required('Required'),
  text: yup.string().min(12, 'Too short!').required('Required'),
  validUntil: yup.date().required('Date is required'),
})

const FormBody = ({ imagesPath, disabled }) => {
  const classes = useStyles()

  return (
    <Form className={classes.root} noValidate autoComplete="off">
      <Grid container spacing={4}>
        <Grid className={classes.sectionContainer} item xs={12} lg={6}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                id="name"
                name="name"
                label="Name"
                margin="normal"
                fullWidth
                disabled={disabled}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={6} />
            <Grid item xs={6}>
              <TextField
                id="phone"
                name="phone"
                label="Phone number"
                margin="normal"
                fullWidth
                disabled={disabled}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="email"
                name="email"
                label="Email"
                margin="normal"
                fullWidth
                disabled={disabled}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <RichTextEditor
                disabled={disabled}
                id="text"
                name="text"
                label="Quote's description"
              />
            </Grid>
            <Grid item xs={12}>
              <DatePicker
                disabled={disabled}
                name="validUntil"
                label="Valid until"
                margin="normal"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <AttachmentsUploader
            maxFileSize={config.app.MAX_ATTACHMENT_SIZE}
            label="Attachments"
            labelId="attachments"
            name="attachments"
            margin="normal"
            fullWidth
            disabled={disabled}
            imagesPath={imagesPath}
          />
        </Grid>
      </Grid>

      {!disabled && (
        <Grid container spacing={2} alignItems="center" alignContent="center">
          <Grid xs={12} item align="center">
            <SubmitButton variant="contained" color="primary">
              Accept quote
            </SubmitButton>
          </Grid>
        </Grid>
      )}
    </Form>
  )
}

const QuoteResponseForm = ({
  onSubmit,
  disabled,
  rejected,
  initialValues = initialState,
  imagesPath,
}) => {
  const classes = useStyles()

  if (rejected) {
    return (
      <div className={classes.rejectedContainer}>
        <Typography variant="h6" color="error">
          Request was rejected
        </Typography>
      </div>
    )
  }
  return (
    <Formik onSubmit={onSubmit} validationSchema={validationSchema} initialValues={initialValues}>
      <FormBody disabled={disabled} imagesPath={imagesPath} />
    </Formik>
  )
}

export default QuoteResponseForm
