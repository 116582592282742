import { Route, useRouteMatch } from 'react-router-dom'
import List from './List'
import Edit from './Edit'
import New from './New'
import Remove from './Remove'

const CoversRouting = () => {
  const match = useRouteMatch()

  return (
    <>
      <Route path={[match.url, `${match.url}/:id/remove`]} exact component={List} />
      <Route path={`${match.url}/new`} exact component={New} />
      <Route path={`${match.url}/:id/edit`} component={Edit} />
      <Route path={`${match.url}/:id/remove`} component={Remove} />
    </>
  )
}

export default CoversRouting
