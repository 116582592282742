import { takeLatestAsync } from 'saga-toolkit'
import { sortDirections } from 'modules/ui'
import * as actions from './slice'

function updateLocation({ meta }) {
  const queryParams = new URLSearchParams(meta.arg)
  const page = parseInt(queryParams.get('page') || '0', 10)
  const pageSize = parseInt(queryParams.get('pageSize') || '10', 10)
  const sort = queryParams.get('sort')
  const filter = queryParams.get('filter')
  const [sortBy, sortDirection = sortDirections.ASC] = sort ? sort.split(',') : []
  const offset = page * pageSize
  const limit = pageSize

  return { pageSize, page, filter, sortBy, sortDirection, offset, limit }
}

export default [takeLatestAsync(actions.updateLocation.type, updateLocation)]
