import { useAvailability } from 'modules/availability'
import { Typography } from 'modules/ui'
import AvailabilityDialog from './AvailabilityDialog'

const Accept = () => {
  const { availability, acceptAvailability } = useAvailability()

  if (!availability) {
    return null
  }

  return (
    <AvailabilityDialog
      dialogTitle="Accept?"
      availability={availability}
      onAccept={acceptAvailability}
      dialogContentText={
        <Typography variant="inherit" tParams={{ user: availability.user.name }}>
          {'Accept availability from: {{user}}'}
        </Typography>
      }
      submitLabel="Accept"
    />
  )
}

export default Accept
