import { useState } from 'react'
import { Formik, Form, useFormikContext } from 'formik'
import * as yup from 'yup'
import { FormControl, makeStyles, Grid } from '@material-ui/core'
import {
  TextField,
  FileUploaderField,
  Checkbox,
  SubmitButton,
  RichTextEditor,
} from 'modules/formik'
import { GalleryUploaderField } from 'modules/item'
import { Section, Typography, Button, FormLabel, Link } from 'modules/ui'
import { TagAutocompleteFormik } from 'modules/tag'
import config from '../../../config'

const validationSchema = yup.object({
  name: yup
    .string('Enter name')
    .min(2, 'Should be of minimum 2 characters length')
    .required('Name is required'),
  termsAndConditions: yup
    .bool()
    .oneOf(
      [true],
      'You must accept terms and condition before create or update a new inspiration!'
    ),
  tags: yup.array().min(1, 'Should be at least 1 tag').required('Required'),
  coverImage: yup.mixed().test('Cover image test', 'Cover image is required', value => {
    if (Array.isArray(value)) {
      return value.length > 0
    } else {
      return !!value
    }
  }),
})

const useStyles = makeStyles(theme => ({
  cover: {
    width: 'auto',
    height: 'auto',
    maxWidth: '50%',
    maxHeight: '50%',
    marginTop: theme.spacing(2),
  },
  center: {
    alignItems: 'center',
  },
  replaceButton: {
    marginTop: theme.spacing(2),
  },
}))

const initialState = {
  name: '',
  active: true,
  status: 'PENDING',
  coverImage: '',
  tags: [],
  description: '',
  gallery: [],
  termsAndConditions: false,
}

const RemoveImageButton = ({ setImage }) => {
  const { values, setValues } = useFormikContext()

  const removeImage = () => {
    setImage(null)
    setValues({ ...values, coverImage: '' })
  }

  return (
    <Button size="small" onClick={removeImage}>
      replace image
    </Button>
  )
}

const PartnerItemForm = ({
  initialValues = initialState,
  onSubmit,
  category,
  handleRemoveItem,
  addGalleryOpen,
  onClose,
  edit,
  ...props
}) => {
  const classes = useStyles()
  const imagesPath = config.app.IMAGES_URL
  const [image, setImage] = useState(initialValues?.coverImage)

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      validateOnBlur={false}
      onSubmit={onSubmit}
      {...props}
    >
      <Form noValidate autoComplete="off">
        <TextField name="name" autoFocus label="Name" fullWidth />
        {image ? (
          <FormControl fullWidth margin="normal" className={classes.center}>
            <FormLabel>Cover Image</FormLabel>
            <img alt="cover" className={classes.cover} src={`${imagesPath}/${image}`} />
            <div className={classes.replaceButton}>
              <RemoveImageButton setImage={setImage} />
            </div>
          </FormControl>
        ) : (
          <FileUploaderField
            margin="normal"
            label="Upload cover image"
            name="coverImage"
            fullWidth
            singleImage
            withIcon={false}
            withPreview
            buttonText="Choose image"
            imgExtension={['.jpg', '.png']}
            maxFileSize={config.app.MAX_ATTACHMENT_SIZE}
          />
        )}
        <Section label="Add other images to your inspiration" subLabel="optional">
          <GalleryUploaderField
            name="gallery"
            imagesPath={imagesPath}
            isOpen={addGalleryOpen}
            fullWidth
          />
        </Section>
        <Section label="Tags" subLabel="Select your tags">
          <TagAutocompleteFormik
            categoryId={category.id}
            id="tags"
            name="tags"
            label="Tags"
            labelId="partner-edit-form-tag-ids-select"
            margin="normal"
            fullWidth
            multiple
            filterSelectedOptions
            disableClearable
          />
        </Section>
        <Section label="Description" subLabel="Add a description to your inspiration (optional)">
          <RichTextEditor
            id="description"
            name="description"
            label=""
            placeholder="Start to type..."
          />
        </Section>
          <Checkbox
            name="termsAndConditions"
            fullWidth
            label={
              <Typography>
                <Typography display="inline">The uploaded content complies with the </Typography>
                <Link
                  color="primary"
                  href="https://www.weddingmanager.app/_files/ugd/4d2baf_65c9ccaa587f45e683f0b74fb2bc43c5.pdf"
                  target="_blank"
                >
                  Terms and conditions
                </Link>
              </Typography>
            }
          />
        <Grid container justifyContent="center" spacing={2}>
          {edit && (
            <Grid item>
              <Button color="primary" type="submit" onClick={handleRemoveItem}>
                <Typography variant="inherit">Remove Item</Typography>
              </Button>
            </Grid>
          )}
          <Grid item>
            <Button color="primary" onClick={onClose}>
              <Typography variant="inherit">Cancel</Typography>
            </Button>
          </Grid>
          <Grid item>
            <SubmitButton color="primary" variant="contained">
              <Typography variant="inherit">{edit ? 'Update' : 'Create'}</Typography>
            </SubmitButton>
          </Grid>
        </Grid>
      </Form>
    </Formik>
  )
}

export default PartnerItemForm
