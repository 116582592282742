import { highlightsAdapter } from './slice'

export const selectRoot = state => state.highlight
export const selectHighlights = state => selectRoot(state).highlights
export const selectHighlight = state => selectRoot(state).highlight

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
  selectById,
} = highlightsAdapter.getSelectors(selectHighlights)
