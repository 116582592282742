import { takeLatestAsync } from 'saga-toolkit'
import { put } from 'redux-saga/effects'
import { pick } from 'rambda'
import { query, mutation } from 'modules/api'
import { actions as appActions } from 'modules/app'
import * as actions from './slice'
import { createUpdateUserInput, partnerProfileFields } from '../profile/fields'

const userFields = [
  'id',
  'name',
  'cognitoId',
  'type',
  'role',
  'status',
  {
    profile: [
      {
        '... on CoupleProfile': [
          'whoAreYou',
          'myName',
          'partnersName',
          'exactWeddingDate',
          { profileImage: ['name'] },
        ],
      },
      {
        '... on AdminProfile': [{ profileImage: ['name'] }, 'name', 'email', 'phone'],
      },
      {
        '... on PartnerProfile': [
          'name',
          'email',
          'contactName',
          'contactEmail',
          'contactPhone',
          'description',
          'website',
          'phone',
          { profileImage: ['name'] },
          { coverImages: ['name'] },
          {
            address: ['name', { coordinate: ['lat', 'lng'] }],
          },
          {
            guestRange: ['low', 'high'],
          },
          {
            tags: ['id', 'name'],
          },
          {
            category: ['id', 'name'],
          },
        ],
      },
    ],
  },
  'createdAt',
  'updatedAt',
  { wallet: ['id', 'balance'] },
]

const parseUser = partner => ({
  ...partner,
  profileImage: partner.profileImage?.name,
  coverImages: partner.coverImages?.map(({ name }) => name),
})

function* fetchUsers() {
  const { users } = yield query(
    {
      operation: 'users',
      fields: [
        {
          '...on Partner': ['name', 'id', 'email', 'type', 'role', 'status'],
        },
        {
          '...on Admin': ['name', 'id', 'email', 'type', 'role', 'status'],
        },
        {
          '...on Couple': ['name', 'id', 'email', 'type', 'role', 'status'],
        },
      ],
    },
    true
  )

  return users
}

function* fetchUser({ meta }) {
  const { id } = meta.arg
  const { user } = yield query(
    {
      operation: 'user',
      fields: [
        { '...on Partner': partnerProfileFields },
        {
          '...on Admin': [
            'id',
            'type',
            'name',
            'role',
            'email',
            { profileImage: ['name'] },
            { devices: ['id', 'signedIn', { notificationPreferences: ['token', 'enabled'] }] },
            'role',
            'status',
            'active',
            'name',
            'phone',
            'cognitoId',
          ],
        },
        {
          '...on Couple': [
            'id',
            'type',
            'name',
            'role',
            'email',
            { profileImage: ['name'] },
            { devices: ['id', 'signedIn', { notificationPreferences: ['token', 'enabled'] }] },
            'role',
            'status',
            'active',
            'name',
            'cognitoId',
          ],
        },
      ],
      variables: {
        id: {
          type: 'ID',
          value: id,
          required: true,
        },
      },
    },
    true
  )

  return parseUser(user)
}

function* searchUser({ meta }) {
  const options = {
    operation: 'users',
    fields: [
      { '...on Partner': ['name', 'id', 'email', { profileImage: ['name'] }] },
      {
        '...on Admin': ['name', 'id', 'email', { profileImage: ['name'] }],
      },
      {
        '...on Couple': ['name', 'id', 'email', { profileImage: ['name'] }],
      },
    ],
    variables: {
      searchByName: meta.arg,
    },
  }

  const { users } = yield query(options, true)

  return users
}

function* createUser({ meta }) {
  // TODO: create cognito user, once logged in account needs to be linked

  const { type } = meta.arg
  const input = pick(['type', 'role', 'status', 'name', 'email'], meta.arg)

  input[type.toLowerCase()] = meta.arg.profile

  const options = {
    operation: 'insertUser',
    fields: userFields,
    variables: {
      input: {
        value: input,
        type: 'UserInput',
      },
    },
  }

  const { insertUser } = yield mutation(options, true)
  yield put(appActions.showSnackMessage('User is created!'))

  return insertUser
}

function* updateUser({ meta }) {
  const input = createUpdateUserInput(meta.arg)

  const { updateUser } = yield mutation(
    {
      operation: `updateUser`,
      variables: {
        input: {
          value: input,
          type: 'UpdateUserInput!',
        },
      },
      fields: [
        { '...on Partner': partnerProfileFields },
        {
          '...on Admin': [
            'id',
            'type',
            'name',
            'role',
            'email',
            { profileImage: ['name'] },
            { devices: ['id', 'signedIn', { notificationPreferences: ['token', 'enabled'] }] },
            'role',
            'status',
            'active',
            'name',
            'phone',
          ],
        },
      ],
    },
    true
  )
  yield put(appActions.showSnackMessage('User is updated!'))

  return updateUser
}

export default [
  takeLatestAsync(actions.fetchUsers.type, fetchUsers),
  takeLatestAsync(actions.fetchUser.type, fetchUser),
  takeLatestAsync(actions.searchUser.type, searchUser),
  takeLatestAsync(actions.createUser.type, createUser),
  takeLatestAsync(actions.updateUser.type, updateUser),
]
