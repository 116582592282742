import { useState, useEffect } from 'react'
import { Formik, Form, useFormikContext } from 'formik'
import { pick } from 'rambda'
import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import MuiCheckbox from '@material-ui/core/Checkbox'
import * as yup from 'yup'
import {
  TextField,
  FileUploaderCropperField,
  FileUploaderField,
  Select,
  RangeSlider,
  PlacesAutocomplete,
  SubmitButton,
  Checkbox,
  RichTextEditor,
} from 'modules/formik'
import { Covers, radius } from 'modules/partner'
import { GoogleMap, Button, FormLabel } from 'modules/ui'
import { TagAutocompleteFormik } from 'modules/tag'
import validationSchemaObject from '../validationSchemaObject'
import config from '../../../config'
import WeekdaySelect from './WeekdaySelect'

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  fileUpload: {
    margin: 20,
  },
  profilePictureContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
}))

const initialState = {
  name: '',
  description: '',
  category: '',
  email: '',
  phone: '',
  website: '',
  coverImages: [],
  fixLocation: null,
  areaLocation: null,
  address: {
    name: '',
    coordinate: {},
  },
  guestRange: [0, 1],
  tags: [],
  services: [],
  profileImage: '',
  contactName: '',
  contactEmail: '',
  role: 'PARTNER',
  status: 'ACTIVE',
  verified: false,
  contactPhone: '',
  availableWeekdays: [],
  class: '',
  standingCapacity: null,
  seatingCapacity: null,
}

const validationSchema = yup
  .object()
  .shape(
    pick(
      [
        'name',
        'description',
        'phone',
        'tags',
        'email',
        'contactName',
        'contactEmail',
        'contactPhone',
        'fixLocation',
        'areaLocation',
        'profileImage',
      ],
      validationSchemaObject
    ),
    [['areaLocation', 'fixLocation']]
  )

export default ({
  initialValues = initialState,
  categoryLoading,
  imagesPath,
  categories,
  tags,
  submitButton = true,
  ...props
}) => {
  const categoryId = initialValues.category?.id

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        ...initialValues,
        category: categoryId,
      }}
      {...props}
    >
      <FormBody {...{ imagesPath, categoryLoading, categories, tags, submitButton }} />
    </Formik>
  )
}

const FormBody = ({ imagesPath, categoryLoading, categories, submitButton }) => {
  const classes = useStyles()
  const { values, initialValues, setValues, setFieldValue, touched } = useFormikContext()
  const [profileImage, setProfileImage] = useState(initialValues?.profileImage)
  const isDisplayedField = field =>
    categories
      .find(category => category.id === values.category)
      ?.extraFields.map(extraField => extraField.field)
      ?.includes(field)
  const [cropImage, setCropImage] = useState(false)

  const handleReplaceClick = () => {
    setProfileImage()
    setFieldValue('profileImage', '')
  }

  useEffect(() => {
    if (isDisplayedField('fixLocation')) {
      touched.category &&
        setValues({
          ...values,
          fixLocation: { city: '', zip: '', address: '', street: '', streetNumber: '' },
          areaLocation: null,
          tags: [],
        })
    } else if (isDisplayedField('areaLocation')) {
      touched.category &&
        setValues({
          ...values,
          fixLocation: null,
          areaLocation: { radius: 0, city: '', address: '', availableNationwide: false },
          tags: [],
        })
    }
  }, [values.category])

  const handleCheckUseCropp = () => {
    setFieldValue('profileImage', '')
    setCropImage(!cropImage)
  }

  useEffect(() => {
    // TODO fix
    setProfileImage(initialValues?.profileImage)
  }, [initialValues])

  return (
    <Form className={classes.root} autoComplete="off">
      <TextField id="name" name="name" label="Name" margin="normal" fullWidth />
      <RichTextEditor id="description" name="description" label="Description" />
      <TextField id="phone" name="phone" label="Phone number" margin="normal" fullWidth />
      <TextField id="email" name="email" label="Email address" margin="normal" fullWidth />
      <TextField id="website" name="website" label="Website" margin="normal" fullWidth />
      <TextField
        id="contactName"
        name="contactName"
        label="Contact name"
        margin="normal"
        fullWidth
      />
      <TextField
        id="contactEmail"
        name="contactEmail"
        label="Contact email"
        margin="normal"
        fullWidth
      />
      <TextField
        id="contactPhone"
        name="contactPhone"
        label="Contact phone"
        margin="normal"
        fullWidth
      />
      <Select
        id="category"
        loading={categoryLoading}
        name="category"
        label="Category"
        labelId="partner-edit-form-category-select"
        margin="normal"
        fullWidth
      >
        {categories.map(({ id, name }, i) => (
          <MenuItem key={i} value={id}>
            {name}
          </MenuItem>
        ))}
      </Select>
      <TagAutocompleteFormik
        categoryId={values.category}
        id="tags"
        name="tags"
        label="Tags"
        labelId="partner-edit-form-tag-ids-select"
        margin="normal"
        fullWidth
        multiple
        filterSelectedOptions
        disableClearable
      />

      {isDisplayedField('fixLocation') && (
        <>
          <PlacesAutocomplete margin="normal" fullWidth name="fixLocation" />
          <TextField
            name="fixLocation.city"
            disabled
            placeholder="City"
            margin="normal"
            fullWidth
          />
          <TextField name="fixLocation.zip" disabled placeholder="Zip" margin="normal" fullWidth />
          <TextField
            name="fixLocation.street"
            disabled
            placeholder="Street"
            margin="normal"
            fullWidth
          />
          <TextField
            name="fixLocation.streetNumber"
            disabled
            placeholder="Street Number"
            margin="normal"
            fullWidth
          />
        </>
      )}
      {isDisplayedField('areaLocation') && (
        <>
          <PlacesAutocomplete onlyCities margin="normal" fullWidth name="areaLocation" />
          <TextField
            name="areaLocation.city"
            disabled
            placeholder="City"
            margin="normal"
            fullWidth
          />
          <RangeSlider
            singleValue
            label="Radius (km)"
            margin="normal"
            valueLabelFormat={radius.valueLabelFormatForRadius}
            step={null}
            defaultValue={10}
            min={10}
            max={200}
            marks={radius.radiusMarks}
            fullWidth
            disabled={values.areaLocation?.availableNationwide}
            name="areaLocation.radius"
          />

          <Checkbox label="Available nationwide" name="areaLocation.availableNationwide" />
        </>
      )}
      <GoogleMap {...values.fixLocation} />
      <FormControl margin="normal" fullWidth>
        <FormLabel>Profile picture</FormLabel>
        {profileImage ? (
          <div className={classes.fileUpload}>
            <div className={classes.profilePictureContainer}>
              <img width={400} src={`${imagesPath}/${profileImage}`} alt="Profile" />
            </div>
            <Button size="small" onClick={handleReplaceClick}>
              replace image
            </Button>
          </div>
        ) : cropImage ? (
          <FileUploaderCropperField
            label="Profile image"
            id="profileImage"
            name="profileImage"
            fullWidth
            singleImage
            withIcon={false}
            withPreview
            buttonText="Choose image"
            imgExtension={['.jpg', '.png', '.gif']}
            maxFileSize={config.app.MAX_ATTACHMENT_SIZE}
          />
        ) : (
          <FileUploaderField
            label="Profile image"
            id="profileImage"
            name="profileImage"
            fullWidth
            singleImage
            withIcon={false}
            withPreview
            buttonText="Choose image"
            imgExtension={['.jpg', '.png', '.gif']}
            maxFileSize={config.app.MAX_ATTACHMENT_SIZE}
          />
        )}
      </FormControl>
      <FormControl margin="normal" fullWidth>
        <FormControlLabel
          label={'Use cropper component'}
          control={
            <MuiCheckbox checked={cropImage} onChange={handleCheckUseCropp} color="primary" />
          }
        />
      </FormControl>
      <FormControl margin="normal" fullWidth>
        <FormLabel>Covers</FormLabel>
        <Covers name="coverImages" imagesPath={imagesPath} />
      </FormControl>
      {isDisplayedField('guestRange') && (
        <RangeSlider
          margin="normal"
          fullWidth
          id="guestRange"
          name="guestRange"
          label="Number of guests"
          min={0}
          max={300}
          valueLabelDisplay="auto"
        />
      )}
      <Checkbox fullWidth label="Verified" name="verified" />
      <Select
        id="class"
        name="class"
        label="Class"
        labelId="partner-edit-form-class-select"
        margin="normal"
        fullWidth
      >
        {['A', 'B', 'C'].map((_class, i) => (
          <MenuItem key={i} value={_class}>
            {_class}
          </MenuItem>
        ))}
      </Select>
      {isDisplayedField('availableWeekdays') && <WeekdaySelect />}
      {isDisplayedField('standingCapacity') && (
        <TextField
          label="Standing capacity"
          name="standingCapacity"
          type="number"
          margin="normal"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
      {isDisplayedField('seatingCapacity') && (
        <TextField
          name="seatingCapacity"
          label="Seating capacity"
          type="number"
          margin="normal"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
      {/** TODO: This form will be changed. */}
      {!!submitButton && <SubmitButton>save</SubmitButton>}
    </Form>
  )
}
