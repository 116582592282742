import { useLocation, useHistory } from 'react-router-dom'

const useRouteQueryParams = () => {
  const history = useHistory()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)

  const setQueryParam = (paramName, value) => {
    if (typeof paramName === 'string') {
      setParamValue(paramName, value)
    } else {
      Object.entries(paramName).forEach(([key, value]) => {
        setParamValue(key, value)
      })
    }
    history.replace(`${location.pathname}?${queryParams.toString()}`)

    function setParamValue(name, value) {
      if (!value) {
        queryParams.delete(name)
      } else {
        queryParams.set(name, value)
      }
    }
  }


  return [setQueryParam]
}

export default useRouteQueryParams
