import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Grid from '@material-ui/core/Grid'
import { TextField } from 'modules/ui'
import PropTypes from 'prop-types'
import { Typography, Button } from 'modules/ui'
import { useForgotPassword } from '../../hooks'

const EmailFormSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email address').required('Required'),
})

export default function EmailForm({ onSubmit, skipToVerification, classes, renderError }) {
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: EmailFormSchema,
    onSubmit: val => onSubmit(val),
  })

  const { loading } = useForgotPassword()

  const { values, handleChange, errors, handleSubmit, validateForm } = formik
  const { email } = values

  useEffect(() => {
    validateForm()
    // eslint-disable-next-line
  }, [])

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Grid container spacing={2} justifyContent="center" alignContent="flex-end">
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body1" gutterBottom>
            To reset your password please enter your email address:
          </Typography>
          <TextField
            autoFocus
            fullWidth
            margin="normal"
            id="email"
            type="email"
            label="e-mail"
            value={email}
            onChange={handleChange}
          />
        </Grid>
        {renderError()}
        <Grid item xs={12} className={classes.footer}>
          <Button
            variant="contained"
            color="primary"
            disabled={loading}
            onClick={skipToVerification}
          >
            Skip to verification
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            margin="dense"
            disabled={!!errors.email || loading}
          >
            Next
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

EmailForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  skipToVerification: PropTypes.func.isRequired,
}
