import { useRouteMatch } from 'react-router'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import Box from '@material-ui/core/Box'
import { NavButton } from 'modules/ui'
import { BlogsList, useBlog } from 'modules/blog'
import { Page } from 'modules/ui'

const RowActions = ({ row }) => {
    const match = useRouteMatch()
    
    return (
        <>
            <NavButton to={`${match.url}/${row.id}/edit`} tooltip='Edit'><EditIcon fontSize='small' /></NavButton>
            <NavButton to={`${match.url}/${row.id}/remove`} tooltip='Delete'><DeleteIcon fontSize='small' /></NavButton>
        </>
    )
}

const List = () => {
    const { blogs, loading } = useBlog()
    const match = useRouteMatch()

    return (
        <Page title='Blog' fullHeight>
            <BlogsList blogs={blogs} loading={loading} rowActionsComponent={RowActions} />
            <Box display='flex' justifyContent='flex-end' p={1}>
                <NavButton to={`${match.url}/new`} tooltip='New'><AddIcon /></NavButton>
            </Box>
        </Page>
    )
}

export default List
