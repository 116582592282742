import { useState, useEffect } from 'react'
import { Formik, Form } from 'formik'
import * as yup from 'yup'
import { makeStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import config from '../../../config'
import { Button } from 'modules/ui'
import { TextField, FileUploaderField, SubmitButton, RichTextEditor } from 'modules/formik'

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  fileUpload: {
    alignItems: 'flex-start',
    marginTop: spacing(2),
  },
}))

const validationSchema = yup.object({
  name: yup
    .string('Enter name')
    .min(3, 'Should be of minimum 3 characters length')
    .required('Name is required'),
})

const initialState = {
  name: '',
  author: '',
  content: '',
  coverImage: '',
  ordering: '',
}

const BlogForm = ({ initialValues = initialState, imagesPath, ...props }) => {
  const classes = useStyles()
  const [coverImage, setCoverImage] = useState(initialValues?.coverImage)

  useEffect(() => {
    setCoverImage(initialValues?.coverImage)
  }, [initialValues])

  const handleReplaceClick = setter => () => setter()

  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues} {...props}>
      <Form className={classes.root} noValidate autoComplete="off">
        <TextField id="name" name="name" label="Name" margin="normal" fullWidth />
        <TextField id="author" name="author" label="Author" margin="normal" fullWidth />
        <RichTextEditor id="content" name="content" label="Content" />
        {coverImage ? (
          <FormControl className={classes.fileUpload} fullWidth>
            <img width={200} src={`${imagesPath}/${coverImage}`} alt="Cover" />
            <Button size="small" onClick={handleReplaceClick(setCoverImage)}>
              replace image
            </Button>
          </FormControl>
        ) : (
          <FileUploaderField
            label="Max file size: 15mb, accepted: jpg|png"
            name="coverImage"
            fullWidth
            withIcon={false}
            withPreview
            singleImage
            buttonText="Choose image"
            imgExtension={['.jpg', '.png']}
            maxFileSize={config.app.MAX_ATTACHMENT_SIZE}
          />
        )}
        <TextField
          id="ordering"
          name="ordering"
          label="Ordering"
          type="number"
          margin="normal"
          fullWidth
        />
        <SubmitButton>Save</SubmitButton>
      </Form>
    </Formik>
  )
}

export default BlogForm
