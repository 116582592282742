import { createSlice } from '@reduxjs/toolkit'
import { createSagaAction } from 'saga-toolkit'

const name = 'auth/signUp'

const initialState = {
  loading: false,
  user: null,
  error: null,
  currentEmail: '',
}

export const signUp = createSagaAction(`${name}/signUp`)
export const confirmSignUp = createSagaAction(`${name}/confirmSignUp`)
export const checkAccountOpened = createSagaAction(`${name}/checkAccountOpened`)
export const openAccount = createSagaAction(`${name}/openAccount`)
export const linkAccount = createSagaAction(`${name}/linkAccount`)

const slice = createSlice({
  name: 'auth/signUp',
  initialState,
  extraReducers: {
    [signUp.pending]: (state, { meta: { arg } }) => ({
      ...state,
      loading: true,
      currentEmail: arg.email,
    }),
    [signUp.fulfilled]: (state, { error }) => ({
      ...state,
      loading: false,
    }),
    [signUp.rejected]: (state, { error }) => ({
      ...state,
      error,
      loading: false,
    }),
    [confirmSignUp.pending]: state => ({
      ...state,
      loading: true,
      error: null,
    }),
    [confirmSignUp.fulfilled]: (state, { payload }) => ({
      ...state,
      data: payload,
      loading: false,
    }),
    [confirmSignUp.rejected]: (state, { error }) => ({
      ...state,
      error,
      loading: false,
    }),
    [checkAccountOpened.fulfilled]: state => ({
      ...state,
      loading: false,
      error: null,
    }),
    [checkAccountOpened.rejected]: (state, { error }) => ({
      ...state,
      error,
      loading: false,
    }),
    [openAccount.fulfilled]: state => ({
      ...state,
      loading: false,
      error: null,
    }),
    [openAccount.rejected]: (state, { error }) => ({
      ...state,
      error,
      loading: false,
    }),
    [linkAccount.fulfilled]: state => ({
      ...state,
      loading: false,
      error: null,
    }),
    [linkAccount.rejected]: (state, { error }) => ({
      ...state,
      error,
      loading: false,
    }),
  },
})

export default slice.reducer
