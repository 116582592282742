import { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import { Loader, Button } from 'modules/ui'
import { useInspiration } from 'modules/inspiration'

const Remove = () => {
  const { id } = useParams()
  const history = useHistory()
  const [open, setOpen] = useState(false)
  const { removeItem, item } = useInspiration()
  const [loading, setLoading] = useState(false)

  const goBack = () => history.push('/inspiration')

  const handleCancel = () => {
    setOpen(false)
  }

  const handleRemove = async () => {
    setLoading(true)
    await removeItem({ id })
    setLoading(false)
    handleCancel()
  }

  useEffect(() => {
    setOpen(true)
  }, [])

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={handleCancel} TransitionProps={{ onExited: goBack }}>
      <DialogTitle id="form-dialog-title">Remove {item?.name}?</DialogTitle>
      <DialogContent>
        <DialogContentText>If you delete the inspiration, it will be permanent.</DialogContentText>
        <Loader loading={loading} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleRemove} color="primary" disabled={loading}>
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default Remove
