import { Grid, makeStyles, MenuItem } from '@material-ui/core'
import { Form, Formik } from 'formik'
import * as yup from 'yup'
import { Select, TextField, SubmitButton } from 'modules/formik'
import { TagAutoCompleteSelector } from 'modules/tag'
import { Button } from 'modules/ui'

const useStyles = makeStyles(({ spacing }) => ({
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: spacing(4),
  },
}))

const validationSchema = yup.object({
  name: yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
  tags: yup.array().min(1).required('Required'),
  category: yup.string().min(2, 'Pick a category!').required('Required'),
  /** TODO: validation */
})

const CompanyForm = ({ submitForm, categories, categoryLoading, onSkipToVerificationClick }) => {
  const classes = useStyles()

  return (
    <Formik
      initialValues={{ name: '', tags: [], category: '' }}
      onSubmit={submitForm}
      validationSchema={validationSchema}
    >
      <Form>
        <Grid container justifyContent="center" alignContent="flex-end">
          <Grid item xs={12} sm={8} md={6}>
            <TextField id="name" name="name" label="Company name" margin="normal" fullWidth />
            <Select
              id="category"
              name="category"
              label="Category"
              loading={categoryLoading}
              labelId="partner-edit-form-category-select"
              margin="normal"
              fullWidth
            >
              {categories.map(({ id, name }) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              ))}
            </Select>
            <TagAutoCompleteSelector />
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.footer}>
          <Button onClick={onSkipToVerificationClick}>Skip to verification</Button>
          <SubmitButton variant="contained" color="primary" margin="dense">
            Next
          </SubmitButton>
        </Grid>
      </Form>
    </Formik>
  )
}

export default CompanyForm
