import { useRouteMatch } from 'react-router'
import AddIcon from '@material-ui/icons/Add'
import { Box } from '@material-ui/core'
import { NavButton } from 'modules/ui'
import { CouplesList, useCouple } from 'modules/couple'
import { Page } from 'modules/ui'
import config from '../../../config'

const List = () => {
  const { couples, loading } = useCouple()
  const match = useRouteMatch()
  const imagesPath = config.app.IMAGES_URL

  return (
    <Page title="Couple" fullHeight>
      <CouplesList
        imagesPath={imagesPath}
        couples={couples}
        loading={loading}
      />
      <Box display="flex" justifyContent="flex-end" p={1}>
        <NavButton to={`${match.url}/new`} tooltip="New">
          <AddIcon />
        </NavButton>
      </Box>
    </Page>
  )
}

export default List
