import Box from '@material-ui/core/Box'
import OpenIcon from '@material-ui/icons/Launch'
import { NavButton } from 'modules/ui'
import { HealtCheckCard, useAdmin } from 'modules/admin'
import { useProfile } from 'modules/profile'
import { Page, Typography } from 'modules/ui'

const AdminHome = () => {
  const { user } = useProfile()
  const { healthCheck } = useAdmin()
  const { conversationIssues } = healthCheck || {}

  if (!user) {
    return null
  }

  return (
    <Page title="Admin home">
      <Typography gutterBottom variant="caption">
        Profile status: {user.status}
      </Typography>
      <Box mt={1}>
        <Typography>Yo {user.name}!</Typography>
      </Box>
      <HealtCheckCard
        conversationIssues={conversationIssues}
        conversationIssuesButtonComponent={() => (
          <NavButton to="/admin/conversation-issues" tooltip="Open">
            <OpenIcon fontSize="small" />
          </NavButton>
        )}
      />
    </Page>
  )
}

export default AdminHome
