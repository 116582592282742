import { useEffect, useRef } from 'react'
import * as yup from 'yup'
import { Form, Formik, useFormikContext } from 'formik'
import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles } from '@material-ui/core/styles'
import { useBlog } from 'modules/blog'
import { useCategory } from 'modules/category'
import { Select, SubmitButton, TextField } from 'modules/formik'
import { usePartner } from 'modules/partner'
import { useTagGroup } from 'modules/tagGroup'
import HighlightAutoComplete from './HighlightAutoComplete'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  fileUpload: {
    alignItems: 'flex-start',
  },
}))

const validationSchema = yup.object({
  name: yup
    .string('Enter name')
    .min(3, 'Should be of minimum 3 characters length')
    .required('Name is required'),
})

const initialState = {
  name: '',
  type: '',
  variant: '',
  description: '',
  elements: [],
}
const variants = [
  { name: 'Tag', value: 'TAG' },
  { name: 'Wide', value: 'WIDE' },
  { name: 'Wide alternative', value: 'WIDE_ALT' },
  { name: 'Primary', value: 'PRIMARY' },
  { name: 'Primary wide', value: 'PRIMARY_WIDE' },
  { name: 'Secondary', value: 'SECONDARY' },
  { name: 'Secondary alternative', value: 'SECONDARY_ALT' },
  { name: 'Image', value: 'IMAGE' },
]
const types = [
  { name: 'Blog', value: 'BLOG' },
  { name: 'Category', value: 'CATEGORY' },
  { name: 'Partner', value: 'PARTNER' },
  { name: 'Tag Group', value: 'TAG_GROUP' },
]

const FormBody = () => {
  const { values, setFieldValue } = useFormikContext()
  const { searchPartner } = usePartner()
  const { searchCategory } = useCategory()
  const { searchTagGroup } = useTagGroup()
  const { searchBlog } = useBlog()

  const classes = useStyles()
  const didMountRef = useRef(false)

  useEffect(() => {
    didMountRef.current ? setFieldValue('elements', []) : (didMountRef.current = true)
  }, [values.type])

  const getSearchFunction = () => {
    switch (values.type) {
      case 'PARTNER':
        return searchPartner
      case 'CATEGORY':
        return searchCategory
      case 'TAG_GROUP':
        return searchTagGroup
      case 'BLOG':
        return searchBlog
      default:
        break
    }
  }
  const searchFunction = getSearchFunction()
  return (
    <Form className={classes.root} noValidate autoComplete="off">
      <TextField id="name" name="name" label="Name" margin="normal" fullWidth />
      <TextField
        id="description"
        name="description"
        label="Description"
        margin="normal"
        fullWidth
        multiline
        rows={6}
      />
      <Select name="type" label="Type" margin="normal" fullWidth>
        {types.map(({ name, value }, i) => (
          <MenuItem key={i} value={value}>
            {name}
          </MenuItem>
        ))}
      </Select>
      <Select name="variant" label="Variant" margin="normal" fullWidth>
        {variants.map(({ name, value }, i) => (
          <MenuItem key={i} value={value}>
            {name}
          </MenuItem>
        ))}
      </Select>
      <HighlightAutoComplete
        id="elements"
        name="elements"
        label="Elements"
        margin="normal"
        fullWidth
        filterSelectedOptions
        multiple
        disableClearable
        searchFunction={searchFunction}
      />
      <SubmitButton>Save</SubmitButton>
    </Form>
  )
}

const HighlightForm = ({ initialValues = initialState, ...props }) => {
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues} {...props}>
      <FormBody />
    </Formik>
  )
}

export default HighlightForm
