import { useState } from 'react'
import parseUrl from 'url-parse'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import PickerIcon from '@material-ui/icons/TextsmsOutlined'
import { RichTextEditor, Button } from 'modules/ui'

const HtmlAttachmentPicker = ({ initialValue = "", selected, onSubmit }) => {
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState(initialValue)
  const close = () => {
    setOpen(false)
  }

  const handleConfirm = () => {
    setOpen(false)
    onSubmit(value)
  }

  const handleChange = value => {
    setValue(value)
  }

  return (
    <>
      <IconButton aria-label="html-attachment-picker" color={selected ? 'primary' : undefined} onClick={() => setOpen(true)}>
        <PickerIcon />
      </IconButton>
      <Dialog fullWidth maxWidth="sm" open={open} onClose={() => setOpen(false)}>
        <DialogTitle id="form-dialog-title">Compose rich text attachment</DialogTitle>
        <DialogContent>
          <RichTextEditor
            name="html"
            label="Text"
            toolbarOptions={[
              'inline',
              'blockType',
              'fontSize',
              'list',
              'textAlign',
              'emoji',
              'history',
              'link',
            ]}
            converterOptions={{
              entityToHTML: (entity, originalText) => {
                if (entity.type === 'LINK') {
                  const parsed = parseUrl(entity.data.url)
                  const url = window.location.host === parsed.host ? parsed.pathname : entity.data.url

                  return <a href={url}>{originalText}</a>
                }
                return originalText
              }
            }}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button size="small" onClick={close}>Cancel</Button>
          <Button
            color="primary"
            variant="contained"
            size="small"
            onClick={handleConfirm}
          >
            Pick
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default HtmlAttachmentPicker
