import React from 'react'
import { Switch, Route } from 'react-router-dom'
import {
  Admin,
  Availabilities,
  Blogs,
  Categories,
  Chat,
  Couples,
  Covers,
  ForgotPassword,
  Help,
  Highlights,
  Home,
  InspirationScroll,
  Items,
  Notifications,
  Partners,
  Privacy,
  Profile,
  Quotes,
  SignUp,
  TagGroups,
  Tags,
  TermsAndConditions,
  Users,
  Wallet,
  Welcome,
  SignIn,
  Wedding
} from './pages'
import {
  userIsAdminRedir,
  userIsAuthenticatedRedir,
  userIsNotAuthenticatedRedir,
  userIsPartnerRedir,
  userIsNotAuthenticated,
} from './wrappers'

const Routes = () => {
  const WelcomeWithAuth = userIsNotAuthenticatedRedir(Welcome)
  // const SignUpWithAuth = userIsNotAuthenticatedRedir(SignUp) // ?
  // const ForgotPasswordWithAuth = userIsNotAuthenticatedRedir(ForgotPassword)
  const HomeWithAuth = userIsAuthenticatedRedir(Home)
  const AdminWithAuth = userIsAdminRedir(Admin)
  const CategoriesWithAuth = userIsAdminRedir(Categories)
  const ChatWithAuth = userIsAuthenticatedRedir(Chat)
  const ItemsWithAuth = userIsAdminRedir(Items)
  const CoversWithAuth = userIsAdminRedir(Covers)
  const NotificationsWithAuth = userIsAdminRedir(Notifications)
  const TagsWithAuth = userIsAdminRedir(Tags)
  const TagGroupsWithAuth = userIsAdminRedir(TagGroups)
  const UsersWithAuth = userIsAdminRedir(Users)
  const PartnersWithAuth = userIsAdminRedir(Partners)
  const CouplesWithAuth = userIsAdminRedir(Couples)
  const BlogsWithAuth = userIsAdminRedir(Blogs)
  const HighlightsWithAuth = userIsAdminRedir(Highlights)
  const ProfileWithAuth = userIsAuthenticatedRedir(Profile)
  const InspirationScrollWithAuth = userIsPartnerRedir(InspirationScroll)
  const AvailabilitiesWithAuth = userIsPartnerRedir(Availabilities)
  const QuotesWithAuth = userIsAuthenticatedRedir(Quotes)
  const WalletWithAuth = userIsPartnerRedir(Wallet)
  const SignInWithAuth = userIsNotAuthenticated(SignIn)

  return (
    <Switch>
      {/* <Redirect path="/" exact to="/highlights" /> */}
      <Route path="/admin" component={AdminWithAuth} />
      <Route path="/welcome" exact component={WelcomeWithAuth} />
      <Route path={['/', '/home']} exact component={HomeWithAuth} />
      <Route path="/availabilities" component={AvailabilitiesWithAuth} />
      <Route path="/highlights" component={HighlightsWithAuth} />
      <Route path="/sign-in" exact component={SignInWithAuth} />
      <Route path="/sign-up" component={SignUp} />
      <Route path="/categories" component={CategoriesWithAuth} />
      <Route path="/chat" component={ChatWithAuth} />
      <Route path="/items" component={ItemsWithAuth} />
      <Route path="/inspiration" component={InspirationScrollWithAuth} />
      <Route path="/notifications" component={NotificationsWithAuth} />
      <Route path="/tags" component={TagsWithAuth} />
      <Route path="/tag-groups" component={TagGroupsWithAuth} />
      <Route path="/partners" component={PartnersWithAuth} />
      <Route path="/couples" component={CouplesWithAuth} />
      <Route path="/blogs" component={BlogsWithAuth} />
      <Route path="/users" component={UsersWithAuth} />
      <Route path="/help" component={Help} />
      <Route path="/privacy-policy" component={Privacy} />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/profile" component={ProfileWithAuth} />
      <Route path="/terms-and-conditions" component={TermsAndConditions} />
      <Route path="/cover" component={CoversWithAuth} />
      <Route path="/quotes" component={QuotesWithAuth} />
      <Route path="/wallet" component={WalletWithAuth} />
      <Route path="/wedding/:id" component={Wedding} />
    </Switch>
  )
}

export default Routes
