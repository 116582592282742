import { Page } from 'modules/ui'
import { CoversList, useCover } from 'modules/cover'
import { useRouteMatch } from 'react-router'
import AddIcon from '@material-ui/icons/Add'
import { Box } from '@material-ui/core'
import { NavButton, RowActions } from 'modules/ui'
import config from '../../../config'

function List(props) {
  const { covers, loading } = useCover()
  const match = useRouteMatch()
  const imagesPath = config.app.IMAGES_URL

  return (
    <Page title="Covers" fullHeight>
      <CoversList imagesPath={imagesPath} items={covers} loading={loading} rowActionsComponent={RowActions} />
      <Box display="flex" justifyContent="flex-end" p={1}>
        <NavButton to={`${match.url}/new`} tooltip="New">
          <AddIcon />
        </NavButton>
      </Box>
    </Page>
  )
}

export default List
