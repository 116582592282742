import UIFormControlLabel from '@material-ui/core/FormControlLabel'
import { useT } from 'modules/i18n'

const isString = value => {
  return typeof value === 'string'
}

export const FormControlLabel = ({ label, ...props }) => {
  const t = useT()

  return <UIFormControlLabel label={isString(label) ? t(label) : label} {...props} />
}

export default FormControlLabel
