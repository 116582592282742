import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import ToolBar from '@material-ui/core/Toolbar'
import { makeStyles } from '@material-ui/core/styles'
import HomeIcon from '@material-ui/icons/Home'
import HighlightsIcon from '@material-ui/icons/StarBorder'
import SignUpIcon from '@material-ui/icons/PersonAdd'
import SignInIcon from '@material-ui/icons/PersonPin'
import ClientsIcon from '@material-ui/icons/PeopleAlt'
import PartnersIcon from '@material-ui/icons/SupervisorAccount'
import InsertComment from '@material-ui/icons/InsertComment'
import DashboardIcon from '@material-ui/icons/Dashboard'
import CategoryIcon from '@material-ui/icons/Category'
import ChatIcon from '@material-ui/icons/Chat'
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary'
import NotificationsIcon from '@material-ui/icons/Notifications'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'
import FilterListIcon from '@material-ui/icons/FilterList'
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter'
import PermMediaIcon from '@material-ui/icons/PermMedia'
import HelpIcon from '@material-ui/icons/Help'
import ExitIcon from '@material-ui/icons/DirectionsRun'
import UsersIcon from '@material-ui/icons/Person'
import EventIcon from '@material-ui/icons/Event'
import NoteIcon from '@material-ui/icons/Note'
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet'
import Fade from '@material-ui/core/Fade'
import Badge from '@material-ui/core/Badge'
import {
  SideBar as UISideBar,
  NavBar as UINavBar,
  NavButton,
  ListButton,
  Typography,
} from 'modules/ui'

import { userIsAuthenticated, userIsAdmin, userIsPartner, userIsNotAuthenticated } from './wrappers'
import { useApp, useAppStart, SnackMessage } from 'modules/app'
import { useSignIn } from 'modules/auth'
import { selectors as authSelectors } from 'modules/auth'
import { useProfile } from 'modules/profile'
import { useChat } from 'modules/chat'
import { Divider } from '@material-ui/core'

import Routes from './Routes'

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  root: {
    display: 'flex',
  },
  loaderContainer: {
    position: 'fixed',
    left: 0,
    top: 0,
    zIndex: 10,
    background: 'linear-gradient(135deg, rgba(247,56,97,1) 0%, rgba(254,113,44,1) 100%)',
    width: '100%',
    height: '100%',
  },
  content: {
    flexGrow: 1,
    marginTop: spacing(2),
  },
  signInLoading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
  loaderImage: {
    maxWidth: breakpoints.values.sm,
    [breakpoints.down('sm')]: {
      maxWidth: '350px',
    },
    animation: `$pulse 1000ms linear infinite`,
  },
  '@keyframes pulse': {
    '0%': {
      transform: 'scale(1)',
    },
    '50%': {
      transform: 'scale(0.8)',
    },
    '100%': {
      transform: 'scale(1)',
    },
  },
}))

const HomeLink = userIsAuthenticated(() => (
  <NavButton exact to="/home" tooltip="Home">
    <HomeIcon />
  </NavButton>
))

const AdminMenu = userIsAdmin(() => (
  <>
    <ListButton to="/" exact={true} title="Home">
      <HomeIcon />
    </ListButton>
    <ListButton to="/highlights" title="Highlights">
      <HighlightsIcon />
    </ListButton>
    <ListButton to="/users" title="Users">
      <UsersIcon />
    </ListButton>
    <ListButton to="/partners" title="Partners">
      <PartnersIcon />
    </ListButton>
    <ListButton to="/couples" title="Couples">
      <ClientsIcon />
    </ListButton>
    <ListButton to="/quotes" title="Quotes" tooltip="Quotes">
      <NoteIcon />
    </ListButton>
    <ListButton to="/categories" title="Categories">
      <CategoryIcon />
    </ListButton>
    <ListButton to="/items" title="Items">
      <DashboardIcon />
    </ListButton>
    <ListButton to="/tags" title="Tags">
      <LocalOfferIcon />
    </ListButton>
    <ListButton to="/tag-groups" title="Tag Groups">
      <FilterListIcon />
    </ListButton>
    <ListButton to="/blogs" title="Blog">
      <InsertComment />
    </ListButton>
    <ListButton to="/notifications" title="Notifications">
      <NotificationsIcon />
    </ListButton>
    <ListButton to="/chat" title="Chat">
      <ChatIcon />
    </ListButton>
    <ListButton to="/cover" title="Covers">
      <PhotoLibraryIcon />
    </ListButton>
    <Divider />
    <ListButton to="/profile" title="Profile" tooltip="Profile">
      <UsersIcon />
    </ListButton>
  </>
))

const PartnerMenu = userIsPartner(({ chatBadge }) => (
  <>
    <ListButton to="/" exact={true} title="Home">
      <HomeIcon />
    </ListButton>
    <ListButton to="/chat" title="Chat">
      <Badge badgeContent={chatBadge} color="primary">
        <ChatIcon />
      </Badge>
    </ListButton>
    <ListButton to="/inspiration" title="Inspiration Scroll" tooltip="Inspiration Scroll">
      <PermMediaIcon />
    </ListButton>
    <ListButton to="/availabilities" title="Availabilities" tooltip="Availabilities">
      <EventIcon />
    </ListButton>
    <ListButton to="/quotes" title="Quotes" tooltip="Quotes">
      <NoteIcon />
    </ListButton>
    <ListButton to="/wallet" title="Wallet" tooltip="Wallet">
      <AccountBalanceWalletIcon />
    </ListButton>
    <ListButton to="/profile" title="Profile" tooltip="Profile">
      <UsersIcon />
    </ListButton>
  </>
))

const HelpLink = () => (
  <NavButton exact to="/help" tooltip="Help">
    <HelpIcon />
  </NavButton>
)

const SignUpLink = userIsNotAuthenticated(() => (
  <NavButton exact to="/sign-up" tooltip="Sign up">
    <SignUpIcon />
  </NavButton>
))

const SignInLink = userIsNotAuthenticated(() => (
  <NavButton to="/sign-in" tooltip="Sign In">
    <SignInIcon />
  </NavButton>
))

const SignOutLink = userIsAuthenticated(() => {
  const { signOut } = useSignIn()
  const history = useHistory()

  const handleClick = async () => {
    await signOut()
    history.push('/')
  }

  return (
    <NavButton to="/" tooltip="Sign out" onClick={handleClick}>
      <ExitIcon />
    </NavButton>
  )
})

const SideBarWithAuth = userIsAuthenticated(({ children, ...props }) => (
  <UISideBar {...props}>{children}</UISideBar>
))

const SideBar = ({ user, open, onClose }) => {
  const { unseenConversations } = useChat()

  return (
    <SideBarWithAuth open={open} onClose={onClose} user={user}>
      <AdminMenu />
      <PartnerMenu chatBadge={unseenConversations.length} />
    </SideBarWithAuth>
  )
}

const AppErrorMessage = () => {
  const { snackMessage, hideSnackMessage } = useApp()
  const error = useSelector(
    ({ user, item, category, tag, tagGroup, partner, highlight, blog, quote }) =>
      user.error ||
      item.error ||
      category.error ||
      tag.error ||
      tagGroup.error ||
      partner.error ||
      highlight.error ||
      blog.error ||
      quote.error
  )

  return (
    <SnackMessage error={error} snackMessage={snackMessage} hideSnackMessage={hideSnackMessage} />
  )
}

const NavBar = ({ authenticated, open, onOpen }) => {
  const loading = useSelector(
    ({
      app,
      auth,
      user,
      item,
      category,
      tag,
      tagGroup,
      partner,
      highlight,
      blog,
      chat,
      availability,
    }) => {
      return (
        !app.started ||
        auth.signIn.loading ||
        auth.signUp.loading ||
        user.loading ||
        item.loading ||
        category.loading ||
        tag.loading ||
        tagGroup.loading ||
        partner.loading ||
        highlight.loading ||
        blog.loading ||
        availability.loading ||
        !!chat.parallelTasks
      )
    }
  )

  return (
    <UINavBar loading={loading} authenticated={authenticated} drawerOpen={open} onOpen={onOpen}>
      <HomeLink />
      <HelpLink />
      <SignUpLink />
      <SignInLink />
      <SignOutLink />
    </UINavBar>
  )
}

const LoadingIndicator = () => (
  <Typography variant="h6" color="inherit">
    Loading...
  </Typography>
)

const useInjectScript = ({ src }) =>
  useEffect(() => {
    let script = document.createElement('script')
    script.src = src
    document.getElementsByTagName('body')[0].appendChild(script)
  }, [])

const App = () => {
  const classes = useStyles()
  const [drawerOpen, setDrawerOpen] = React.useState(true)
  const authenticated = useSelector(authSelectors.isAuthenticated) // TODO const { authenticated } = useSignIn()
  const { started } = useApp()
  const { user } = useProfile()
  const { loading: signInLoading } = useSignIn()

  const handleDrawerOpen = () => {
    setDrawerOpen(true)
  }

  const handleDrawerClose = () => {
    setDrawerOpen(false)
  }

  useInjectScript({
    src: `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_API}&libraries=places`,
  })
  useAppStart()

  React.useEffect(() => {
    setDrawerOpen(authenticated)
  }, [authenticated])

  return (
    <>
      <Fade in={!started} timeout={{ enter: 0, exit: 500 }}>
        <div className={classes.loaderContainer}>
          <div
            style={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <img
              className={classes.loaderImage}
              src="/wm-text.png"
              alt="Wedding manager is loading"
            />
          </div>
        </div>
      </Fade>
      {started && (
        <Fade timeout={{ enter: 500, exit: 0 }} in={started}>
          <div className={classes.root}>
            <SideBar user={user} open={drawerOpen} onClose={handleDrawerClose} />
            <NavBar authenticated={authenticated} open={drawerOpen} onOpen={handleDrawerOpen} />

            <main className={`${classes.content} ${signInLoading ? classes.signInLoading : ''}`}>
              <ToolBar />
              {signInLoading ? <LoadingIndicator /> : <Routes />}
            </main>
          </div>
        </Fade>
      )}
      <AppErrorMessage />
    </>
  )
}

export default App
